import { EuiHighlight, EuiLink, EuiText } from '@elastic/eui';
import { observer } from 'mobx-react';
import { useNavProps } from '../../../hooks';

export interface TitleCellProps {
  title: string;
  linkTo: string;
  searchValue?: string;
}

export const TitleCell = observer((props: TitleCellProps) => {
  const { title, linkTo, searchValue } = props;
  const getNavProps = useNavProps();

  const text = searchValue ? (
    <EuiHighlight search={searchValue.trim().replaceAll(/\s{2,}/g, ' ')}>
      {title}
    </EuiHighlight>
  ) : (
    <EuiText size="relative">{title}</EuiText>
  );

  return <EuiLink {...getNavProps(linkTo)}>{text}</EuiLink>;
});
