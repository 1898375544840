import {
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiIconTip,
  EuiText,
} from '@elastic/eui';
import { debounce } from 'lodash';
import { useCallback, useState } from 'react';
import { logger } from '../../utils';
import { LocationDto } from '@unfrl/copdb-sdk';
import { Flex } from '../common';
import { NewPlaceSuggestion } from '../../api';
import { apiClient } from '../../api';

export interface LocationSearchProps {
  selectedPlaces: NewPlaceSuggestion[];
  required?: boolean;
  onSelected: (location?: LocationDto) => void;
}

export const LocationSearch = ({
  selectedPlaces,
  onSelected,
  required,
}: LocationSearchProps) => {
  const [places, setPlaces] = useState<NewPlaceSuggestion[]>([]);
  const [loading, setLoading] = useState(false);

  const mapPlacesToOptions = (places: NewPlaceSuggestion[]) => {
    return places.map((place) => ({
      label: place.placeName,
      key: place.id,
      value: place,
      append: <EuiText color="subdued">{place.displayName}</EuiText>,
    }));
  };

  const handleSearchChange = useCallback(async (value: string) => {
    setLoading(true);
    setPlaces([]);

    try {
      if (value) {
        const places = await apiClient.geoClient.getSuggestions(value);

        setPlaces(places);
      }
    } catch (error) {
      logger.error('error when fetching suggestions', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSuggestionSelected = async (
    options: EuiComboBoxOptionOption<NewPlaceSuggestion>[],
  ): Promise<void> => {
    setLoading(true);
    setPlaces([]);

    try {
      if (!options.length || !options[0].value?.id) {
        onSelected();
        return;
      }

      const location = await apiClient.geoClient.getPlaceFromPlaceId(
        options[0].value.id,
      );

      onSelected(location);
    } catch (error) {
      logger.error('error when fetching place details', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex column gap={4}>
      <EuiComboBox
        isInvalid={required ? !selectedPlaces.length : false}
        async
        fullWidth
        singleSelection={{ asPlainText: true }}
        aria-label="Search by address"
        placeholder="Search by address"
        isLoading={loading}
        options={mapPlacesToOptions(places)}
        selectedOptions={mapPlacesToOptions(selectedPlaces)}
        onSearchChange={debounce(handleSearchChange, 400)}
        onChange={handleSuggestionSelected}
      />
      <Flex align="center" justify="flex-end" gap={4}>
        <EuiText size="s" color="subdued">
          Powered by{' '}
        </EuiText>
        <img width={45} src="/google_on_non_white.png" />
        <EuiIconTip
          content="We only use Google for address lookup, and they require this attribution."
          position="right"
        />
      </Flex>
    </Flex>
  );
};
