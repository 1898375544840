import { useState } from 'react';
import { stores } from '../stores';
import { logger } from '../utils';

const { toastStore } = stores;

/**
 * Wraps the provided async function in a try/catch/finally, setting the
 * executing state before and after invocation. Useful for eliminating
 * boilerplate for setting a `loading` variable when executing an async
 * function. This hook is coupled with our store implementation, making it
 * simple to show an error toast if the provided function fails for whatever
 * reason.
 * @param asyncFn - Async function to invoke in try block.
 * @param onSuccess - Optional callback that will be invoked on successful
 * asyncFn invocation, useful for separate cleanup functions.
 */
export function useAsyncFunction<Result, Args extends unknown[] = unknown[]>(
  asyncFn: (...params: Args) => Promise<Result>,
  onSuccess?: () => void,
): [(...params: Args) => Promise<Result | undefined>, boolean] {
  const [executing, setExecuting] = useState(false);

  const wrappedAsyncFn = async (...params: Args) => {
    try {
      setExecuting(true);

      const result = await asyncFn(...params);

      if (onSuccess) {
        onSuccess();
      }

      return result;
    } catch (error) {
      logger.error('failed to execute async function', error);
      await toastStore.showApiError(error);
    } finally {
      setExecuting(false);
    }
  };

  return [wrappedAsyncFn, executing];
}
