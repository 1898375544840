import {
  EuiAvatar,
  EuiButtonIcon,
  EuiCommentList,
  EuiCommentListProps,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiMarkdownFormat,
  EuiPopover,
} from '@elastic/eui';
import { CommentDto, CommentType } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useStores } from '../../hooks';
import { MissingDataText } from '../common';
import { CommentEditor } from './comment-editor';
import { CommentTimestamp } from './comment-timestamp';

export interface CommentListProps {
  commentDtos: CommentDto[];
  onUpdate: (commentId: string, content: string) => Promise<void>;
  onDelete: (commentId: string) => void;
}

export const CommentList = observer((props: CommentListProps) => {
  const { commentDtos, onUpdate, onDelete } = props;
  const { authStore, userStore } = useStores();
  const [editingId, setEditingId] = useState<string | null>(null);
  const [openId, setOpenId] = useState<string | null>(null);

  if (!commentDtos.length) {
    return <MissingDataText text="No comments yet." textAlign="center" />;
  }

  const handleUpdateComment =
    (commentId: string) => async (newValue: string) => {
      await onUpdate(commentId, newValue);
      setEditingId(null);
    };

  const handleDeleteComment = (commentId: string) => () => {
    onDelete(commentId);
  };

  const handleShowPopover = (commentId: string) => () => {
    setOpenId(commentId);
  };

  const handleClosePopover = () => {
    setOpenId(null);
  };

  const handleShowEditing = (commentId: string) => () => {
    setEditingId(commentId);
    setOpenId(null);
  };

  const handleCloseEditing = () => {
    setEditingId(null);
  };

  const getCommentActions = (commentId: string) => {
    return (
      <EuiPopover
        button={
          <EuiButtonIcon
            aria-label="Actions"
            iconType="boxesHorizontal"
            color="text"
            onClick={handleShowPopover(commentId)}
          />
        }
        isOpen={openId === commentId}
        closePopover={handleClosePopover}
        panelPaddingSize="none"
        anchorPosition="leftCenter"
      >
        <EuiContextMenuPanel
          items={[
            <EuiContextMenuItem
              key="edit"
              icon="pencil"
              onClick={handleShowEditing(commentId)}
            >
              Edit
            </EuiContextMenuItem>,
            <EuiContextMenuItem
              key="delete"
              icon="trash"
              onClick={handleDeleteComment(commentId)}
            >
              Delete
            </EuiContextMenuItem>,
          ]}
        />
      </EuiPopover>
    );
  };

  const comments: EuiCommentListProps['comments'] = commentDtos.map(
    (comment) => {
      const userIsAuthor = comment.createdBy === authStore.user?.id;
      const userCanEdit = comment.type === CommentType.Message && userIsAuthor;
      const isEditMode = comment.id === editingId && userCanEdit;
      const isUpdateType = comment.type === CommentType.Update;

      // common EuiComment props
      const authorProfile = userStore.data.getItem(comment.createdBy);
      const username = authorProfile?.userName ?? 'Anonymous';
      const timelineAvatarAriaLabel = username;
      const timelineAvatar = <EuiAvatar size="m" name={username} />;
      const timestamp = <CommentTimestamp comment={comment} />;

      if (isEditMode) {
        return {
          username,
          timelineAvatarAriaLabel,
          timelineAvatar,
          children: (
            <CommentEditor
              initialValue={comment.content || ''}
              onSubmitComment={handleUpdateComment(comment.id)}
              onCancel={handleCloseEditing}
              submitText="Update comment"
            />
          ),
        };
      }

      if (isUpdateType) {
        return {
          username,
          timelineAvatarAriaLabel,
          timelineAvatar,
          timestamp,
          event: comment.content,
          eventIcon: 'pencil',
        };
      }

      // otherwise it's a message type
      return {
        username,
        timelineAvatarAriaLabel,
        timelineAvatar,
        timestamp,
        event: 'commented',
        children: (
          <EuiMarkdownFormat textSize="s">{comment.content}</EuiMarkdownFormat>
        ),
        actions: userCanEdit ? getCommentActions(comment.id) : [],
      };
    },
  );

  return <EuiCommentList comments={comments} />;
});
