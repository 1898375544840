import { observer } from 'mobx-react';
import { useStores } from '../../hooks';
import { AuthRole } from '../../stores';
import { ResourceNotFound } from '../common';

export interface RoleRequiredProps {
  roles: AuthRole[];
  children: JSX.Element;
  isRoute?: boolean;
}

const RoleRequired = ({ children, isRoute, roles }: RoleRequiredProps) => {
  const { authStore } = useStores();

  if (!authStore.authenticated) {
    return null;
  }

  if (roles.some((role) => authStore.hasRole(role))) {
    return children;
  }

  return isRoute ? <ResourceNotFound /> : null;
};

export default observer(RoleRequired);
