import { MediaEdit } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { Fragment } from 'react';
import { useExpandedMap, useStores } from '../../hooks';
import { MediaEditMapping } from '../../stores';
import { mediaDetailsPath } from '../../utils';
import { Flex } from '../common';
import {
  BasicTable,
  BasicTableColumn,
  TitleCell,
  expanderColumn,
} from '../table';
import { MediaEditList } from './media-edit-list';
import { MediaPreview } from './media-preview';

export const MediaEditTable = observer(() => {
  const { mediaEditStore } = useStores();

  const { expandedMap, isExpanded, toggleExpanded } = useExpandedMap((id) => (
    <MediaEditList mediaId={id} />
  ));

  const previewColumn = (): BasicTableColumn<MediaEditMapping> => {
    return {
      field: 'media.id',
      name: 'Media',
      render: (id: string, item: MediaEditMapping) => {
        const { name, displayName, objectKey } = item.media;
        const title = displayName || name || objectKey;
        return (
          <Fragment>
            <Flex column align="start">
              <TitleCell title={title} linkTo={mediaDetailsPath(id)} />
              <MediaPreview media={item.media} />
            </Flex>
          </Fragment>
        );
      },
    };
  };

  const editsColumn = (): BasicTableColumn<MediaEditMapping> => {
    return {
      field: 'edits',
      name: 'Pending edits',
      render: (edits: MediaEdit[]) => (
        <Flex auto justify="center">{`${edits.length}`}</Flex>
      ),
    };
  };

  const columns = [
    previewColumn(),
    editsColumn(),
    expanderColumn({
      isExpanded,
      onToggle: toggleExpanded,
      showText: 'Show edits',
      hideText: 'Hide edits',
    }),
  ];

  return (
    <BasicTable
      columns={columns}
      loading={mediaEditStore.loading}
      result={{
        items: mediaEditStore.mediaEditMap,
        count: mediaEditStore.mediaEditMap.length,
      }}
      subject="Media edits"
      expandedRowsMap={expandedMap}
    />
  );
});
