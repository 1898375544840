import { EuiListGroup, EuiListGroupItem, EuiText } from '@elastic/eui';
import { IncidentResponseDto } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { incidentPath } from '../../utils';
import { useNavProps } from '../../hooks';
import { IncidentDetailItem } from './incident-detail-item';

export interface IncidentDetailListProps {
  incidents: IncidentResponseDto[];
  onDelete?: (index: number) => void;
}

export const IncidentDetailList = observer((props: IncidentDetailListProps) => {
  const { incidents, onDelete } = props;
  const getNavProps = useNavProps();

  if (!incidents.length) {
    return (
      <EuiText color="subdued" size="relative">
        No incidents
      </EuiText>
    );
  }

  return (
    <EuiListGroup flush maxWidth={false}>
      {incidents.map((incident, index) => {
        const navProps = incident.id
          ? getNavProps(incidentPath(incident.id))
          : undefined;

        return (
          <div key={incident.id}>
            <EuiListGroupItem
              label={
                <div title="Click to view">
                  <IncidentDetailItem incident={incident} />
                </div>
              }
              showToolTip={false}
              extraAction={
                onDelete
                  ? {
                      'aria-label': 'Delete incident',
                      title: 'Delete incident',
                      color: 'danger',
                      iconType: 'trash',
                      alwaysShow: true,
                      size: 's',
                      onClick: () => onDelete(index),
                    }
                  : undefined
              }
              {...navProps}
            />
          </div>
        );
      })}
    </EuiListGroup>
  );
});
