import { EuiSpacer } from '@elastic/eui';
import { Media } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { Fragment } from 'react';
import ReactPlayer from 'react-player';
import { Flex } from '../../common';
import { MediaLink } from './media-link';

export interface VideoPreviewProps {
  media: Media;
  displayMediaDetailsLink?: boolean;
}

export const VideoPreview = observer((props: VideoPreviewProps) => {
  const { media, displayMediaDetailsLink } = props;

  return (
    <Fragment>
      <Flex>
        {media.url && (
          <ReactPlayer controls url={media.url} width="100%" height="100%" />
        )}
      </Flex>
      <EuiSpacer size="s" />
      <Flex justify="center">
        <MediaLink
          media={media}
          displayMediaDetailsLink={displayMediaDetailsLink}
        />
      </Flex>
    </Fragment>
  );
});
