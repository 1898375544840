import { EuiFieldNumber, EuiPanel, EuiText } from '@elastic/eui';
import { IncidentInformationDto } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { Fragment } from 'react';
import { camelToSentence } from '../../utils';
import { Flex } from '../common';

export interface CopIncidentInfoProps {
  incidentInfo?: IncidentInformationDto | null;
  onEdit?: (incidentInfo: IncidentInformationDto) => void;
}

export const CopIncidentInfo = observer((props: CopIncidentInfoProps) => {
  const { incidentInfo, onEdit } = props;

  const renderEditable = () => {
    return (
      <Fragment>
        <EuiText size="s" color={'subdued'}>
          {'Add information about this cop related to the incident above.'}
          <br />
          {`Leave any fields you don't know about blank.`}
        </EuiText>

        <EuiFieldNumber
          placeholder="Shots fired"
          value={incidentInfo?.shotsFired ?? ''}
          min={0}
          onChange={(e) =>
            onEdit &&
            onEdit({
              ...incidentInfo,
              shotsFired: e.target.value ? Number(e.target.value) : undefined,
            })
          }
          aria-label="shots fired"
        />
      </Fragment>
    );
  };

  return (
    <EuiPanel paddingSize="xs" hasShadow={false} hasBorder={false}>
      <Flex column gap={8}>
        {onEdit && renderEditable()}
        {!onEdit &&
          incidentInfo &&
          Object.keys(incidentInfo).map((key) => {
            const objKey = key as keyof IncidentInformationDto;

            return (
              <EuiText key={key}>
                {camelToSentence(key)}: {incidentInfo[objKey] ?? 'N/A'}
              </EuiText>
            );
          })}
      </Flex>
    </EuiPanel>
  );
});
