import { DepartmentResponseDto } from '@unfrl/copdb-sdk';
import { makeAutoObservable } from 'mobx';
import { apiClient } from '../api';
import { DataStore } from './data.store';

export class DepartmentStore {
  public data: DataStore<DepartmentResponseDto> = new DataStore();

  public constructor() {
    makeAutoObservable(this);
  }

  public fetchDepartments = async (ids: string[]): Promise<void> => {
    const idsToFetch = ids.filter((id) => !this.data.itemExists(id));
    if (!idsToFetch.length) {
      return;
    }

    const { departmentResponses } = await apiClient.departments.listDepartments(
      {
        ids: idsToFetch,
        page: 1,
        pageSize: idsToFetch.length,
      },
    );

    this.data.setList(departmentResponses ?? []);
  };
}
