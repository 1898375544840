import {
  CriteriaWithPagination,
  EuiAccordion,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiToolTip,
} from '@elastic/eui';
import {
  CopDepartmentNotability,
  CopFilterNameQueryType,
  CopResponseDto,
  CopSortField,
  CopsFilterResponse,
  EyeColor,
  Gender,
  HairColor,
  Race,
  SortDirection,
} from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import { apiClient } from '../../api';
import { useStores } from '../../hooks';
import { logger, urlParamsToObject } from '../../utils';
import { BasicTable } from '../table';
import { copTableColumns } from './cop-table-columns';
import { CopFilterItems, CopTableFilters } from './cop-table-filters';
import { DepartmentSelect } from './department-select';

const sortDirectionMapping: any = {
  asc: SortDirection.Ascending,
  desc: SortDirection.Descending,
};

const sortFieldMapping: any = {
  'person.firstName': CopSortField.FirstName,
  'person.lastName': CopSortField.LastName,
  updatedAt: CopSortField.UpdatedAt,
};

export const CopTableContainer = observer(() => {
  const { toastStore } = useStores();
  const [result, setResult] = useState<CopsFilterResponse>({
    pageNumber: 1,
    pageSize: 10,
    count: 0,
  });

  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const page = parseInt(searchParams.get('page') ?? '1');
  const pageSize = parseInt(searchParams.get('pageSize') ?? '10');
  const name = searchParams.get('name') ?? '';
  const queryType = (searchParams.get('queryType') ??
    CopFilterNameQueryType.Basic) as CopFilterNameQueryType;
  const sortField =
    searchParams.get('sort') ?? (name ? null : 'person.lastName');
  const sortDirection = searchParams.get('order') ?? 'desc';
  const departmentId = searchParams.get('departmentId') ?? undefined;

  const hairColorsRaw = searchParams.getAll('hairColors') as HairColor[];
  const hairColors = hairColorsRaw.length > 0 ? hairColorsRaw : undefined;
  const hairColorsJson = JSON.stringify(hairColors);

  const eyeColorsRaw = searchParams.getAll('eyeColors') as EyeColor[];
  const eyeColors = eyeColorsRaw.length > 0 ? eyeColorsRaw : undefined;
  const eyeColorsJson = JSON.stringify(eyeColors);

  const gendersRaw = searchParams.getAll('genders') as Gender[];
  const genders = gendersRaw.length > 0 ? gendersRaw : undefined;
  const gendersJson = JSON.stringify(genders);

  const racesRaw = searchParams.getAll('races') as Race[];
  const races = racesRaw.length > 0 ? racesRaw : undefined;
  const racesJson = JSON.stringify(races);

  const notabilitiesRaw = searchParams.getAll(
    'notabilities',
  ) as CopDepartmentNotability[];
  const notabilities = notabilitiesRaw.length > 0 ? notabilitiesRaw : undefined;
  const notabilitiesJson = JSON.stringify(notabilities);

  const badgeNumber = searchParams.get('badgeNumber') ?? undefined;

  const position = searchParams.get('position') ?? undefined;

  const visibleTattoosRaw = searchParams.get('visibleTattoos');
  const visibleTattoos =
    visibleTattoosRaw === 'true'
      ? true
      : visibleTattoosRaw === 'false'
      ? false
      : undefined;

  const debouncedName = useDebounce<string>(name, 150);

  useEffect(() => {
    let ignore = false;

    const fetchCops = async () => {
      setLoading(true);

      try {
        const result = await apiClient.cops.listCops({
          departmentId,
          nameQuery: debouncedName,
          nameQueryType: queryType,
          sortField: sortField ? sortFieldMapping[sortField] : undefined,
          sortDirection: sortDirection
            ? sortDirectionMapping[sortDirection]
            : undefined,
          page,
          pageSize,
          hairColors,
          eyeColors,
          genders,
          races,
          notabilities,
          visibleTattoos,
          badgeNumber,
          position,
        });
        if (!ignore) {
          setResult(result);
        }
      } catch (error: any) {
        logger.error('error fetching cops', error);
        await toastStore.showApiError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCops();
    return () => {
      ignore = true;
    };
  }, [
    page,
    pageSize,
    debouncedName,
    departmentId,
    sortField,
    sortDirection,
    toastStore,
    hairColorsJson,
    eyeColorsJson,
    gendersJson,
    racesJson,
    notabilitiesJson,
    visibleTattoosRaw,
    queryType,
    badgeNumber,
    position,
  ]);

  const handleTableChange = ({
    page,
    sort,
  }: CriteriaWithPagination<CopResponseDto>) => {
    setSearchParams((sp) => {
      return {
        ...urlParamsToObject(sp),
        page: (page.index + 1).toString(),
        pageSize: page.size.toString(),
        sort: sort?.field || 'person.lastName',
        order: sort?.direction || 'desc',
      } as any;
    });
  };

  const handleNameChange = (name: string) => {
    if (queryType !== 'basic') {
      setSearchParams((sp) => {
        return {
          ...urlParamsToObject(sp),
          name,
        } as any;
      });
      return;
    }

    if (name) {
      setSearchParams((sp) => {
        return {
          ...urlParamsToObject(sp),
          sort: '',
          order: 'desc',
          name,
        } as any;
      });
      return;
    }

    setSearchParams((sp) => {
      return {
        ...urlParamsToObject(sp),
        sort: sortField || 'person.lastName',
        order: sortDirection || 'desc',
        name,
      } as any;
    });
  };

  const handleDepartmentChange = (newDepartmentId: string | undefined) => {
    setSearchParams((sp) => {
      const { departmentId, ...rest } = urlParamsToObject(sp);
      return {
        ...rest,
        ...(newDepartmentId && { departmentId: newDepartmentId }),
      } as any;
    });
  };

  const handleQueryTypeChange = (queryType: CopFilterNameQueryType) => {
    setSearchParams((sp) => {
      return {
        ...urlParamsToObject(sp),
        queryType,
      } as any;
    });
  };

  const handleFilterChange = (filters: CopFilterItems) => {
    setSearchParams((sp) => {
      const {
        hairColors: hairColorsOld,
        eyeColors: eyeColorsOld,
        genders: gendersOld,
        races: racesOld,
        notabilities: notabilitiesOld,
        visibleTattoos: visibleTattoosOld,
        badgeNumber: badgeNumberOld,
        position: positionOld,
        ...rest
      } = urlParamsToObject(sp);

      const {
        hairColors,
        eyeColors,
        genders,
        races,
        notabilities,
        visibleTattoos,
        badgeNumber,
        position,
      } = filters;
      return {
        ...rest,
        ...(hairColors && { hairColors }),
        ...(eyeColors && { eyeColors }),
        ...(genders && { genders }),
        ...(races && { races }),
        ...(notabilities && { notabilities }),
        ...(visibleTattoos !== undefined && { visibleTattoos }),
        ...(badgeNumber && { badgeNumber }),
        ...(position && { position }),
      } as any;
    });
  };

  /**
   * Clears out the "More filters" options and pagination, but retains the
   * current sort/order, name search, and department filter.
   */
  const handleClearFilters = () => {
    setSearchParams((sp) => {
      const {
        hairColors: hairColorsOld,
        eyeColors: eyeColorsOld,
        genders: gendersOld,
        races: racesOld,
        notabilities: notabilitiesOld,
        visibleTattoos: visibleTattoosOld,
        badgeNumber: badgeNumberOld,
        position: positionOld,
        ...rest
      } = urlParamsToObject(sp);

      return {
        ...rest,
      } as any;
    });
  };

  const columns = [
    copTableColumns.name(name),
    copTableColumns.department(departmentId),
    copTableColumns.position(departmentId),
    copTableColumns.badgeNumber(departmentId),
    copTableColumns.updatedAt(),
  ];

  return (
    <>
      <EuiFlexGroup gutterSize="s">
        <EuiFlexItem grow={10}>
          <EuiFieldSearch
            fullWidth
            placeholder="Search by name"
            value={name}
            onChange={(e) => handleNameChange(e.target.value)}
            append={
              <EuiToolTip
                content={
                  queryType === CopFilterNameQueryType.Regex
                    ? 'Disable Regex'
                    : 'Enable Regex'
                }
              >
                <EuiButtonIcon
                  display={
                    queryType === CopFilterNameQueryType.Regex
                      ? 'base'
                      : 'empty'
                  }
                  aria-label={
                    queryType === CopFilterNameQueryType.Regex
                      ? 'Disable Regex'
                      : 'Enable Regex'
                  }
                  aria-pressed={queryType === CopFilterNameQueryType.Regex}
                  color={
                    queryType === CopFilterNameQueryType.Regex
                      ? 'primary'
                      : 'text'
                  }
                  iconType="function"
                  onClick={() => {
                    if (queryType !== CopFilterNameQueryType.Basic) {
                      handleQueryTypeChange(CopFilterNameQueryType.Basic);
                    } else {
                      handleQueryTypeChange(CopFilterNameQueryType.Regex);
                    }
                  }}
                />
              </EuiToolTip>
            }
          />
        </EuiFlexItem>
        <EuiFlexItem grow={2}>
          <DepartmentSelect
            selectedDepartmentId={departmentId}
            onDepartmentSelected={handleDepartmentChange}
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer size="s" />
      <EuiAccordion
        id="cop-filter-accordion"
        buttonContent="More filters"
        extraAction={
          <EuiButtonEmpty
            size="s"
            iconType="cross"
            color="text"
            onClick={handleClearFilters}
          >
            Clear filters
          </EuiButtonEmpty>
        }
        initialIsOpen={true}
      >
        <CopTableFilters
          values={{
            hairColors,
            eyeColors,
            genders,
            races,
            notabilities,
            visibleTattoos,
            badgeNumber,
            position,
          }}
          onChange={handleFilterChange}
        />
      </EuiAccordion>

      <EuiSpacer size="s" />
      <BasicTable
        columns={columns}
        loading={loading}
        result={{ ...result, items: result.cops ?? [] }}
        onTableChange={handleTableChange}
        sort={{
          direction: (sortDirection as 'asc' | 'desc') ?? '',
          field: (sortField as keyof CopResponseDto) ?? CopSortField.LastName,
        }}
        subject="Cops"
      />
    </>
  );
});
