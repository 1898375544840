import { FeaturesResponseDto } from '@unfrl/copdb-sdk';
import { makeAutoObservable } from 'mobx';
import { apiClient } from '../api';
import { CAPTCHA_SITEKEY, MASTODON_LINK } from '../api/config';
import { logger } from '../utils';

export type Features = FeaturesResponseDto & {
  mastodonLink: string | undefined;
};

export class FeaturesStore {
  public features: Features = {
    signUpEnabled: false,
    invitesEnabled: false,
    searchEnabled: false,
    mastodonLink: MASTODON_LINK,
    captchaEnabled: false,
  };

  /**
   * If true, then features have been loaded. Otherwise, still retrieving the features.
   */
  public initialized: boolean = false;

  public constructor() {
    makeAutoObservable(this);
    this.attemptGetFeatures();
  }

  private attemptGetFeatures = async () => {
    try {
      const features = await apiClient.features.getFeatures();
      this.setFeatures(features);
      this.setInitialized(true);
      logger.info('misc', 'successfully retrieved features');
    } catch {
      logger.info('misc', 'failed to retrieve features, retrying in 1 second');
      setTimeout(this.attemptGetFeatures, 1000);
    }
  };

  private setFeatures = (features: FeaturesResponseDto): void => {
    this.features = {
      ...features,
      mastodonLink: MASTODON_LINK,
      captchaEnabled: features.captchaEnabled && Boolean(CAPTCHA_SITEKEY),
    };
  };

  private setInitialized = (initialized: boolean): void => {
    this.initialized = initialized;
  };
}
