import {
  EuiButton,
  EuiButtonIcon,
  EuiHighlight,
  EuiPopover,
  EuiPopoverTitle,
  EuiSelectable,
  EuiText,
} from '@elastic/eui';
import { DepartmentResponseDto } from '@unfrl/copdb-sdk';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import { Fragment, useEffect, useState } from 'react';
import { apiClient } from '../../api';
import { logger } from '../../utils';
import { Flex, Spinner } from '../common';

export interface DepartmentSelectProps {
  selectedDepartmentId?: string;
  onDepartmentSelected: (departmentId: string | undefined) => void;
}

export const DepartmentSelect = observer((props: DepartmentSelectProps) => {
  const { selectedDepartmentId, onDepartmentSelected } = props;
  const [open, setOpen] = useState(false);
  const [ready, setReady] = useState(false);
  const [search, setSearch] = useState('');
  const [departments, setDepartments] = useState<DepartmentResponseDto[]>([]);

  const fetchDepartments = async (departmentId?: string) => {
    try {
      setReady(false);
      const result =
        departmentId || open
          ? await apiClient.departments.listDepartments({
              sortField: 'name',
              sortDirection: 'ascending',
              ids: departmentId ? [departmentId] : undefined,
              page: 1,
              name: search,
            })
          : null;
      if (result?.departmentResponses) {
        const selectedDepartment = selectedDepartmentId
          ? departments.find((dep) => dep.id === selectedDepartmentId)
          : undefined;
        if (selectedDepartment) {
          setDepartments([...result.departmentResponses, selectedDepartment]);
        } else {
          setDepartments(result.departmentResponses);
        }
      }
    } catch (error) {
      logger.error('error fetching departments', error);
    } finally {
      setReady(true);
    }
  };

  useEffect(() => {
    fetchDepartments();
  }, [search, open]);

  useEffect(() => {
    if (selectedDepartmentId) {
      fetchDepartments(selectedDepartmentId);
    }
  }, [selectedDepartmentId]);

  const selectedDepartment = selectedDepartmentId
    ? departments.find((dep) => dep.id === selectedDepartmentId)
    : undefined;

  return (
    <EuiPopover
      panelPaddingSize="none"
      button={
        <Flex gap={8}>
          <EuiButton
            iconType="arrowDown"
            iconSide="right"
            onClick={() => setOpen(!open)}
            fullWidth
          >
            {selectedDepartment || selectedDepartmentId
              ? selectedDepartment?.name ?? <Spinner />
              : 'Filter by department'}
          </EuiButton>

          {selectedDepartmentId && (
            <EuiButtonIcon
              iconType="cross"
              display="base"
              aria-label="Remove Department Filter"
              size="m"
              onClick={() => {
                setOpen(false);
                onDepartmentSelected(undefined);
              }}
            />
          )}
        </Flex>
      }
      isOpen={open}
      closePopover={() => setOpen(false)}
      display="block"
    >
      <EuiSelectable
        isLoading={!ready}
        searchable
        singleSelection
        searchProps={{
          autoFocus: true,
          placeholder: 'Filter list',
          compressed: true,
          onChange: debounce(setSearch, 200),
        }}
        listProps={{
          rowHeight: 65,
          showIcons: false,
        }}
        onChange={(options, event, changedOption) => {
          onDepartmentSelected(changedOption.key);
          setOpen(false);
        }}
        options={departments.map((dept) => ({
          key: dept.id,
          label: dept.name,
          data: {
            location: [dept.location.place, dept.location.region]
              .filter(Boolean)
              .join(', '),
          },
          checked:
            selectedDepartmentId && selectedDepartmentId === dept.id
              ? 'on'
              : 'off',
        }))}
        renderOption={(option, searchValue) => (
          <Fragment>
            <EuiHighlight search={searchValue}>{option.label}</EuiHighlight>
            <EuiText size="s" color="subdued">
              {(option as any).location || 'N/A'}
            </EuiText>
          </Fragment>
        )}
      >
        {(list, search) => (
          <div style={{ width: 350, maxWidth: '100%' }}>
            <EuiPopoverTitle paddingSize="s">{search}</EuiPopoverTitle>
            {list}
          </div>
        )}
      </EuiSelectable>
    </EuiPopover>
  );
});
