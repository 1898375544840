import {
  AccountApi,
  AdminApi,
  Configuration,
  CopApi,
  CopPagesApi,
  DepartmentApi,
  DepartmentPagesApi,
  FeatureApi,
  IncidentApi,
  IncidentPagesApi,
  MappingApi,
  MediaApi,
  MetaApi,
  ReportApi,
  SearchApi,
  TagApi,
  UserApi,
} from '@unfrl/copdb-sdk';
import { API_PATH } from './config';
import { GeoClient } from './geo-client';

export class ApiClient {
  private _accessToken: string = '';

  public readonly accounts: AccountApi;

  public readonly admin: AdminApi;

  public readonly cops: CopApi;

  public readonly copPages: CopPagesApi;

  public readonly departments: DepartmentApi;

  public readonly departmentPages: DepartmentPagesApi;

  public readonly incidents: IncidentApi;

  public readonly incidentPages: IncidentPagesApi;

  public readonly media: MediaApi;

  public readonly reports: ReportApi;

  public readonly users: UserApi;

  public readonly features: FeatureApi;

  public readonly meta: MetaApi;

  public readonly mapping: MappingApi;

  public readonly search: SearchApi;

  public readonly tags: TagApi;

  public readonly geoClient: GeoClient;

  public constructor() {
    const configuration = new Configuration({
      basePath: API_PATH,
      apiKey: () => `Bearer ${this._accessToken}`,
    });

    this.accounts = new AccountApi(configuration);
    this.admin = new AdminApi(configuration);
    this.cops = new CopApi(configuration);
    this.copPages = new CopPagesApi(configuration);
    this.departments = new DepartmentApi(configuration);
    this.departmentPages = new DepartmentPagesApi(configuration);
    this.incidents = new IncidentApi(configuration);
    this.incidentPages = new IncidentPagesApi(configuration);
    this.media = new MediaApi(configuration);
    this.reports = new ReportApi(configuration);
    this.users = new UserApi(configuration);
    this.features = new FeatureApi(configuration);
    this.meta = new MetaApi(configuration);
    this.mapping = new MappingApi(configuration);
    this.search = new SearchApi(configuration);
    this.tags = new TagApi(configuration);
    this.geoClient = new GeoClient();
  }

  public setAccessTokenHeader(token: string) {
    this._accessToken = token;
  }
}

export const apiClient = new ApiClient();
