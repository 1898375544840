import { EuiLink, EuiTitle } from '@elastic/eui';
import { DepartmentResponseDto } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { camelToSentence, copsDepartmentFilterPath } from '../../utils';
import { LabelOrMissing } from '../common';

export interface DepartmentProfileHeaderProps {
  departmentInfo: DepartmentResponseDto;
}

export const DepartmentProfileHeader = observer(
  (props: DepartmentProfileHeaderProps) => {
    const { departmentInfo } = props;

    return (
      <div>
        <EuiTitle>
          <h1>{departmentInfo.name}</h1>
        </EuiTitle>
        <Link to={copsDepartmentFilterPath(departmentInfo.id)}>
          <EuiLink>View cops for {departmentInfo.shortName}</EuiLink>
        </Link>
        <LabelOrMissing
          name="Short name"
          value={departmentInfo.shortName}
          type="Text"
        />
        <LabelOrMissing
          name="Department type"
          value={camelToSentence(departmentInfo.type)}
          type="Text"
        />
      </div>
    );
  },
);
