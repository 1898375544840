import { EuiImage } from '@elastic/eui';
import { observer } from 'mobx-react';

type ImageSize = 's' | 'm' | 'l' | 'xl' | 'fullWidth' | 'original';

export interface CopAvatarProps {
  size: ImageSize | number | string;
  name: string;
  photoUrl?: string | null;
  disableFullScreen?: boolean;
}

export const CopAvatar = observer((props: CopAvatarProps) => {
  const { name, photoUrl, size, disableFullScreen } = props;

  return (
    <EuiImage
      allowFullScreen={!disableFullScreen}
      alt={name}
      src={photoUrl || '/pig_glasses_big.png'}
      size={size}
      loading="lazy"
      style={{
        height: 'auto',
        objectFit: 'contain',
        borderRadius: 2,
      }}
    />
  );
});
