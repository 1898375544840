import { LocationDto, ReportStatus, ReportVersion } from '@unfrl/copdb-sdk';
import { ReportDetails } from '../../stores/report.store';
import { dateUtils, reportPath } from '../../utils';
import { DateOccurred } from '../date-occurred';
import { LocationPlaceLink } from '../location';
import { BasicTableColumn, StringCell, TitleCell } from '../table';
import { ReportStatusBadge } from './report-status-badge';

const title = (): BasicTableColumn<ReportDetails> => {
  return {
    field: 'version',
    name: 'Title',
    render: (value: ReportVersion) => (
      <TitleCell
        title={value.reportDto?.title || 'Untitled'}
        linkTo={reportPath(value.reportId!)}
      />
    ),
    width: '30%',
  };
};

const reportStatus = (): BasicTableColumn<ReportDetails> => {
  return {
    field: 'report.status',
    name: 'Status',
    render: (value: ReportStatus) => <ReportStatusBadge status={value} />,
  };
};

const dateOccurred = (): BasicTableColumn<ReportDetails> => {
  return {
    field: 'version.reportDto.dateOccurred',
    name: 'Date occurred',
    dataType: 'date',
    render: (date?: Date) => <DateOccurred date={date} />,
  };
};

const location = (): BasicTableColumn<ReportDetails> => {
  return {
    field: 'version.reportDto.location',
    name: 'Location',
    render: (value?: LocationDto) => (
      <LocationPlaceLink location={value} size="relative" />
    ),
    width: '25%',
  };
};

const updatedAt = (): BasicTableColumn<ReportDetails> => {
  return {
    field: 'version.updatedAt',
    name: 'Last updated',
    render: (value: Date) => (
      <StringCell
        getValue={() => dateUtils.fromNow(value)}
        getTitle={() => dateUtils.format(value)}
      />
    ),
  };
};

export const reportTableColumns = {
  title,
  reportStatus,
  dateOccurred,
  location,
  updatedAt,
};
