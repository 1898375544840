import {
  CriteriaWithPagination,
  EuiAccordion,
  EuiButtonEmpty,
  EuiFieldSearch,
  EuiSpacer,
} from '@elastic/eui';
import {
  IncidentFilterResponse,
  IncidentResponseDto,
  IncidentSortField,
  SortDirection,
} from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { Fragment, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounce, useLocalStorage } from 'usehooks-ts';
import { apiClient } from '../../api';
import { logger, urlParamsToObject } from '../../utils';
import { IncidentTable } from './incident-table';
import {
  IncidentFilterItems,
  IncidentTableFilters,
} from './incident-table-filters';
import moment from 'moment';

const sortDirectionMapping: any = {
  asc: SortDirection.Ascending,
  desc: SortDirection.Descending,
};

export const IncidentTableContainer = observer(() => {
  const [result, setResult] = useState<IncidentFilterResponse>({
    pageNumber: 1,
    pageSize: 10,
    count: 0,
  });
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const page = parseInt(searchParams.get('page') ?? '1');
  const pageSize = parseInt(searchParams.get('pageSize') ?? '10');
  const searchValue = searchParams.get('search') ?? '';
  const sortField = searchParams.get('sort') ?? 'dateOccurred';
  const sortDirection = searchParams.get('order') ?? 'desc';

  const preliminaryRaw = searchParams.get('preliminary');
  const preliminary =
    preliminaryRaw === 'true'
      ? true
      : preliminaryRaw === 'false'
      ? false
      : undefined;

  const tagsRaw = searchParams.getAll('tags') as string[];
  const tags = tagsRaw.length > 0 ? tagsRaw : undefined;
  const tagsJson = JSON.stringify(tags);

  const startDateRaw = searchParams.get('startDate');
  const startDate = startDateRaw ? new Date(startDateRaw) : undefined;

  const endDateRaw = searchParams.get('endDate');
  const endDate = endDateRaw ? new Date(endDateRaw) : undefined;

  const debouncedSearchValue = useDebounce<string>(searchValue, 150);

  const [savedStart, setSavedStart] = useLocalStorage<string>(
    'INC_TABLE_START',
    moment().subtract(5, 'y').toString(),
  );

  const [savedEnd, setSavedEnd] = useLocalStorage<string | undefined>(
    'INC_TABLE_END',
    undefined,
  );

  useEffect(() => {
    // TODO: this needs a cleanup function, see `useEffect` docs in react for example of race condition
    const fetchIncidents = async () => {
      try {
        setLoading(true);

        const result = await apiClient.incidents.listIncidents({
          searchValue: debouncedSearchValue,
          sortField: sortField ? (sortField as IncidentSortField) : undefined,
          sortDirection: sortDirection
            ? sortDirectionMapping[sortDirection]
            : undefined,
          page,
          pageSize,
          preliminary,
          startDate,
          endDate,
          tags,
        });

        setResult(result);
      } catch (error) {
        logger.error('error fetching incidents', error);
      } finally {
        setLoading(false);
      }
    };

    fetchIncidents();
  }, [
    page,
    pageSize,
    debouncedSearchValue,
    sortField,
    sortDirection,
    preliminaryRaw,
    startDateRaw,
    endDateRaw,
    tagsJson,
  ]);

  const handleTableChange = ({
    page,
    sort,
  }: CriteriaWithPagination<IncidentResponseDto>) => {
    setSearchParams((sp) => {
      return {
        ...urlParamsToObject(sp),
        page: (page.index + 1).toString(),
        pageSize: page.size.toString(),
        sort: sort?.field || 'dateOccurred',
        order: sort?.direction || 'desc',
      } as any;
    });
  };

  const handleSearchChange = (search: string) => {
    setSearchParams((sp) => {
      return {
        ...urlParamsToObject(sp),
        search,
      } as any;
    });
  };

  const handleFilterChange = (filters: IncidentFilterItems) => {
    setSearchParams((sp) => {
      const {
        preliminary: preliminaryOld,
        startDate: startDateOld,
        endDate: endDateOld,
        tags: tagsOld,
        ...rest
      } = urlParamsToObject(sp);

      const { preliminary, startDate, endDate, dateFilterEnabled, tags } =
        filters;

      if (startDate) {
        setSavedStart(startDate.toISOString());
      }
      if (endDate) {
        setSavedEnd(endDate === 'now' ? undefined : endDate.toISOString());
      }
      const result = {
        ...rest,
        ...(preliminary !== undefined && { preliminary }),
        ...(tags && { tags: tags.map((tag) => tag.name) }),
      };
      if (dateFilterEnabled) {
        if (startDate) {
          result.startDate = startDate;
        } else if (savedStart) {
          result.startDate = new Date(savedStart);
        }

        if (endDate) {
          // If endDate === 'now' leave it undefined
          if (endDate !== 'now') {
            result.endDate = endDate.toISOString();
          }
        } else if (savedEnd) {
          result.endDate = new Date(savedEnd);
        }
      }

      return result;
    });
  };

  /**
   * Clears out the "More filters" options and pagination, but retains the
   * current sort/order, name search, and department filter.
   */
  const handleClearFilters = () => {
    setSearchParams((sp) => {
      const { preliminary, startDate, endDate, tags, ...rest } =
        urlParamsToObject(sp);

      return {
        ...rest,
      } as any;
    });
  };

  return (
    <Fragment>
      <EuiFieldSearch
        fullWidth
        placeholder="Search by summary"
        value={searchValue}
        onChange={(e) => handleSearchChange(e.target.value)}
      />

      <EuiSpacer size="s" />
      <EuiAccordion
        id="incident-filter-accordion"
        buttonContent="More filters"
        extraAction={
          <EuiButtonEmpty
            size="s"
            iconType="cross"
            color="text"
            onClick={handleClearFilters}
          >
            Clear filters
          </EuiButtonEmpty>
        }
        initialIsOpen={true}
      >
        <IncidentTableFilters
          values={{
            preliminary,
            startDate,
            endDate,
            dateFilterEnabled: Boolean(startDate || endDate),
            tags: tags?.map((tag) => ({ name: tag })),
          }}
          onChange={handleFilterChange}
        />
      </EuiAccordion>

      <EuiSpacer size="s" />
      <IncidentTable
        loading={loading}
        result={{ ...result, items: result.incidentResponses ?? [] }}
        onTableChange={handleTableChange}
        sort={{
          direction: sortDirection as 'asc' | 'desc',
          field: sortField as keyof IncidentResponseDto,
        }}
        searchValue={searchValue}
      />
    </Fragment>
  );
});
