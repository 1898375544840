import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiForm,
  EuiFormRow,
  EuiTitle,
  useGeneratedHtmlId,
} from '@elastic/eui';
import { InviteUserDto } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { apiClient } from '../../api';
import { useStores } from '../../hooks';
import { AuthRole } from '../../stores';
import { validateEmail } from '../../utils';
import { Flex } from '../common';
import { RoleSelect } from './role-select';

export interface UserInviteFormProps {
  onClose: () => void;
}

export const UserInviteForm = observer((props: UserInviteFormProps) => {
  const { onClose } = props;
  const { toastStore } = useStores();
  const [saving, setSaving] = useState(false);
  const [state, setState] = useState<InviteUserDto>({
    email: '',
    roleName: null,
  });
  const formFlyoutId = useGeneratedHtmlId({
    prefix: 'inviteUserForm',
  });

  const handleSave = async () => {
    if (!state.email || !validateEmail(state.email)) {
      return;
    }

    try {
      setSaving(true);
      await apiClient.admin.inviteUser({ inviteUserDto: state });
      setState({ email: '', roleName: null });
      toastStore.showSuccess('User invite sent');
    } catch (error) {
      toastStore.showApiError(error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <EuiFlyout maxWidth={500} onClose={onClose} aria-labelledby={formFlyoutId}>
      <EuiFlyoutHeader hasBorder>
        <EuiTitle size="m">
          <h2 id={formFlyoutId}>Invite user</h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiForm>
          <EuiFormRow fullWidth label="Email">
            <EuiFieldText
              fullWidth
              type="email"
              value={state.email}
              onChange={(e) => setState({ ...state, email: e.target.value })}
            />
          </EuiFormRow>
          <EuiFormRow fullWidth label="Role">
            <RoleSelect
              selectedRole={state.roleName as AuthRole}
              onChange={(role) => setState({ ...state, roleName: role })}
            />
          </EuiFormRow>
        </EuiForm>
      </EuiFlyoutBody>
      <EuiFlyoutFooter>
        <Flex align="center" justify="flex-end" gap={16}>
          <EuiButtonEmpty
            size="s"
            iconType="cross"
            color="text"
            onClick={onClose}
            disabled={saving}
          >
            Cancel
          </EuiButtonEmpty>
          <EuiButton
            fill
            size="s"
            onClick={handleSave}
            isLoading={saving}
            disabled={!state.email || !validateEmail(state.email)}
          >
            Invite
          </EuiButton>
        </Flex>
      </EuiFlyoutFooter>
    </EuiFlyout>
  );
});
