import { observer } from 'mobx-react';
import { useStores } from '../../hooks';
import { Features } from '../../stores/features.store';
import { ResourceNotFound } from './resource-not-found';

export interface FeatureRequiredProps {
  children: JSX.Element;
  feature: keyof Features;
}

/**
 * Returns props.children if the featuresStore is ready and enabled for props.feature.
 * If ready and feature is not enabled, then the 404 resource not found is returned.
 */
export const FeatureRequired = observer((props: FeatureRequiredProps) => {
  const { children, feature } = props;
  const { featuresStore } = useStores();

  if (!featuresStore.initialized) {
    return null;
  }

  if (featuresStore.features[feature]) {
    return children;
  }

  return <ResourceNotFound />;
});
