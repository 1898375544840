import { CSSProperties } from 'react';

const SIZES = {
  sm: 28,
  md: 48,
  lg: 64,
};

export interface LogoProps {
  size?: 'sm' | 'md' | 'lg';
  style?: CSSProperties;
}

export const Logo = ({ size, style }: LogoProps) => {
  const imgSize = SIZES[size ?? 'sm'];

  return (
    <img
      src="/pig_glasses_small.png"
      alt="Cop DB"
      height={imgSize}
      width={imgSize}
      style={{ ...style }}
    />
  );
};
