import { Loader } from '@googlemaps/js-api-loader';
import { LocationDto } from '@unfrl/copdb-sdk';
import { GOOGLE_MAPS_API_KEY } from '../api/config';

export interface NewPlaceSuggestion {
  id: string;
  placeName: string;
  displayName: string;
}

export class GeoClient {
  private loader = new Loader({
    apiKey: GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });

  private sessionToken: google.maps.places.AutocompleteSessionToken | null =
    null;

  private placePredictions: google.maps.places.PlacePrediction[] = [];

  public getSuggestions = async (
    value: string,
  ): Promise<NewPlaceSuggestion[]> => {
    const { AutocompleteSuggestion } = await this.loader.importLibrary(
      'places',
    );

    // If there is no session token make a new one
    this.sessionToken = this.sessionToken
      ? this.sessionToken
      : new google.maps.places.AutocompleteSessionToken();

    const results = await AutocompleteSuggestion.fetchAutocompleteSuggestions({
      input: value,
      sessionToken: this.sessionToken,
    });

    this.placePredictions = results.suggestions.flatMap((sug) =>
      sug.placePrediction ? [sug.placePrediction] : [],
    );

    const suggestions: NewPlaceSuggestion[] = results.suggestions.map(
      (result) => {
        return {
          id: result.placePrediction?.placeId!,
          placeName: result.placePrediction?.text?.text!,
          displayName: result.placePrediction?.mainText?.text!,
        };
      },
    );

    return suggestions;
  };

  public getPlaceFromPlaceId = async (
    placeId: string,
  ): Promise<LocationDto | undefined> => {
    const matchingPrediction = this.placePredictions.find(
      (pred) => pred.placeId === placeId,
    );
    if (!matchingPrediction) {
      return;
    }

    this.sessionToken = null; // Must invalidate the token
    this.placePredictions = [];
    const placeResult = await matchingPrediction.toPlace().fetchFields({
      fields: [
        'formattedAddress',
        'location',
        'displayName',
        'formattedAddress',
      ],
    });

    const { place } = placeResult;

    const dto: LocationDto = {
      placeName: place.formattedAddress ?? 'NA',
      // GeoJSON point go Long, Lat https://datatracker.ietf.org/doc/html/rfc7946#appendix-A.1
      coordinates: {
        centerLongitude: place.location?.lng()!,
        centerLatitude: place.location?.lat()!,
      },
      place: place.displayName,
    };

    return dto;
  };
}
