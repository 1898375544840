import { EuiCheckbox } from '@elastic/eui';
import { DepartmentCapabilities } from '@unfrl/copdb-sdk';
import { Fragment } from 'react';
import { camelToSentence } from '../../utils';
import { MissingDataText } from '../common';

export interface DepartmentCapabilitiesSelectProps {
  selectedCapabilities: DepartmentCapabilities[];
  onChange?: (capabilities: DepartmentCapabilities[]) => void;
  nonInteractive?: boolean;
}

export const DepartmentCapabilitiesSelect = (
  props: DepartmentCapabilitiesSelectProps,
) => {
  const { selectedCapabilities, onChange, nonInteractive } = props;

  const options = Object.values(DepartmentCapabilities);

  const checkBoxes = options
    .map((option) => {
      const index = selectedCapabilities.indexOf(option);
      if (nonInteractive && index === -1) {
        return null;
      }
      return (
        <EuiCheckbox
          disabled={nonInteractive}
          key={option}
          id={option}
          label={camelToSentence(option)}
          checked={index > -1}
          onChange={(e) => {
            if (onChange) {
              index > -1
                ? selectedCapabilities.splice(index, 1)
                : selectedCapabilities.push(option as DepartmentCapabilities);
              onChange(selectedCapabilities);
            }
          }}
        />
      );
    })
    .filter((checkbox) => Boolean(checkbox));

  if (nonInteractive && checkBoxes.length === 0) {
    return <MissingDataText text="No capabilities recorded." />;
  }

  return <Fragment>{checkBoxes}</Fragment>;
};
