import {
  EditStatus,
  Media,
  MediaEdit,
  MediaRelationType,
  MediaUpdateDto,
} from '@unfrl/copdb-sdk';
import { uniq } from 'lodash';
import { makeAutoObservable, onBecomeObserved, onBecomeUnobserved } from 'mobx';
import { apiClient } from '../api';
import { DataStore } from './data.store';
import { RootStore } from './root.store';

export interface MediaEditMapping {
  id: string;
  media: Media;
  edits: MediaEdit[];
}

export class MediaEditStore {
  private readonly _root: RootStore;

  public data: DataStore<MediaEdit> = new DataStore();

  public loading: boolean = false;

  private get uniqueMediaIds(): string[] {
    return uniq(this.data.orderedItems.map((edit) => edit.mediaId));
  }

  public get mediaEditMap(): MediaEditMapping[] {
    return this.uniqueMediaIds
      .map((id) => ({
        id,
        media: this._root.mediaStore.data.getItem(id),
        edits: this.data.orderedItems.filter((edit) => edit.mediaId === id),
      }))
      .filter((mapping) =>
        Boolean(mapping.media && mapping.edits.length),
      ) as MediaEditMapping[];
  }

  public constructor(rootStore: RootStore) {
    this._root = rootStore;

    makeAutoObservable(this);

    onBecomeObserved(this, 'mediaEditMap', () =>
      this.fetchMediaEdits(EditStatus.Pending),
    );
    onBecomeUnobserved(this, 'mediaEditMap', this.data.clearItems);
  }

  public getEditMapping = (mediaId: string): MediaEditMapping | undefined => {
    return this.mediaEditMap.find((map) => map.id === mediaId);
  };

  public fetchMediaEdits = async (status?: EditStatus): Promise<void> => {
    try {
      this.setLoading(true);

      const edits = await apiClient.media.listMediaEdits({
        status: status ?? EditStatus.Pending,
      });

      this.data.setList(edits);

      const mediaIdsToFetch = uniq(edits.map((edit) => edit.mediaId));

      const promises = [this._root.mediaStore.fetchMedias(mediaIdsToFetch)];

      let personIdsToFetch: string[] = [];
      let incidentIdsToFetch: string[] = [];

      edits.forEach((edit) => {
        edit.editDto.upsertMediaRelationDtos?.forEach((mrd) => {
          if (mrd.type === MediaRelationType.Person) {
            personIdsToFetch.push(mrd.id);
          } else {
            incidentIdsToFetch.push(mrd.id);
          }
        });
      });
      personIdsToFetch = uniq(personIdsToFetch);
      incidentIdsToFetch = uniq(incidentIdsToFetch);

      if (personIdsToFetch.length) {
        promises.push(
          this._root.copStore.fetchCopsByPersonIds(personIdsToFetch),
        );
      }

      if (incidentIdsToFetch.length) {
        promises.push(
          this._root.incidentStore.fetchIncidents(incidentIdsToFetch),
        );
      }

      await Promise.all(promises);
    } finally {
      this.setLoading(false);
    }
  };

  public createMediaEdit = async (
    mediaId: string,
    mediaUpdateDto: MediaUpdateDto,
  ): Promise<void> => {
    const edit = await apiClient.media.createMediaEdit({
      mediaId,
      mediaUpdateDto,
    });

    this.data.setItem(edit);
  };

  public updateMediaEditStatus = async (
    editId: string,
    status: EditStatus,
  ): Promise<void> => {
    const updated = await apiClient.media.updateMediaEditStatus({
      editId,
      status,
    });

    this.handleUpdatedEdit(updated);
  };

  //#region Actions
  private handleUpdatedEdit = async (updated: MediaEdit): Promise<void> => {
    const { mediaId, id } = updated;

    if (updated.status === EditStatus.Approved) {
      await this._root.mediaStore.fetchMedias([mediaId]);

      this.data.orderedItems
        .filter((edit) => edit.mediaId === mediaId)
        .forEach((edit) => this.data.deleteItem(edit.id));
    }

    if (updated.status === EditStatus.Denied) {
      this.data.deleteItem(id);
    }
  };

  private setLoading = (loading: boolean): void => {
    this.loading = loading;
  };
  //#endregion
}
