import {
  EuiButton,
  EuiContextMenu,
  EuiContextMenuPanelItemDescriptor,
  EuiPopover,
} from '@elastic/eui';
import { ReportStatus } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useStores } from '../../hooks';

const OPTIONS_ID = 'report-status-options';

export const ModerateReportToolbar = observer(() => {
  const { dialogStore, reportStore, toastStore } = useStores();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!isOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { activeItem: activeReport } = reportStore.data;
  if (!activeReport) {
    return null;
  }

  // currently cannot change the status of approved reports, because upon
  // approval multiple entities + relationships are created
  if (activeReport.report.status === ReportStatus.Approved) {
    return null;
  }

  const handleChangeStatus = (status: ReportStatus) => async () => {
    try {
      await reportStore.updateReportStatus(activeReport.report.id, status);
    } catch (error: any) {
      await toastStore.showApiError(error);
    }
  };

  const handleConfirmApprove = () => {
    handleClose();
    dialogStore.openDialog({
      title: 'Approve this report?',
      content: (
        <p>
          This will make the report public and the status{' '}
          <strong>cannot</strong> be changed.
        </p>
      ),
      onConfirm: handleChangeStatus(ReportStatus.Approved),
      confirmText: 'Approve',
    });
  };

  const handleConfirmPending = () => {
    handleClose();
    dialogStore.openDialog({
      title: 'Mark report as pending?',
      content: <p>This will allow the author to edit the report again.</p>,
      onConfirm: handleChangeStatus(ReportStatus.Pending),
      confirmText: 'Confirm',
    });
  };

  const handleConfirmDeny = () => {
    handleClose();
    dialogStore.openDialog({
      title: 'Deny this report?',
      content: <p>This will not let the author edit the report again.</p>,
      onConfirm: handleChangeStatus(ReportStatus.Denied),
      confirmText: 'Deny',
      isDelete: true,
    });
  };

  const button = (
    <EuiButton
      fill
      size="s"
      iconType="arrowDown"
      iconSide="right"
      onClick={handleOpen}
    >
      Set status
    </EuiButton>
  );

  let approveItem: EuiContextMenuPanelItemDescriptor = {
    name: 'Approve',
    key: 'approved',
    icon: 'check',
    onClick: handleConfirmApprove,
  };

  if (!reportStore.userCanApproveActive) {
    approveItem = {
      ...approveItem,
      disabled: true,
      toolTipContent: 'Non-Admin users cannot approve reports they created',
    };
  }

  const items: EuiContextMenuPanelItemDescriptor[] = [
    approveItem,
    {
      name: 'Pending',
      key: 'pending',
      icon: 'pencil',
      onClick: handleConfirmPending,
    },
    {
      name: 'Deny',
      key: 'denied',
      icon: 'cross',
      onClick: handleConfirmDeny,
    },
  ];

  const applicableItems = items.filter(
    (item) => item.key !== activeReport?.report.status!.toString(),
  );

  const panels = [
    {
      id: 0,
      title: 'Set the report status',
      items: applicableItems,
    },
  ];

  return (
    <EuiPopover
      id={OPTIONS_ID}
      button={button}
      isOpen={isOpen}
      closePopover={handleClose}
      panelPaddingSize="none"
      anchorPosition="downLeft"
    >
      <EuiContextMenu initialPanelId={0} panels={panels} />
    </EuiPopover>
  );
});
