import { HubConnection } from '@microsoft/signalr';
import { CommentDto, ReportResponseDto } from '@unfrl/copdb-sdk';
import { BaseMethods } from './base.methods';

export class ReportMethods extends BaseMethods<ReportResponseDto> {
  public readonly comments: Omit<
    BaseMethods<CommentDto>,
    'connect' | 'disconnect'
  >;

  public constructor(connection: HubConnection) {
    super(connection, 'Report');

    this.comments = new BaseMethods(connection, 'ReportComment');
  }
}
