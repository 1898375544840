import {
  EuiAvatar,
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeaderSectionItemButton,
  EuiHealth,
  EuiLoadingSpinner,
  EuiPopover,
  EuiSpacer,
  EuiText,
} from '@elastic/eui';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useNavProps, useStores } from '../../hooks';
import { AuthStatus, RtmStatus } from '../../stores';
import { loginPath, signupPath, userProfilePath } from '../../utils';
import { Flex } from '../common';

const USER_POPOVER_ID = 'headerUserMenu';

export const UserMenu = observer(() => {
  const [open, setOpen] = useState(false);
  const { appStore, authStore, featuresStore } = useStores();
  const getNavProps = useNavProps();

  const handleMenuButtonClick = () => {
    setOpen(!open);
  };

  const handleCloseMenu = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    authStore.logout();
  };

  if (
    authStore.status === AuthStatus.Initializing ||
    authStore.status === AuthStatus.Authenticating
  ) {
    return (
      <EuiLoadingSpinner size="l" style={{ marginLeft: 8, marginRight: 8 }} />
    );
  }

  if (!authStore.authenticated) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <EuiButtonEmpty
          {...getNavProps(loginPath())}
          size="s"
          style={{ marginRight: 8 }}
        >
          Login
        </EuiButtonEmpty>
        {featuresStore.features.signUpEnabled && (
          <EuiButton
            {...getNavProps(signupPath())}
            size="s"
            style={{ marginRight: 8 }}
          >
            Create account
          </EuiButton>
        )}
      </div>
    );
  }

  const button = (
    <EuiHeaderSectionItemButton
      aria-controls={USER_POPOVER_ID}
      aria-expanded={open}
      aria-haspopup="true"
      aria-label="Account menu"
      onClick={handleMenuButtonClick}
    >
      <EuiAvatar name={authStore.user?.userName ?? ''} size="s" />
    </EuiHeaderSectionItemButton>
  );

  const getRtmStatusColor = () => {
    if (appStore.rtmStatus === RtmStatus.Connected) {
      return 'success';
    }

    if (appStore.rtmStatus === RtmStatus.Connecting) {
      return 'warning';
    }

    return 'danger';
  };

  return (
    <EuiPopover
      id={USER_POPOVER_ID}
      button={button}
      isOpen={open}
      anchorPosition="downRight"
      closePopover={handleCloseMenu}
      panelPaddingSize="m"
    >
      <EuiFlexGroup gutterSize="s" style={{ width: 350 }}>
        <EuiFlexItem grow={false} style={{ alignItems: 'center' }}>
          <EuiAvatar name={authStore.user?.userName ?? ''} size="xl" />
          <EuiSpacer size="xs" />
          <EuiHealth color={getRtmStatusColor()}>
            {appStore.rtmStatus}
          </EuiHealth>
        </EuiFlexItem>
        <EuiFlexItem>
          <div style={{ marginLeft: 8 }}>
            <EuiText>
              <p>{authStore.user?.userName ?? ''}</p>
            </EuiText>
            <EuiText size="s" color="subdued">
              <p>{authStore.user?.roles?.join(', ') ?? ''}</p>
            </EuiText>
          </div>
          <EuiSpacer size="m" />
          <Flex justify="space-between">
            <EuiButtonEmpty
              {...getNavProps(userProfilePath(), handleCloseMenu)}
              size="s"
            >
              Your profile
            </EuiButtonEmpty>
            <EuiButtonEmpty size="s" onClick={handleLogout}>
              Log out
            </EuiButtonEmpty>
          </Flex>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPopover>
  );
});
