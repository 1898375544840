import { EuiIcon, EuiToolTip } from '@elastic/eui';

export const MissingDataTooltip = ({ children }: { children?: any }) => {
  return (
    <EuiToolTip
      content={
        <p>
          This data is missing. If you know it, please submit an edit by
          clicking <strong>Suggest edit</strong>.
        </p>
      }
    >
      {children ?? <EuiIcon type="alert" />}
    </EuiToolTip>
  );
};
