import { observer } from 'mobx-react';

// see: https://mobx.js.org/react-optimizations.html#function-props-
export const StringCell = observer(
  ({
    getValue,
    getTitle,
  }: {
    getValue: () => string | undefined | null;
    getTitle?: () => string;
  }) => {
    return <span title={getTitle ? getTitle() : ''}>{getValue() ?? ''}</span>;
  },
);
