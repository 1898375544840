import { RootStore } from './root.store';

export * from './app.store';
export * from './auth.store';
export * from './cop-edit.store';
export * from './cop.store';
export * from './dialog.store';
export * from './incident-edit.store';
export * from './incident.store';
export * from './mapping.store';
export * from './media-edit.store';
export * from './media.store';
export * from './report.store';
export * from './toast.store';
export * from './user.store';

export const stores = new RootStore();
