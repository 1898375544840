import { EuiButtonIcon, EuiLink, EuiText, copyToClipboard } from '@elastic/eui';
import { Media } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { useStores } from '../../../hooks';
import { dateUtils, mediaDetailsPath } from '../../../utils';
import { Flex } from '../../common';

export interface MediaLinkProps {
  media: Media;
  displayMediaDetailsLink?: boolean;
}

export const MediaLink = observer((props: MediaLinkProps) => {
  const { media, displayMediaDetailsLink } = props;
  const name = media.displayName || media.name || media.objectKey;
  const { toastStore } = useStores();

  const handleCopyLink = () => {
    copyToClipboard(`${window.origin}${mediaDetailsPath(media.id)}`);
    toastStore.showSuccess('Media details link copied to clipboard');
  };

  return (
    <Flex align="flex-start" justify="space-between" gap={8} auto>
      <Flex column align="flex-start">
        {displayMediaDetailsLink ? (
          <Link
            to={mediaDetailsPath(media.id)}
            style={{ wordBreak: 'break-word' }}
          >
            <EuiLink>{name}</EuiLink>
          </Link>
        ) : (
          <EuiText size="relative" style={{ wordBreak: 'break-word' }}>
            {name}
          </EuiText>
        )}
        <EuiText size="xs" color="subdued">
          {media.contentType} - {dateUtils.format(media.createdAt)}
        </EuiText>
      </Flex>
      <Flex gap={4}>
        {displayMediaDetailsLink && (
          <EuiButtonIcon
            size="s"
            color="text"
            iconType="copyClipboard"
            title="Copy media link"
            aria-label="Copy media link to clipboard"
            onClick={handleCopyLink}
          />
        )}
        {media.url && (
          <EuiButtonIcon
            size="s"
            color="text"
            iconType="popout"
            title="Open media asset"
            target="_blank"
            href={media.url}
          />
        )}
      </Flex>
    </Flex>
  );
});
