import { EuiSuperSelect, EuiText } from '@elastic/eui';
import { Fragment } from 'react';
import { AuthRole } from '../../stores';

const ROLE_OPTIONS = [
  {
    value: 'none',
    inputDisplay: 'None',
    dropdownDisplay: (
      <Fragment>
        <strong>None</strong>
        <EuiText size="s" color="subdued">
          <p>
            No special permissions. User is able to create and submit reports.
          </p>
        </EuiText>
      </Fragment>
    ),
  },
  {
    value: AuthRole.Moderators,
    inputDisplay: AuthRole.Moderators,
    dropdownDisplay: (
      <Fragment>
        <strong>{AuthRole.Moderators}</strong>
        <EuiText size="s" color="subdued">
          <p>
            User is able to view all reports, request changes, and modify their
            status.
          </p>
        </EuiText>
      </Fragment>
    ),
  },
  {
    value: AuthRole.Administrators,
    inputDisplay: AuthRole.Administrators,
    dropdownDisplay: (
      <Fragment>
        <strong>{AuthRole.Administrators}</strong>
        <EuiText size="s" color="subdued">
          <p>
            User has {AuthRole.Moderators} permissions, and can also manage user
            access and their roles.
          </p>
        </EuiText>
      </Fragment>
    ),
  },
];

export interface RoleSelectProps {
  selectedRole: AuthRole | null;
  onChange: (role: AuthRole | null) => void;
}

export const RoleSelect = (props: RoleSelectProps) => {
  const { selectedRole, onChange } = props;

  return (
    <EuiSuperSelect
      hasDividers
      fullWidth
      itemLayoutAlign="top"
      options={ROLE_OPTIONS}
      valueOfSelected={selectedRole ?? 'none'}
      onChange={(value) =>
        onChange(value === 'none' ? null : (value as AuthRole))
      }
    />
  );
};
