import { EuiFlexGroup, EuiFlexItem, EuiTitle } from '@elastic/eui';
import { CopDepartmentResponseDto, CopResponseDto } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import {
  formatNameFromResponseDto,
  getBadgeNumberText,
  getDepartmentStatusText,
  getPositionText,
  getProfilePhotoFromResponse,
} from '../../utils';
import { LabelOrMissing } from '../common';
import { CopAvatar } from './cop-avatar';

export interface CopProfileHeaderProps {
  copResponse: CopResponseDto;
  copDepartments: CopDepartmentResponseDto[];
}

export const CopProfileHeader = observer((props: CopProfileHeaderProps) => {
  const { copResponse, copDepartments } = props;
  const fullName = formatNameFromResponseDto(copResponse);
  const photoUrl = getProfilePhotoFromResponse(copResponse);

  return (
    <EuiFlexGroup gutterSize="s">
      <EuiFlexItem>
        <CopAvatar size="s" name={fullName} photoUrl={photoUrl} />
      </EuiFlexItem>
      <EuiFlexItem grow={6}>
        <div>
          <EuiTitle>
            <h1>{fullName}</h1>
          </EuiTitle>
          <LabelOrMissing
            name="Department"
            value={getDepartmentStatusText(copDepartments)}
          />
          <LabelOrMissing
            name="Position"
            value={getPositionText(copDepartments)}
          />
          <LabelOrMissing
            name="Badge"
            value={getBadgeNumberText(copDepartments)}
          />
        </div>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
});
