import { autorun, makeAutoObservable } from 'mobx';
import { rtmClient } from '../api';
import { logger } from '../utils';

const APP_STORE_KEY = 'appStore';
const APP_HEADER_HEIGHT = 48;
const APP_SIDEBAR_WIDTH = 240;

export enum RtmStatus {
  Connecting = 'Connecting',
  Connected = 'Connected',
  Disconnected = 'Disconnected',
}

export class AppStore {
  public appHeaderHeight: number;

  public appSidebarWidth: number;

  public navIsOpen: boolean;

  public navIsDocked: boolean;

  public rtmStatus: RtmStatus = RtmStatus.Disconnected;

  public get appSidebarHeight(): string {
    return `calc(100vh - ${this.appHeaderHeight}px)`;
  }

  private get asJson() {
    return {
      appHeaderHeight: this.appHeaderHeight,
      appSidebarWidth: this.appSidebarWidth,
      navIsOpen: this.navIsOpen,
      navIsDocked: this.navIsDocked,
    };
  }

  public constructor() {
    rtmClient.onReconnected(this.handleReconnected);
    rtmClient.onReconnecting(this.handleReconnecting);
    rtmClient.onStopped(this.handleStopped);

    const data: Partial<AppStore> = JSON.parse(
      localStorage.getItem(APP_STORE_KEY) ?? '{}',
    );

    this.appHeaderHeight = data.appHeaderHeight || APP_HEADER_HEIGHT;
    this.appSidebarWidth = data.appSidebarWidth || APP_SIDEBAR_WIDTH;
    this.navIsOpen = data.navIsOpen ?? false;
    this.navIsDocked = data.navIsDocked ?? true;

    makeAutoObservable(this);

    autorun(() =>
      localStorage.setItem(APP_STORE_KEY, JSON.stringify(this.asJson)),
    );
  }

  public toggleNavIsOpen = (): void => {
    this.navIsOpen = !this.navIsOpen;
  };

  public toggleNavIsDocked = (): void => {
    this.navIsDocked = !this.navIsDocked;
  };

  public restartRtm = async (): Promise<void> => {
    await rtmClient.stop();

    await this.startRtm();
  };

  public startRtm = async (): Promise<void> => {
    try {
      this.setRtmStatus(RtmStatus.Connecting);

      await rtmClient.start();

      this.setRtmStatus(RtmStatus.Connected);
    } catch (error) {
      logger.error('failed to connect rtm', error);
      this.setRtmStatus(RtmStatus.Disconnected);
    }
  };

  private handleStopped = () => {
    this.setRtmStatus(RtmStatus.Disconnected);
  };

  private handleReconnecting = () => {
    this.setRtmStatus(RtmStatus.Connecting);
  };

  private handleReconnected = () => {
    this.setRtmStatus(RtmStatus.Connected);
  };

  private setRtmStatus = (status: RtmStatus): void => {
    this.rtmStatus = status;
  };
}
