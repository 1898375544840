import {
  EuiButtonEmpty,
  EuiCodeBlock,
  EuiEmptyPrompt,
  EuiIconTip,
  EuiProgress,
  EuiSpacer,
  EuiText,
} from '@elastic/eui';
import { Media } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { Fragment, useState } from 'react';
import { MediaProcessedStatuses, mediaUtils } from '../../utils';
import { ConfirmTextInput, Flex } from '../common';
import {
  AudioPreview,
  DocumentPreview,
  ImagePreview,
  MediaLink,
  VideoPreview,
} from './previews';
import { useStores } from '../../hooks';

export interface MediaPreviewProps {
  media: Media;
  onSetDisplayName?: (displayName: string) => void;
  displayMediaDetailsLink?: boolean;
}

export const MediaPreview = observer((props: MediaPreviewProps) => {
  const { media, onSetDisplayName, displayMediaDetailsLink } = props;
  const [editing, setEditing] = useState(false);

  const { mediaStore } = useStores();

  const mediaStatus = mediaUtils.getProcessingStatus(media);

  if (mediaStatus === MediaProcessedStatuses.Processing) {
    const mediaProgress = mediaStore.getProcessingMedia(media.id);

    return (
      <EuiEmptyPrompt
        iconType="clock"
        iconColor="warning"
        title={<h4>Media Processing</h4>}
        titleSize="xxs"
        paddingSize="none"
        body={
          <Flex column>
            <EuiText size="s">
              This media has not finished being processed. This is important to
              preserve the privacy of the uploader. It will be available upon
              completion.
            </EuiText>
            {Boolean(mediaProgress) ? (
              <Fragment>
                <Flex justify="center" gap={8}>
                  {mediaProgress?.status ? (
                    <EuiText size="xs" color="default">
                      {mediaProgress.status}
                    </EuiText>
                  ) : null}{' '}
                  {mediaProgress?.progress ? (
                    <EuiText size="xs" color="ghost">
                      {mediaProgress.progress}%
                    </EuiText>
                  ) : null}
                </Flex>
                <EuiProgress
                  value={mediaProgress?.progress ?? 0}
                  max={100}
                  size="xs"
                />
              </Fragment>
            ) : null}
          </Flex>
        }
      />
    );
  }

  if (mediaStatus === MediaProcessedStatuses.Failed) {
    return (
      <EuiEmptyPrompt
        iconType="cloudStormy"
        iconColor="danger"
        title={
          <Flex gap={8}>
            <h4>Media Processing Failed</h4>
            <EuiIconTip
              type="magnifyWithExclamation"
              color="danger"
              aria-label="Error Info"
              size="m"
              content={
                <EuiCodeBlock language="bash" fontSize="m" paddingSize="m">
                  {media.mediaProcessInfo[0].failureReason}
                </EuiCodeBlock>
              }
            />
          </Flex>
        }
        titleSize="xxs"
        paddingSize="none"
        body={<EuiText size="s">This media was unable to be processed</EuiText>}
      />
    );
  }

  let previewElement = (
    <MediaLink
      media={media}
      displayMediaDetailsLink={displayMediaDetailsLink}
    />
  );

  if (mediaUtils.isVideo(media)) {
    previewElement = (
      <VideoPreview
        media={media}
        displayMediaDetailsLink={displayMediaDetailsLink}
      />
    );
  }

  if (mediaUtils.isAudio(media)) {
    previewElement = (
      <AudioPreview
        media={media}
        displayMediaDetailsLink={displayMediaDetailsLink}
      />
    );
  }

  if (mediaUtils.isDocument(media)) {
    previewElement = (
      <DocumentPreview
        media={media}
        displayMediaDetailsLink={displayMediaDetailsLink}
      />
    );
  }

  if (mediaUtils.isImage(media)) {
    previewElement = (
      <ImagePreview
        media={media}
        displayMediaDetailsLink={displayMediaDetailsLink}
      />
    );
  }

  let editElement = null;
  if (onSetDisplayName) {
    if (!editing) {
      editElement = (
        <Flex justify="flex-end">
          <EuiButtonEmpty size="s" onClick={() => setEditing(true)}>
            Edit name
          </EuiButtonEmpty>
        </Flex>
      );
    } else {
      editElement = (
        <ConfirmTextInput
          placeholder="Media name"
          initialText={media.displayName || media.name || media.objectKey}
          onSave={(displayname) => {
            onSetDisplayName(displayname);
            setEditing(false);
          }}
          onCancel={() => setEditing(false)}
        />
      );
    }
  }

  return (
    <Flex column>
      {previewElement}
      {editElement ? <EuiSpacer size="s" /> : null}
      {editElement}
    </Flex>
  );
});
