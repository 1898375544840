import {
  EuiBadge,
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
} from '@elastic/eui';
import { CopDepartmentNotability } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { camelToSentence } from '../../utils';
import { Flex } from '../common';

export interface CopNotabilityListProps {
  notabilities?: CopDepartmentNotability[] | null;
  onChange?: (notabilities: CopDepartmentNotability[] | null) => void;
}

export const CopNotabilityList = observer((props: CopNotabilityListProps) => {
  const { notabilities: selected, onChange } = props;

  const mapValueToOption = (value: CopDepartmentNotability) => {
    return {
      label: camelToSentence(value),
      key: value,
      value: value,
    };
  };

  const notabilityOptions: EuiComboBoxOptionOption<CopDepartmentNotability>[] =
    Object.values(CopDepartmentNotability).map((type) =>
      mapValueToOption(type),
    );

  const selectedNotabilityOptions = selected
    ? selected.map((val) => mapValueToOption(val))
    : [];

  if (onChange) {
    return (
      <EuiComboBox
        fullWidth
        aria-label="Select ways in which this cop is notable, if any"
        options={notabilityOptions}
        selectedOptions={selectedNotabilityOptions}
        onChange={(values) => {
          const updatedNotabilities = values
            ? values.filter((val) => val.value).map((val) => val.value!)
            : null;

          onChange(updatedNotabilities);
        }}
        isClearable={true}
      />
    );
  }

  if (!selected?.length) {
    return null;
  }

  return (
    <Flex gap={8}>
      <EuiText size="relative" color="subdued">
        Notable:
      </EuiText>
      <EuiFlexGroup wrap responsive={false} gutterSize="xs">
        {selected.map((notability) => (
          <EuiFlexItem grow={false} key={notability}>
            <EuiBadge>{camelToSentence(notability)}</EuiBadge>
          </EuiFlexItem>
        ))}
      </EuiFlexGroup>
    </Flex>
  );
});
