import {
  EuiInputPopover,
  EuiSelectable,
  EuiSelectableOption,
  EuiSelectableOptionsListProps,
} from '@elastic/eui';
import { observer } from 'mobx-react';
import { useState } from 'react';

export interface SelectableInputPopoverProps {
  options: EuiSelectableOption[];
  onChange: (options: EuiSelectableOption[]) => void;
  /**
   * Placeholder for the search input. Will also be used as the aria-label, so
   * make sure that it is instructive.
   */
  placeholder: string;
  /**
   * Optional props to be passed along to the optins list, see #EuiSelectableOptionsList.
   */
  listProps?: EuiSelectableOptionsListProps;
}

export const SelectableInputPopover = observer(
  (props: SelectableInputPopoverProps) => {
    const { options, onChange, placeholder, listProps } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    return (
      <EuiSelectable
        aria-label={placeholder}
        options={options}
        onChange={(newOptions) => {
          onChange(newOptions);
          setSearchValue('');
        }}
        searchable
        searchProps={{
          placeholder,
          value: searchValue,
          onChange: (value) => setSearchValue(value),
          onKeyDown: (event) => {
            if (event.key === 'Tab') return setIsOpen(false);
            if (event.key !== 'Escape') return setIsOpen(true);
          },
          onClick: () => setIsOpen(true),
          onFocus: () => setIsOpen(true),
        }}
        listProps={listProps}
      >
        {(list, search) => (
          <EuiInputPopover
            closePopover={() => setIsOpen(false)}
            disableFocusTrap
            closeOnScroll
            isOpen={isOpen}
            input={search!}
            panelPaddingSize="none"
            fullWidth
          >
            {list}
          </EuiInputPopover>
        )}
      </EuiSelectable>
    );
  },
);
