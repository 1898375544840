import { EditStatus, MediaEdit } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks';
import { dateUtils, formatEditCitation, logger } from '../../utils';
import { EditAction, EditActions } from '../moderate';
import { EuiPanel, EuiText, EuiHorizontalRule } from '@elastic/eui';
import { Flex, ResourceNotFound, TextDiff } from '../common';
import { MediaEditCopDiff } from './media-edit-cop-diff';
import { MediaEditIncidentDiff } from './media-edit-incident-diff';

export interface MediaEditItemProps {
  edit: MediaEdit;
}

export const MediaEditItem = observer((props: MediaEditItemProps) => {
  const { mediaEditStore, toastStore, mediaStore, authStore } = useStores();
  const { edit } = props;

  const disableApproveReason =
    !authStore.isAdmin && authStore.user?.id === edit.createdBy
      ? 'Non-Admin users cannot approve edits they created'
      : undefined;

  const media = mediaStore.data.orderedItems.find((m) => m.id === edit.mediaId);
  if (!media) {
    return <ResourceNotFound text="Unable to load the specified Media" />;
  }

  const handleAction = (action: EditAction) => {
    try {
      switch (action) {
        case EditAction.Approve:
          return mediaEditStore.updateMediaEditStatus(
            edit.id,
            EditStatus.Approved,
          );
        case EditAction.Deny:
          return mediaEditStore.updateMediaEditStatus(
            edit.id,
            EditStatus.Denied,
          );
      }
    } catch (error) {
      logger.error('failed to handle media edit action', error);
      toastStore.showApiError(error);
    }
  };

  return (
    <EuiPanel hasShadow={false} color="subdued">
      <Flex column gap={8}>
        {media.displayName !== edit.editDto.displayName ? (
          <TextDiff
            beforeText={media.displayName}
            afterText={edit.editDto.displayName}
            label={'Display Name'}
          />
        ) : null}
        <MediaEditCopDiff
          mediaRelationDtos={edit.editDto.upsertMediaRelationDtos ?? []}
        />
        <MediaEditIncidentDiff
          mediaRelationDtos={edit.editDto.upsertMediaRelationDtos ?? []}
        />
      </Flex>
      <EuiHorizontalRule margin="s" />
      <Flex justify="space-between" align="center">
        <EuiText
          color="subdued"
          size="s"
          title={dateUtils.format(edit.createdAt)}
        >
          {formatEditCitation(edit)}
        </EuiText>
        <EditActions
          onAction={handleAction}
          disableApproveReason={disableApproveReason}
          disableOverwrite
        />
      </Flex>
    </EuiPanel>
  );
});
