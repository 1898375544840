export const IS_PRODUCTION = process.env.REACT_APP_IS_PRODUCTION ?? false;

export const API_PATH = IS_PRODUCTION
  ? `https://${document.location.host}/api`
  : 'http://localhost:5207';

export const RTM_API_PATH = `${API_PATH}/rtm`;

export const MASTODON_LINK = process.env.REACT_APP_MASTODON_LINK;

export const CAPTCHA_SITEKEY =
  process.env.REACT_APP_CAPTCHA_SITEKEY ??
  '10000000-ffff-ffff-ffff-000000000001';

export const GOOGLE_MAPS_API_KEY =
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '';
