import {
  CopResponseDto,
  IncidentInformationDto,
  IncidentResponseDto,
  IncidentTagDto,
  LocationDto,
  Media,
  MediaAssociationDto,
  PersonIncidentAssociationDto,
  UpsertIncidentDto,
} from '@unfrl/copdb-sdk';
import { isEqual, sortBy, orderBy } from 'lodash';
import { makeAutoObservable } from 'mobx';
import { CopDetailItemProps } from '../components';
import { RootStore } from '../stores/root.store';
import { mapIncidentToUpsertDto, mapResponsesToDetailItems } from '../utils';

export class EditIncidentViewModel implements UpsertIncidentDto {
  private readonly _root: RootStore;

  private readonly _original: UpsertIncidentDto;

  public summary?: string | null;

  public description?: string | null;

  public dateOccurred?: Date | null;

  public location?: LocationDto;

  public personIncidentAssociations: PersonIncidentAssociationDto[] = [];

  public mediaAssociations: MediaAssociationDto[] = [];

  public preliminary?: boolean;

  public tags?: IncidentTagDto[];

  public get mediaIds(): string[] {
    return this.mediaAssociations.map((association) => association.mediaId);
  }

  public get medias(): Media[] {
    const unsortedMedias = this._root.mediaStore.data.orderedItems.filter(
      (media) => this.mediaIds.includes(media.id),
    );

    return orderBy(unsortedMedias, 'createdAt', 'desc');
  }

  public get associatedCops(): CopResponseDto[] {
    const result: CopResponseDto[] = [];
    this.personIncidentAssociations.forEach((pia) => {
      const match = this._root.copStore.data.orderedItems.find(
        (cop) => cop.personId === pia.personId,
      );
      if (match) {
        result.push(match);
      }
    });

    return result;
  }

  public get copDetailItems(): CopDetailItemProps[] {
    return mapResponsesToDetailItems(
      this.associatedCops,
      this.personIncidentAssociations,
    );
  }

  public get canSave(): boolean {
    const {
      location: originalLocation,
      tags: originalTags,
      ...original
    } = this._original;
    const { location: dtoLocation, tags: dtoTags, ...dto } = this.asDto;

    if (dto.mediaAssociations && dto.mediaAssociations.length > -1) {
      /*
      If there are any mediaAssociations they must all either be associated with the 
      incident or at least 1 cop.
      */
      const hasInvalidMediaAssociation = dto.mediaAssociations.some(
        (association) =>
          !association.includesIncident &&
          association.personIds &&
          association.personIds.length === 0,
      );
      if (hasInvalidMediaAssociation) {
        return false;
      }
    }

    return !isEqual(
      JSON.parse(
        JSON.stringify({
          tags: sortBy(originalTags, (tag) => tag.name),
          location: originalLocation?.coordinates,
          ...original,
        }),
      ),
      JSON.parse(
        JSON.stringify({
          tags: sortBy(dtoTags, (tag) => tag.name),
          location: dtoLocation?.coordinates,
          ...dto,
        }),
      ),
    );
  }

  public get asDto(): UpsertIncidentDto {
    return mapIncidentToUpsertDto(this);
  }

  /**
   * Creates a new observable view model for editing an existing incident.
   * @param rootStore - Root domain store
   * @param incident - The current version of the incident
   * @param incidentDto - Optionally provide the initial state of the edit, otherwise the original incident will be used
   */
  public constructor(
    rootStore: RootStore,
    incident: IncidentResponseDto,
    incidentDto?: UpsertIncidentDto,
  ) {
    this._root = rootStore;
    this._original = mapIncidentToUpsertDto(incident);
    this.update(incidentDto ?? this._original);

    // TODO: FIXME! cops need to be set in the store b/c they're not guaranteed to be there when loading an incident
    incident.cops?.forEach((cop) => {
      this._root.copStore.data.setItem(cop);
    });

    makeAutoObservable(this);
  }

  public update = (partial: Partial<UpsertIncidentDto>): void => {
    Object.assign(this, partial);
  };

  public associateCop = (cop: CopResponseDto): void => {
    if (
      this.personIncidentAssociations.find(
        (pia) => pia.personId === cop.personId,
      )
    ) {
      return;
    }

    this.personIncidentAssociations.push({
      personId: cop.personId,
      incidentInformation: {},
    });

    // TODO: FIXME! same reason as cops being set in constructor
    this._root.copStore.data.setItem(cop);
  };

  public unassociateCop = (index: number): void => {
    const targetPerson = this.personIncidentAssociations.at(index);
    if (!targetPerson) {
      return;
    }

    this.personIncidentAssociations.splice(index, 1);
    if (targetPerson.personId) {
      this.mediaAssociations.forEach((ma) => {
        if (!ma.personIds) {
          return;
        }
        const targetAssociationIndex = ma.personIds.indexOf(
          targetPerson.personId!,
        );
        if (targetAssociationIndex > -1) {
          ma.personIds.splice(targetAssociationIndex, 1);
        }
      });
    }
  };

  public updateAssociatedCop = (
    index: number,
    incidentInfo: IncidentInformationDto,
  ): void => {
    if (this.personIncidentAssociations[index]) {
      this.personIncidentAssociations[index].incidentInformation = incidentInfo;
    }
  };

  public associateMedias = (mediaIds: string[]): void => {
    this.mediaAssociations.push(
      ...mediaIds.map((mediaId) => ({
        mediaId,
        includesIncident: true,
        personIds: [],
      })),
    );
  };

  public unassociateMedia = (targetMediaId: string): void => {
    const index = this.mediaAssociations.findIndex(
      (ma) => ma.mediaId === targetMediaId,
    );
    if (index > -1) {
      this.mediaAssociations.splice(index, 1);
    }
  };

  public setIncludesIncident = (
    mediaId: string,
    includesIncident: boolean,
  ): void => {
    const association = this.mediaAssociations.find(
      (ma) => ma.mediaId === mediaId,
    );
    if (association) {
      association.includesIncident = includesIncident;
    }
  };

  public associatePersonWithMedia = (
    personId: string,
    mediaId: string,
  ): void => {
    const association = this.mediaAssociations.find(
      (ma) => ma.mediaId === mediaId,
    );
    if (!association) {
      return;
    }

    if (!association.personIds?.length) {
      association.personIds = [personId];
      return;
    }

    if (!association.personIds?.find((id) => id === personId)) {
      association.personIds.push(personId);
    }
  };

  public unassociatePersonWithMedia = (
    personId: string,
    mediaId: string,
  ): void => {
    const association = this.mediaAssociations.find(
      (ma) => ma.mediaId === mediaId,
    );
    if (!association?.personIds?.length) {
      return;
    }

    const index = association.personIds.findIndex((id) => id === personId);
    if (index > -1) {
      association.personIds.splice(index, 1);
    }
  };
}
