import { makeAutoObservable } from 'mobx';

export class DataStore<T extends { id: string }> {
  public items: Map<string, T> = new Map();

  public get orderedItems(): T[] {
    return Array.from(this.items.values());
  }

  public activeId?: string;

  public get activeItem(): T | undefined {
    return this.activeId ? this.items.get(this.activeId) : undefined;
  }

  public constructor() {
    makeAutoObservable(this);
  }

  //#region Actions

  public setActive = (id: string): void => {
    this.activeId = id;
  };

  public clearActive = (): void => {
    this.activeId = undefined;
  };

  public getItem = (id: string): T | undefined => {
    return this.items.get(id);
  };

  public setItem = (item: T): void => {
    this.items.set(item.id, item);
  };

  public updateItem = (id: string, partial: Partial<T>): void => {
    const item = this.getItem(id);
    if (!item) {
      return;
    }

    this.setItem({ ...item, ...partial });
  };

  public deleteItem = (id: string): boolean => {
    return this.items.delete(id);
  };

  public clearItems = (): void => {
    this.items.clear();
  };

  public setList = (items: T[]): void => {
    items.forEach(this.setItem);
  };

  //#endregion

  //#region Utils

  public itemExists = (id: string): boolean => {
    return this.items.has(id);
  };

  //#endregion
}
