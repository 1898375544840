import { Media } from '@unfrl/copdb-sdk';

export enum MediaProcessedStatuses {
  Processing,
  Processed,
  Failed,
}

// content types
const VIDEO_TYPE = 'video/';
const PDF_TYPE = 'application/pdf';
const TEXT_TYPE = 'text/';
const IMAGE_TYPE = 'image/';
const AUDIO_TYPE = 'audio/';

const isVideo = (media: Media): boolean => {
  return media.contentType.indexOf(VIDEO_TYPE) === 0;
};

const isAudio = (media: Media): boolean => {
  return media.contentType.indexOf(AUDIO_TYPE) === 0;
};

const isPdf = (media: Media): boolean => {
  return media.contentType === PDF_TYPE;
};

const isText = (media: Media): boolean => {
  return media.contentType.indexOf(TEXT_TYPE) === 0;
};

const isDocument = (media: Media): boolean => {
  return isPdf(media) || isText(media);
};

const isImage = (media: Media): boolean => {
  return media.contentType.indexOf(IMAGE_TYPE) === 0;
};

const getProcessingStatus = (media: Media): MediaProcessedStatuses => {
  const latestInfo = media.mediaProcessInfo[0];
  if (latestInfo && !!latestInfo.completedAt) {
    return latestInfo.failureReason
      ? MediaProcessedStatuses.Failed
      : MediaProcessedStatuses.Processed;
  }

  return MediaProcessedStatuses.Processing;
};

const isOther = (media: Media): boolean => {
  return (
    !isVideo(media) &&
    !isAudio(media) &&
    !isPdf(media) &&
    !isText(media) &&
    !isDocument(media) &&
    !isImage(media)
  );
};

export const mediaUtils = {
  isVideo,
  isAudio,
  isPdf,
  isText,
  isDocument,
  isImage,
  isOther,
  getProcessingStatus,
};
