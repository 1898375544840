import {
  EuiAccordion,
  EuiMarkdownFormat,
  EuiPanel,
  EuiSpacer,
} from '@elastic/eui';
import {
  CopDepartmentResponseDto,
  CopResponseDto,
  Media,
  PersonMedia,
} from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { Fragment } from 'react';
import { camelToSentence } from '../../utils';
import { Flex, LabelOrMissing, MissingDataText } from '../common';
import { MediaPreviewList } from '../media';
import { CopDepartmentList } from './cop-department-list';

export interface CopProfileDetailsProps {
  copResponse: CopResponseDto;
  personMedias: PersonMedia[];
  copDepartments: CopDepartmentResponseDto[];
}

export const CopProfileDetails = observer((props: CopProfileDetailsProps) => {
  const { personMedias, copDepartments, copResponse } = props;

  const medias: Media[] = personMedias.map((personMedia) => ({
    ...(personMedia.media as Media),
  }));

  const demographicData: { label: string; value: string | undefined }[] = [
    {
      label: 'Hair color',
      value: copResponse.person.hairColor
        ? camelToSentence(copResponse.person.hairColor.toString())
        : undefined,
    },
    {
      label: 'Eye color',
      value: copResponse.person.eyeColor
        ? camelToSentence(copResponse.person.eyeColor.toString())
        : undefined,
    },
    {
      label: 'Race',
      value: copResponse.person.race
        ? camelToSentence(copResponse.person.race.toString())
        : undefined,
    },
    {
      label: 'Gender',
      value: copResponse.person.gender
        ? camelToSentence(copResponse.person.gender.toString())
        : undefined,
    },
    {
      label: 'Visible tattoos',
      value:
        copResponse.person.visibleTattoos === true
          ? 'Yes'
          : copResponse.person.visibleTattoos === false
          ? 'No'
          : undefined,
    },
  ];

  return (
    <Fragment>
      <EuiPanel hasBorder paddingSize="s">
        <EuiAccordion
          id="cop-description-accordion"
          buttonContent="Description"
          initialIsOpen={true}
        >
          <EuiPanel hasShadow={false} paddingSize="s">
            {copResponse.description ? (
              <EuiMarkdownFormat>{copResponse.description}</EuiMarkdownFormat>
            ) : (
              <MissingDataText text="No description recorded." />
            )}
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
      <EuiSpacer size="m" />

      <EuiPanel hasBorder paddingSize="s">
        <EuiAccordion
          id="cop-departments-accordion"
          buttonContent="Departments"
          initialIsOpen={true}
        >
          <EuiPanel hasShadow={false} paddingSize="s">
            <CopDepartmentList copDepartments={copDepartments} />
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
      <EuiSpacer size="m" />

      <EuiPanel hasBorder paddingSize="s">
        <EuiAccordion
          id="cop-demographic-accordion"
          buttonContent="Demographic information"
          initialIsOpen={false}
        >
          <EuiPanel hasShadow={false} paddingSize="s">
            <Flex wrap column gap={8}>
              {demographicData.map((val) => (
                <LabelOrMissing
                  key={val.label}
                  size="relative"
                  name={val.label}
                  value={val.value}
                />
              ))}
            </Flex>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
      <EuiSpacer size="m" />

      <EuiPanel hasBorder paddingSize="s">
        <EuiAccordion
          id="cop-media-accordion"
          buttonContent="Media"
          initialIsOpen={true}
        >
          <EuiPanel hasShadow={false} paddingSize="s">
            <MediaPreviewList
              mediaList={medias}
              displayMediaDetailsLink={true}
            />
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
    </Fragment>
  );
});
