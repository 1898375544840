import { EuiText, useEuiTextDiff } from '@elastic/eui';
import { Fragment } from 'react';
import { Flex } from './flex';

export interface TextDiffProps {
  beforeText?: string | null;
  afterText?: string | null;
  label?: string;
  hideOnNoDiff?: boolean;
}

export const TextDiff = (props: TextDiffProps) => {
  const { beforeText, afterText, label, hideOnNoDiff } = props;

  const [rendered] = useEuiTextDiff({
    beforeText: beforeText || '',
    afterText: afterText || '',
  });

  if (hideOnNoDiff && beforeText === afterText) {
    return null;
  }

  if (!label) {
    return <Fragment>{rendered}</Fragment>;
  }

  return (
    <Flex wrap>
      <EuiText color="subdued">{label}:</EuiText>
      <div style={{ marginRight: 8 }} />
      <EuiText>{rendered}</EuiText>
    </Flex>
  );
};
