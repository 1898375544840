import { EuiBadge, EuiIcon } from '@elastic/eui';
import { Flex } from './flex';
import { MissingDataText } from './missing-data-text';

export const TagDisplay = (props: { tags: string[] | undefined }) => {
  const { tags } = props;

  const items =
    tags && tags?.length > 0 ? (
      tags.map((tag) => (
        <Flex key={tag}>
          <EuiBadge>{tag}</EuiBadge>
        </Flex>
      ))
    ) : (
      <MissingDataText text="No tags recorded." />
    );

  return (
    <Flex>
      <EuiIcon
        title="Tags"
        type="tag"
        style={{ marginRight: 4, marginTop: 2 }}
      />
      <Flex align="center" wrap gap={4}>
        {items}
      </Flex>
    </Flex>
  );
};
