import { EuiCard, EuiIcon, EuiLink, EuiSpacer, EuiTitle } from '@elastic/eui';
import { IncidentResponseDto } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { Fragment } from 'react';
import { DateOccurred } from '../date-occurred';
import { LocationPlaceLink } from '../location';
import { useStores } from '../../hooks';
import { Link } from 'react-router-dom';
import { loginPath, signupPath } from '../../utils';
import { TagDisplay } from '../common';

export interface IncidentDetailsHeaderProps {
  incidentDto: IncidentResponseDto;
}

export const IncidentDetailsHeader = observer(
  ({ incidentDto }: { incidentDto: IncidentResponseDto }) => {
    const { authStore, featuresStore } = useStores();

    const unauthenticatedPrompt = (
      <span>
        <Link to={loginPath()}>
          <EuiLink>logging in</EuiLink>
        </Link>{' '}
        {featuresStore.features.signUpEnabled ? (
          <Fragment>
            or{' '}
            <Link to={signupPath()}>
              <EuiLink>creating an account</EuiLink>
            </Link>
          </Fragment>
        ) : null}
      </span>
    );

    return (
      <Fragment>
        <EuiTitle>
          <h1>{incidentDto.summary ?? 'Untitled summary'}</h1>
        </EuiTitle>
        <EuiSpacer size="m" />
        <DateOccurred withIcon date={incidentDto.dateOccurred} />
        <EuiSpacer size="s" />
        <LocationPlaceLink
          withIcon
          size="relative"
          location={incidentDto.location}
        />
        <EuiSpacer size="s" />
        <TagDisplay tags={incidentDto.tags?.map((tag) => tag.name)} />

        {incidentDto.preliminary ? (
          <Fragment>
            <EuiSpacer size="s" />
            <EuiCard
              icon={<EuiIcon size="l" color="warning" type="alert" />}
              layout="horizontal"
              title="Incomplete"
              titleSize="xs"
              description={
                <p>
                  This incident has been marked "preliminary" and needs more
                  information. You can help by{' '}
                  {authStore.authenticated
                    ? "hitting 'Suggest edit'"
                    : unauthenticatedPrompt}
                </p>
              }
            />
          </Fragment>
        ) : null}
      </Fragment>
    );
  },
);
