import { EuiFormRow, EuiSpacer } from '@elastic/eui';
import { CopDepartmentDto, DepartmentResponseDto } from '@unfrl/copdb-sdk';
import { uniqBy } from 'lodash';
import { observer } from 'mobx-react';
import { Fragment, useEffect, useState } from 'react';
import { useStores } from '../../hooks';
import { validateCopDepartmentDtos } from '../../utils';
import { DepartmentSearch } from '../department';
import { CopDepartmentItem } from './cop-department-item';

export interface CopDepartmentsInputProps {
  copDepartmentDtos: CopDepartmentDto[];
  onChange: (dtos: CopDepartmentDto[]) => void;
}

export const CopDepartmentsInput = observer(
  (props: CopDepartmentsInputProps) => {
    const { copDepartmentDtos, onChange } = props;
    const { departmentStore } = useStores();

    const [departmentErrors, setDepartmentErrors] = useState<
      Map<string, string[]>
    >(new Map());

    const validateDepartmentDates = () => {
      const errors = validateCopDepartmentDtos(copDepartmentDtos);
      setDepartmentErrors(errors);
    };

    useEffect(() => {
      if (copDepartmentDtos?.length) {
        departmentStore.fetchDepartments(
          copDepartmentDtos.map((dto) => dto.departmentId),
        );
      }
      validateDepartmentDates();
    }, [copDepartmentDtos]);

    const handleSelectDepartment = (department: DepartmentResponseDto) => {
      const { id } = department;
      const existing = copDepartmentDtos ?? [];
      const updated = uniqBy(
        [...existing, { departmentId: id }],
        (d) => d.departmentId,
      );
      onChange(updated);
    };

    const handleUpdateDepartment = (copDepartmentDto: CopDepartmentDto) => {
      const toUpdate = [...(copDepartmentDtos ?? [])];
      const index = toUpdate.findIndex(
        (d) => d.departmentId === copDepartmentDto.departmentId,
      );
      if (index > -1) {
        toUpdate[index] = copDepartmentDto;
        onChange(toUpdate);
      }
    };

    const handleRemoveDepartment = (id: string) => {
      const toUpdate = [...copDepartmentDtos];
      const index = toUpdate.findIndex((d) => d.departmentId === id);
      if (index > -1) {
        toUpdate.splice(index, 1);
        onChange(toUpdate);
      }
    };

    const renderDepartmentItems = () => {
      if (!copDepartmentDtos.length) {
        return null;
      }

      return (
        <Fragment>
          <EuiSpacer size="m" />
          {copDepartmentDtos.map((copDepartmentDto) => (
            <EuiFormRow
              fullWidth
              key={copDepartmentDto.departmentId}
              isInvalid={departmentErrors.has(copDepartmentDto.departmentId)}
              error={departmentErrors.get(copDepartmentDto.departmentId)}
            >
              <CopDepartmentItem
                departmentName={
                  departmentStore.data.getItem(copDepartmentDto.departmentId)
                    ?.name ?? ''
                }
                copDepartmentDto={copDepartmentDto}
                onUpdate={handleUpdateDepartment}
                onRemove={handleRemoveDepartment}
                isInvalid={
                  !!departmentErrors.get(copDepartmentDto.departmentId)?.length
                }
              />
            </EuiFormRow>
          ))}
        </Fragment>
      );
    };

    return (
      <Fragment>
        <EuiFormRow
          fullWidth
          label="Departments"
          helpText="Search for any departments this cop is, or has been, a part of. Please specify their start and end dates if you know them."
        >
          <DepartmentSearch onSelected={handleSelectDepartment} />
        </EuiFormRow>
        {renderDepartmentItems()}
      </Fragment>
    );
  },
);
