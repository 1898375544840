import { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';

export interface DocumentHeadProps {
  title?: string;
  description?: string;
  jsonld?: string;
}

export const DocumentHead = (props: DocumentHeadProps) => {
  const { title, description, jsonld } = props;
  const docTitle = title ? `${title} | ` : '';
  const fullTitle = `${docTitle}Cop DB`;
  const desc = description ?? 'Cop DB';

  return (
    <Fragment>
      {/* @ts-ignore */}
      <Helmet>
        <title>{fullTitle}</title>
        <link rel="shortcut icon" href="favicon.ico" />
        <meta name="description" content={desc} />
        <meta name="twitter:title" content={fullTitle} />
        <meta name="twitter:description" content={desc} />
        <meta property="og:title" content={fullTitle} />
        <meta property="og:description" content={desc} />
        {jsonld ? (
          <script type="application/ld+json">{`${jsonld}`}</script>
        ) : null}
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.9.3/dist/leaflet.css"
          integrity="sha256-kLaT2GOSpHechhsozzB+flnD+zUyjE2LlfWPgU04xyI="
          crossOrigin=""
        />
      </Helmet>
    </Fragment>
  );
};
