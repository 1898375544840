import { observer } from 'mobx-react';
import { useStores } from '../../hooks';
import { Flex } from '../common';
import { IncidentEditItem } from './incident-edit-item';

export interface IncidentEditListProps {
  incidentId: string;
}

export const IncidentEditList = observer((props: IncidentEditListProps) => {
  const { incidentId } = props;
  const { incidentEditStore } = useStores();

  const mapping = incidentEditStore.getEditMapping(incidentId);
  if (!mapping) {
    return null;
  }

  const { incident, edits } = mapping;

  return (
    <Flex column gap={8} style={{ width: '100%' }}>
      {edits.map((edit) => (
        <IncidentEditItem key={edit.id} incident={incident} edit={edit} />
      ))}
    </Flex>
  );
});
