import {
  EuiButtonIcon,
  EuiPanel,
  EuiSelectableOption,
  EuiText,
} from '@elastic/eui';
import { MediaAssociationDto } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { EditReport } from '../../models';
import {
  formatNameFromResponseDto,
  formatNameFromUpsertDto,
  getProfilePhotoFromResponse,
  getProfilePhotoFromUpsert,
} from '../../utils';
import { Flex, SelectableInputPopover } from '../common';
import { CopAvatar } from '../cop/cop-avatar';

export interface MediaCopAssociationListProps {
  mediaAssociation: MediaAssociationDto;
  report: EditReport;
}

export const MediaCopAssociationList = observer(
  (props: MediaCopAssociationListProps) => {
    const { mediaAssociation, report } = props;
    const { mediaId } = mediaAssociation;

    const existingOptions: EuiSelectableOption[] = report.existingCops.map(
      (existingCop) => {
        const label = formatNameFromResponseDto(existingCop);
        const photoUrl = getProfilePhotoFromResponse(existingCop);

        return {
          label,
          prepend: (
            <CopAvatar
              size={30}
              name={label}
              photoUrl={photoUrl}
              disableFullScreen
            />
          ),
          checked: mediaAssociation.personIds?.includes(existingCop.personId)
            ? 'on'
            : undefined,
          data: {
            existing: true,
            personId: existingCop.personId,
            photoUrl,
          },
        };
      },
    );

    const newOptions: EuiSelectableOption[] =
      report.newCops?.map((newCop, index) => {
        const label = formatNameFromUpsertDto(newCop);
        const photoUrl = getProfilePhotoFromUpsert(newCop);

        return {
          label,
          prepend: (
            <CopAvatar
              size={30}
              name={label}
              photoUrl={photoUrl}
              disableFullScreen
            />
          ),
          checked: !!newCop.personMediaDtos?.find(
            (pmd) => pmd.mediaId === mediaId,
          )
            ? 'on'
            : undefined,
          data: {
            existing: false,
            newCopIndex: index,
            photoUrl,
          },
        };
      }) ?? [];

    const allCopOptions = [...existingOptions, ...newOptions];

    const handleSelectedOptionsChange = (newOptions: EuiSelectableOption[]) => {
      for (const { checked, data } of newOptions) {
        if (!data) {
          continue;
        }

        const isChecked = checked === 'on';
        const isExisting = !!data.existing;

        if (isExisting) {
          if (isChecked) {
            report.associateExistingCopWithMedia(mediaId, data.personId);
          } else {
            report.unassociateExistingCopWithMedia(mediaId, data.personId);
          }
        } else {
          if (isChecked) {
            report.associateNewCopWithMedia(mediaId, data.newCopIndex);
          } else {
            report.unassociateNewCopWithMedia(mediaId, data.newCopIndex);
          }
        }
      }
    };

    return (
      <Flex column gap={8}>
        <SelectableInputPopover
          options={allCopOptions}
          onChange={handleSelectedOptionsChange}
          placeholder="Search cops to associate"
          listProps={{ rowHeight: 50 }}
        />
        {allCopOptions
          .filter((option) => option.checked === 'on')
          .map((cop) => (
            <EuiPanel key={cop.label} hasBorder paddingSize="s">
              <Flex align="center" justify="space-between">
                <Flex gap={16} align="center">
                  <CopAvatar
                    size={48}
                    name={cop.label}
                    photoUrl={cop.data?.photoUrl}
                    disableFullScreen
                  />
                  <EuiText size="m">{cop.label}</EuiText>
                </Flex>
                <EuiButtonIcon
                  color="danger"
                  iconType="trash"
                  aria-label="Remove cop association"
                  title="Remove cop association"
                  size="s"
                  onClick={() => {
                    if (!cop.data) {
                      return;
                    }

                    if (!!cop.data.existing) {
                      report.unassociateExistingCopWithMedia(
                        mediaId,
                        cop.data.personId,
                      );
                    } else {
                      report.unassociateNewCopWithMedia(
                        mediaId,
                        cop.data.newCopIndex,
                      );
                    }
                  }}
                />
              </Flex>
            </EuiPanel>
          ))}
      </Flex>
    );
  },
);
