import { EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui';
import { IncidentInformationDto } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { formatName } from '../../utils';
import { CopAvatar } from './cop-avatar';

export interface CopDetailItemProps {
  id?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  position?: string | null;
  badgeNumber?: string | null;
  incidentInformation?: IncidentInformationDto | null;
  photoUrls?: string[] | null;
}

export const CopDetailItem = observer((props: CopDetailItemProps) => {
  const { firstName, middleName, lastName, position, badgeNumber, photoUrls } =
    props;

  const name = formatName(firstName, middleName, lastName);
  const positionLabel = `Position: ${position || 'N/A'}`;
  const badgeLabel = `Badge: ${badgeNumber || 'N/A'}`;

  return (
    <EuiFlexGroup responsive={false} gutterSize="m" alignItems="center">
      <EuiFlexItem grow={2}>
        <CopAvatar
          name={name}
          photoUrl={photoUrls?.length ? photoUrls[0] : undefined}
          size={48}
          disableFullScreen
        />
      </EuiFlexItem>
      <EuiFlexItem grow={8}>
        <div>
          <EuiText size="relative">{name}</EuiText>
          <EuiText size="xs" color="subdued">
            {[positionLabel, badgeLabel].join(' - ')}
          </EuiText>
        </div>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
});
