import {
  CriteriaWithPagination,
  EuiButton,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
} from '@elastic/eui';
import {
  DepartmentDto,
  DepartmentFilterResponse,
  DepartmentResponseDto,
  DepartmentSortField,
  SortDirection,
} from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { Fragment, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import { apiClient } from '../../api';
import { useStores } from '../../hooks';
import { logger } from '../../utils';
import AdminRequired from '../auth/admin-required';
import { DepartmentForm } from './department-form';
import { DepartmentTable } from './department-table';

const sortDirectionMapping: any = {
  asc: SortDirection.Ascending,
  desc: SortDirection.Descending,
};

export const DepartmentTableContainer = observer(() => {
  const { toastStore } = useStores();
  const [result, setResult] = useState<DepartmentFilterResponse>({
    pageNumber: 1,
    pageSize: 10,
    count: 0,
  });
  const [loading, setLoading] = useState(false);
  const [showDepartmentForm, setShowDepartmentForm] = useState(false);
  const [savingDepartment, setSavingDepartment] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [editingDepartment, setEditingDepartment] = useState<
    DepartmentResponseDto | undefined
  >(undefined);

  const page = parseInt(searchParams.get('page') ?? '1');
  const pageSize = parseInt(searchParams.get('pageSize') ?? '10');
  const name = searchParams.get('search') ?? '';
  const sortField = searchParams.get('sort') ?? 'name';
  const sortDirection = searchParams.get('order') ?? 'asc';

  const debouncedName = useDebounce<string>(name, 150);

  const fetchDepartments = async () => {
    try {
      setLoading(true);

      const result = await apiClient.departments.listDepartments({
        name: debouncedName,
        sortField: sortField ? (sortField as DepartmentSortField) : undefined,
        sortDirection: sortDirection
          ? sortDirectionMapping[sortDirection]
          : undefined,
        page,
        pageSize,
      });

      setResult(result);
    } catch (error) {
      logger.error('error fetching departments', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDepartments();
  }, [page, pageSize, debouncedName, sortField, sortDirection]);

  const handleTableChange = ({
    page,
    sort,
  }: CriteriaWithPagination<DepartmentResponseDto>) => {
    const withSearch = name ? { name } : undefined;

    setSearchParams({
      page: (page.index + 1).toString(),
      pageSize: page.size.toString(),
      sort: sort?.field || 'name',
      order: sort?.direction || 'asc',
      ...withSearch,
    });
  };

  const handleSearchChange = (search: string) => {
    let { ...params } = searchParams as any;

    setSearchParams({
      ...params,
      sort: sortField,
      order: sortDirection,
      search,
    });
  };

  const handleToggleShowDepartmentForm = () => {
    setEditingDepartment(undefined);
    setShowDepartmentForm(!showDepartmentForm);
  };

  const handleSaveDepartment = async (departmentDto: DepartmentDto) => {
    try {
      setSavingDepartment(true);
      if (editingDepartment) {
        await apiClient.departments.updateDepartment({
          departmentId: editingDepartment.id,
          departmentDto,
        });
      } else {
        await apiClient.departments.createDepartment({ departmentDto });
      }
      await fetchDepartments();

      setShowDepartmentForm(false);
    } catch (error) {
      logger.error('handleSaveDepartment', error);
      await toastStore.showApiError(error);
    } finally {
      setEditingDepartment(undefined);
      setSavingDepartment(false);
    }
  };

  return (
    <Fragment>
      <EuiFlexGroup gutterSize="s">
        <EuiFlexItem grow={10}>
          <EuiFieldSearch
            fullWidth
            placeholder="Search by name"
            value={name}
            onChange={(e) => handleSearchChange(e.target.value)}
          />
        </EuiFlexItem>
        <AdminRequired>
          <EuiFlexItem grow={2}>
            <EuiButton
              iconType="plus"
              iconSide="left"
              onClick={() => setShowDepartmentForm(true)}
              fullWidth
            >
              Add department
            </EuiButton>
          </EuiFlexItem>
        </AdminRequired>
      </EuiFlexGroup>
      <EuiSpacer size="s" />

      <DepartmentTable
        loading={loading}
        result={{ ...result, items: result.departmentResponses ?? [] }}
        onTableChange={handleTableChange}
        sort={{
          field: sortField as keyof DepartmentResponseDto,
          direction: sortDirection as 'asc' | 'desc',
        }}
        name={name}
        onEdit={(dto) => {
          setEditingDepartment(dto);
          setShowDepartmentForm(true);
        }}
      />
      {showDepartmentForm && (
        <DepartmentForm
          onClose={handleToggleShowDepartmentForm}
          onSave={handleSaveDepartment}
          saving={savingDepartment}
          editingDepartment={editingDepartment}
        />
      )}
    </Fragment>
  );
});
