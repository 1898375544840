import {
  EuiButton,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiSpacer,
  EuiText,
} from '@elastic/eui';
import { Link } from 'react-router-dom';
import { useNavProps } from '../../hooks';
import { homePath } from '../../utils';
import { Logo } from './logo';

export interface ResourceNotFoundProps {
  text?: string;
  linkBack?: string;
}

export const ResourceNotFound = ({ text, linkBack }: ResourceNotFoundProps) => {
  const getNavProps = useNavProps();

  return (
    <EuiEmptyPrompt
      title={
        <EuiFlexGroup direction="column" alignItems="center">
          <Link to={homePath()}>
            <Logo size="lg" />
          </Link>
          <EuiSpacer size="s" />
          <EuiText>
            <h1>{text || 'Page not found'}</h1>
          </EuiText>
        </EuiFlexGroup>
      }
      actions={
        <EuiButton
          size="s"
          fill
          fullWidth
          {...getNavProps(linkBack || homePath())}
        >
          {linkBack ? 'Go back' : 'Go home'}
        </EuiButton>
      }
    />
  );
};
