import { observer } from 'mobx-react';
import { useStores } from '../../hooks';
import { ResourceNotFound } from '../common';

export interface AuthRequiredProps {
  children: JSX.Element;
  isRoute?: boolean;
}

export const AuthRequired = observer((props: AuthRequiredProps) => {
  const { children, isRoute } = props;
  const { authStore } = useStores();

  if (authStore.authenticated) {
    return children;
  }

  return isRoute ? <ResourceNotFound /> : null;
});
