import moment from 'moment';

type LogLabel = 'lifecycle' | 'api' | 'rtm' | 'state' | 'misc';

type Context = Record<string, any>;

const timestamp = (): string => moment().format();

export class Logger {
  public info(label: LogLabel, message: string, context?: Context): void {
    console.info(`${timestamp()}: [${label}] ${message}`, context ?? ' ');
  }

  public debug(label: LogLabel, message: string, context?: Context): void {
    if (process.env.NODE_ENV === 'development') {
      console.debug(`${timestamp()}: [${label}] ${message}`, context ?? ' ');
    }
  }

  public warn(message: string, context?: Context): void {
    console.warn(`${timestamp()}: ${message}`, context ?? ' ');
  }

  public error(message: string, error: any, context?: Context): void {
    console.error(`${timestamp()}: ${message}`, { error, context });
  }
}

export const logger = new Logger();
