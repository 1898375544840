import { EuiScreenReaderOnly, EuiText } from '@elastic/eui';
import { DepartmentResponseDto, LocationDto } from '@unfrl/copdb-sdk';
import { camelToSentence, dateUtils, departmentProfilePath } from '../../utils';
import { LocationPlaceLink } from '../location';
import { BasicTableColumn, TitleCell } from '../table';

const name = (searchValue?: string) => {
  return {
    field: 'name',
    name: 'Name',
    sortable: true,
    render: (value: string, department: DepartmentResponseDto) => {
      return (
        <TitleCell
          title={value}
          searchValue={searchValue}
          linkTo={departmentProfilePath(department.id)}
        />
      );
    },
  };
};

const shortName = (): BasicTableColumn<DepartmentResponseDto> => {
  return {
    field: 'shortName',
    name: 'Short Name',
    sortable: true,
    render: (value: string) => {
      return (
        <EuiText size="relative" title={value}>
          {value}
        </EuiText>
      );
    },
  };
};

const type = (): BasicTableColumn<DepartmentResponseDto> => {
  return {
    field: 'type',
    name: 'Type',
    sortable: true,
    render: (value: string) => {
      return (
        <EuiText size="relative" title={value}>
          {camelToSentence(value)}
        </EuiText>
      );
    },
  };
};

const location = (): BasicTableColumn<DepartmentResponseDto> => {
  return {
    field: 'location',
    name: 'Location',
    render: (location: LocationDto) => {
      return <LocationPlaceLink location={location} size="relative" />;
    },
  };
};

const createdAt = (): BasicTableColumn<DepartmentResponseDto> => {
  return {
    field: 'createdAt',
    name: 'Created',
    sortable: true,
    dataType: 'date',
    width: '100px',
    render: (created: Date) => {
      return (
        <EuiText size="relative" title={dateUtils.format(created)}>
          {dateUtils.fromNow(created)}
        </EuiText>
      );
    },
  };
};

const actions = (
  onEdit: (department: DepartmentResponseDto) => void,
): BasicTableColumn<DepartmentResponseDto> => {
  return {
    width: '25px',
    name: (
      <EuiScreenReaderOnly>
        <span>Actions</span>
      </EuiScreenReaderOnly>
    ),
    actions: [
      {
        name: 'Edit',
        description: 'Edit this department',
        type: 'icon',
        icon: 'pencil',
        onClick: onEdit,
      },
    ],
  };
};

export const departmentTableColumns = {
  name,
  shortName,
  type,
  location,
  createdAt,
  actions,
};
