import { IncidentResponseDto } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { BasicTable, BasicTableColumn, BasicTableProps } from '../table';
import { incidentTableColumns } from './incident-table-columns';

export interface IncidentTableProps
  extends Pick<
    BasicTableProps<IncidentResponseDto>,
    'result' | 'onTableChange' | 'loading' | 'sort' | 'hideResultsCount'
  > {
  searchValue?: string;
}

export const IncidentTable = observer((props: IncidentTableProps) => {
  const { searchValue, result, ...rest } = props;

  const columns: BasicTableColumn<IncidentResponseDto>[] = [
    incidentTableColumns.summary(searchValue),
    incidentTableColumns.dateOccurred(),
    incidentTableColumns.location(),
    incidentTableColumns.tags(),
    incidentTableColumns.createdAt(),
    incidentTableColumns.updatedAt(),
  ];

  return (
    <BasicTable
      {...rest}
      result={result}
      columns={columns}
      subject="Incidents"
    />
  );
});
