import {
  EuiMarkdownFormat,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui';
import { IncidentResponseDto, Media } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { MissingDataText } from '../common';
import { copTableColumns } from '../cop';
import { MediaPreviewList } from '../media';
import { BasicTable } from '../table';

export interface IncidentDetailsProps {
  incidentDto: IncidentResponseDto;
  media: Media[];
}

export const IncidentDetails = observer((props: IncidentDetailsProps) => {
  const { incidentDto, media } = props;

  const copsWithAssociations = (incidentDto.cops ?? []).map((cop) => {
    const incidentInformation = incidentDto.personIncidentAssociations?.find(
      (association) => association.personId === cop.personId,
    )?.incidentInformation;

    return {
      ...cop,
      shotsFired: incidentInformation?.shotsFired,
    };
  });

  const columns = [
    copTableColumns.name(),
    copTableColumns.department(),
    copTableColumns.position(),
    copTableColumns.badgeNumber(),
    {
      field: 'shotsFired',
      name: 'Shots fired',
      render: (shotsFired?: number | null) =>
        shotsFired ?? <MissingDataText text="N/A" />,
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      <EuiTitle size="xxs">
        <EuiText>Description</EuiText>
      </EuiTitle>
      <EuiSpacer size="xs" />
      {incidentDto.description ? (
        <EuiMarkdownFormat>{incidentDto.description}</EuiMarkdownFormat>
      ) : (
        <MissingDataText text="No description recorded." />
      )}
      <EuiSpacer size="m" />
      <EuiTitle size="xxs">
        <EuiText>Cops ({incidentDto.cops?.length || 0})</EuiText>
      </EuiTitle>
      <EuiSpacer size="xs" />
      <EuiPanel hasBorder paddingSize="s">
        <BasicTable
          hideResultsCount
          columns={columns}
          result={{ items: copsWithAssociations }}
          subject="Cops"
        />
      </EuiPanel>
      <EuiSpacer size="m" />
      <EuiTitle size="xxs">
        <EuiText>Media</EuiText>
      </EuiTitle>
      <EuiSpacer size="xs" />
      <MediaPreviewList
        mediaList={media}
        mediaAssociations={incidentDto.mediaAssociations}
        existingCops={incidentDto.cops}
        hideIncidentAssociation
        displayMediaDetailsLink
      />
    </div>
  );
});
