import {
  EuiHorizontalRule,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui';
import { CopResponseDto, Media, ReportVersion } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { Fragment } from 'react';
import { dateUtils } from '../../utils';
import { IncidentPreview } from '../incident';
import { LocationPlaceLink } from '../location';
import { MediaPreviewListReadonly } from '../media';
import { ExistingCopsTable } from './existing-cops-table';
import { NewCopsTable } from './new-cops-table';

const SectionHeader = ({ title }: { title: string }) => {
  return (
    <Fragment>
      <EuiTitle size="xs">
        <h2>{title}</h2>
      </EuiTitle>
      <EuiSpacer size="xs" />
    </Fragment>
  );
};

export interface ReportViewProps {
  version: ReportVersion;
  existingCops: CopResponseDto[];
  mediaList: Media[];
}

/**
 * Component used to view a report in readonly mode.
 */
export const ReportView = observer((props: ReportViewProps) => {
  const { version, existingCops, mediaList } = props;
  const { existingCopIncidentInformations } = version.reportDto;

  return (
    <Fragment>
      <EuiSpacer size="s" />
      <SectionHeader title="Date occurred" />
      {version.reportDto.dateOccurred ? (
        <EuiText>{dateUtils.format(version.reportDto.dateOccurred)}</EuiText>
      ) : (
        <EuiText color="subdued" size="relative">
          Date not recorded.
        </EuiText>
      )}
      <EuiSpacer size="m" />
      <SectionHeader title="Location" />
      <LocationPlaceLink location={version.reportDto.location} />
      <EuiHorizontalRule margin="m" />

      <SectionHeader title="Existing cops" />
      <EuiPanel hasBorder paddingSize="s">
        <ExistingCopsTable
          cops={existingCops.map((cop) => ({
            ...cop,
            incidentInformation: existingCopIncidentInformations?.find(
              (info) => info.personId === cop.personId,
            )?.incidentInformation,
          }))}
        />
      </EuiPanel>
      <EuiSpacer size="s" />
      <SectionHeader title="New cops" />
      <EuiPanel hasBorder paddingSize="s">
        <NewCopsTable cops={version.reportDto.newCops ?? []} />
      </EuiPanel>
      <EuiHorizontalRule margin="m" />

      <SectionHeader title="Incident" />
      <IncidentPreview
        summary={version.reportDto.summary}
        preliminary={version.reportDto.preliminary}
        tags={version.reportDto.tags?.map((tag) => tag.name)}
        description={version.reportDto.description}
        dateOccurred={version.reportDto.dateOccurred}
      />
      <EuiHorizontalRule margin="m" />

      <SectionHeader title={`Media (${mediaList.length})`} />
      <MediaPreviewListReadonly
        mediaList={mediaList}
        newCops={version.reportDto.newCops}
        existingCops={existingCops}
        mediaAssociations={version.reportDto.mediaAssociations}
      />
      <EuiHorizontalRule />
    </Fragment>
  );
});
