import { EuiComboBox, EuiComboBoxOptionOption } from '@elastic/eui';
import { CopResponseDto } from '@unfrl/copdb-sdk';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useState } from 'react';
import { apiClient } from '../../api';
import {
  formatNameFromResponseDto,
  getProfilePhotoFromResponse,
  logger,
} from '../../utils';
import { CopAvatar } from './cop-avatar';

const mapCopsToOptions = (
  cops: CopResponseDto[],
): EuiComboBoxOptionOption[] => {
  return cops.map((cop) => {
    const label = formatNameFromResponseDto(cop);
    const photoUrl = getProfilePhotoFromResponse(cop);

    return {
      label,
      prepend: (
        <CopAvatar
          size={30}
          name={label}
          photoUrl={photoUrl}
          disableFullScreen
        />
      ),
      key: cop.id,
      value: cop.id,
    };
  });
};

export interface CopSearchProps {
  onSelected: (cop: CopResponseDto) => void;
}

export const CopSearch = observer((props: CopSearchProps) => {
  const { onSelected } = props;
  const [copOptions, setCopOptions] = useState<CopResponseDto[]>([]);
  const [loading, setLoading] = useState(false);

  const comboBoxOptions = mapCopsToOptions(copOptions);

  const handleSearchChange = useCallback(async (value: string) => {
    setLoading(true);
    setCopOptions([]);

    try {
      const { cops } = await apiClient.cops.listCops({
        nameQuery: value,
        nameQueryType: 'basic',
      });
      setCopOptions(cops ?? []);
    } catch (error) {
      logger.error('error when fetching cops', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    // trigger initial load
    handleSearchChange('');
  }, [handleSearchChange]);

  return (
    <EuiComboBox
      async
      fullWidth
      singleSelection
      aria-label="Search existing cops by name"
      placeholder="Search existing cops by name"
      isLoading={loading}
      options={comboBoxOptions}
      rowHeight={50}
      onSearchChange={debounce(handleSearchChange, 100)}
      onChange={(opts) => {
        const [opt] = opts;
        const mappedToCop = copOptions.find((cop) => cop.id === opt.key!);
        if (mappedToCop) {
          onSelected(mappedToCop);
        }
      }}
    />
  );
});
