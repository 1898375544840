import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { logger } from '../../utils';

export class BaseMethods<T> {
  protected readonly connection: HubConnection;

  protected readonly modelName: string;

  public constructor(connection: HubConnection, modelName: string) {
    this.connection = connection;
    this.modelName = modelName;
  }

  public async connect(id: string): Promise<void> {
    if (this.connection.state !== HubConnectionState.Connected) {
      logger.warn('attempted to invoke command, but state is not connected', {
        connectionState: this.connection.state,
      });
      return;
    }

    try {
      const success = await this.connection.invoke(
        `Connect${this.modelName}`,
        id,
      );
      if (success) {
        logger.info('rtm', `connected to ${this.modelName}: ${id}`);
      }
    } catch (error: any) {
      logger.error('error connecting', error);
    }
  }

  public async disconnect(id: string): Promise<void> {
    if (this.connection.state !== HubConnectionState.Connected) {
      logger.warn('attempted to invoke command, but state is not connected', {
        connectionState: this.connection.state,
      });
      return;
    }

    try {
      await this.connection.invoke(`Disconnect${this.modelName}`, id);
      logger.info('rtm', `disconnected from ${this.modelName}: ${id}`);
    } catch (error: any) {
      logger.error('error when disconnecting', error);
    }
  }

  public onAdded(cb: (response: T) => void) {
    this.connection.on(`${this.modelName}.Added`, cb);
  }

  public onUpdated(cb: (response: T) => void) {
    this.connection.on(`${this.modelName}.Updated`, cb);
  }

  public onDeleted(cb: (id: string) => void) {
    this.connection.on(`${this.modelName}.Deleted`, cb);
  }

  public onProgress(
    cb: (response: { id: string; progress: number; status: string }) => void,
  ) {
    this.connection.on(`${this.modelName}.Progress`, cb);
  }
}
