import {
  CopDepartmentResponseDto,
  CopEdit,
  CopResponseDto,
  EditStatus,
  PersonMediaResponseDto,
  UpsertCopDto,
} from '@unfrl/copdb-sdk';
import { uniq } from 'lodash';
import { makeAutoObservable } from 'mobx';
import { apiClient } from '../api';
import { DataStore } from './data.store';
import { RootStore } from './root.store';

export interface CopEditMapping {
  id: string;
  cop: CopResponseDto;
  edits: CopEdit[];
  existingPersonMediaDtos: PersonMediaResponseDto[];
  existingCopDepartments: CopDepartmentResponseDto[];
}

export class CopEditStore {
  private readonly _root: RootStore;

  public data: DataStore<CopEditMapping> = new DataStore();

  public constructor(rootStore: RootStore) {
    this._root = rootStore;

    makeAutoObservable(this);
  }

  public fetchCopEdits = async (
    status?: EditStatus,
    copIds?: string[],
  ): Promise<void> => {
    const edits = await apiClient.cops.listCopEdits({ status, copIds });
    if (!edits.length) {
      return;
    }
    const uniqueCopIds = uniq(edits.map((edit) => edit.copId));
    const newMediaIds = edits.flatMap((edit) =>
      (edit.editDto.personMediaDtos ?? []).map((dto) => dto.mediaId),
    );
    const newDepartmentIds = uniq(
      edits
        .flatMap((edit) =>
          edit.editDto.copDepartmentDtos?.map((dep) => dep.departmentId),
        )
        .filter(Boolean) as string[],
    );

    const [copDetails] = await Promise.all([
      apiClient.cops.listCopDetails({ copIds: uniqueCopIds }),
      this._root.copStore.fetchCops(uniqueCopIds),
      this._root.mediaStore.fetchMedias(newMediaIds),
      this._root.departmentStore.fetchDepartments(newDepartmentIds),
    ]);

    const allPersonMedias = copDetails.flatMap(
      (details) => details.medias ?? [],
    );

    allPersonMedias.forEach((personMedia) => {
      if (personMedia.media) {
        this._root.mediaStore.data.setItem(personMedia.media);
      }
    });

    const allCopDepartments = copDetails.flatMap(
      (details) => details.cop.copDepartments ?? [],
    );

    const mappings: CopEditMapping[] = uniqueCopIds.map((id) => {
      const cop = this._root.copStore.data.getItem(id)!;

      const existingCopDepartments = allCopDepartments.filter(
        (cd) => cd.copId === id,
      );

      const existingPersonMediaDtos = allPersonMedias
        .filter((pm) => pm.personId === cop.personId)
        .map((pm) => ({
          mediaId: pm.mediaId,
          url: pm.media?.url ?? '',
          profilePhoto: !!pm.profilePhoto,
        }));

      return {
        id,
        edits: edits.filter((edit) => edit.copId === id),
        cop,
        existingPersonMediaDtos,
        existingCopDepartments,
      };
    });

    this.data.setList(mappings);
  };

  public createCopEdit = async (
    copId: string,
    copDto: UpsertCopDto,
  ): Promise<void> => {
    const [edit] = await Promise.all([
      apiClient.cops.createCopEdit({ copId, upsertCopDto: copDto }),
      this._root.copStore.fetchCops([copId]),
    ]);

    const existing = this.data.getItem(copId);
    if (existing) {
      this.data.updateItem(copId, { edits: [...existing.edits, edit] });
    } else {
      this.data.setItem({
        id: copId,
        edits: [edit],
        cop: this._root.copStore.data.getItem(copId)!,
        existingPersonMediaDtos: [],
        existingCopDepartments: [],
      });
    }
  };

  public updateEditStatus = async (
    editId: string,
    status: EditStatus,
  ): Promise<void> => {
    const updated = await apiClient.cops.updateCopEditStatus({
      editId,
      status,
    });

    this.handleUpdatedEdit(updated);
  };

  public overwriteCopEdit = async (
    copId: string,
    copDto: UpsertCopDto,
  ): Promise<void> => {
    const copEdit = await apiClient.cops.createCopEdit({
      copId,
      upsertCopDto: copDto,
    });

    await this.updateEditStatus(copEdit.id, EditStatus.Approved);
  };

  //#region Actions

  private handleUpdatedEdit = async (updated: CopEdit): Promise<void> => {
    const { copId, id } = updated;

    if (updated.status === EditStatus.Approved) {
      await this._root.copStore.fetchCops([copId], true);
      this.data.deleteItem(copId);
      return;
    }

    const mapping = this.data.getItem(copId);
    if (!mapping) {
      return;
    }

    if (updated.status === EditStatus.Denied) {
      const index = mapping.edits.findIndex((e) => e.id === id);
      if (index > -1) {
        mapping.edits.splice(index, 1);
      }
    }

    if (!mapping.edits.length) {
      this.data.deleteItem(copId);
    }
  };

  //#endregion
}
