import {
  EuiComboBox,
  EuiIcon,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiToolTip,
} from '@elastic/eui';
import { CopDepartmentDto } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks';
import { Flex, LabelOrMissing, TextDiff } from '../common';
import { camelToSentence } from '../../utils';
import { isEqual } from 'lodash';

export interface DepartmentEditDiffProps {
  beforeDepartments: CopDepartmentDto[];
  afterDepartments: CopDepartmentDto[];
}

export const DepartmentEditDiff = observer((props: DepartmentEditDiffProps) => {
  const { beforeDepartments, afterDepartments } = props;
  const { departmentStore } = useStores();

  const addedDepartments = afterDepartments.filter(
    (after) =>
      !beforeDepartments.find(
        (before) => before.departmentId === after.departmentId,
      ),
  );

  const deletedDepartments = beforeDepartments.filter(
    (before) =>
      !afterDepartments.find(
        (after) => after.departmentId === before.departmentId,
      ),
  );

  const updatedDepartments = afterDepartments.filter((after) => {
    const extant = beforeDepartments.find(
      (before) => before.departmentId === after.departmentId,
    );

    return (
      extant &&
      (extant.startDate?.toString() !== after.startDate?.toString() ||
        extant.endDate?.toString() !== after.endDate?.toString() ||
        extant.badgeNumber !== after.badgeNumber ||
        extant.position !== after.position ||
        !isEqual(extant.notabilities ?? [], after.notabilities ?? []))
    );
  });

  if (
    !addedDepartments.length &&
    !deletedDepartments.length &&
    !updatedDepartments.length
  ) {
    return (
      <EuiText size="s" color="subdued">
        No Changes
      </EuiText>
    );
  }

  return (
    <Flex column gap={8}>
      {addedDepartments.map((addedDepartment) => {
        const departmentInfo = departmentStore.data.getItem(
          addedDepartment.departmentId,
        );

        const notabilities =
          addedDepartment.notabilities?.map((val) => {
            return {
              label: camelToSentence(val),
              color: 'success',
              prepend: <EuiIcon size="s" type="plus" />,
            };
          }) ?? [];

        return (
          <EuiToolTip key={addedDepartment.departmentId} title="Added">
            <EuiPanel
              color="success"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <EuiText>{departmentInfo?.name}</EuiText>

              <LabelOrMissing
                name="Position"
                value={addedDepartment.position}
                type="Text"
              />
              <LabelOrMissing
                name="Badge"
                value={addedDepartment.badgeNumber}
                type="Text"
              />
              <LabelOrMissing
                name="Start date"
                value={addedDepartment.startDate?.toDateString()}
                type="Text"
              />
              <LabelOrMissing
                name="End date"
                value={addedDepartment.endDate?.toDateString()}
                type="Text"
              />
              {notabilities.length ? (
                <Flex align="center">
                  <EuiText
                    size="s"
                    color="subdued"
                    style={{ wordBreak: 'keep-all', marginRight: 8 }}
                  >
                    Notabilities:
                  </EuiText>
                  <EuiSpacer size="s" />
                  <EuiComboBox
                    aria-label="Ways in which this cop is notable"
                    selectedOptions={[...notabilities]}
                    isDisabled
                  />
                </Flex>
              ) : null}
            </EuiPanel>
          </EuiToolTip>
        );
      })}
      {deletedDepartments.map((deletedDepartment) => {
        const departmentInfo = departmentStore.data.getItem(
          deletedDepartment.departmentId,
        );
        return (
          <EuiToolTip key={deletedDepartment.departmentId} title="Added">
            <EuiPanel
              color="danger"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <EuiText>{departmentInfo?.name}</EuiText>
              <LabelOrMissing
                name="Start date"
                value={deletedDepartment.startDate?.toDateString()}
              />
              <LabelOrMissing
                name="End date"
                value={deletedDepartment.endDate?.toDateString()}
              />
            </EuiPanel>
          </EuiToolTip>
        );
      })}
      {updatedDepartments.map((updatedDepartment) => {
        const departmentInfo = departmentStore.data.getItem(
          updatedDepartment.departmentId,
        );
        const originalDepartment = beforeDepartments.find(
          (before) => before.departmentId === updatedDepartment.departmentId,
        );
        const originalStartDate =
          originalDepartment?.startDate?.toDateString() ?? '';
        const originalEndDate =
          originalDepartment?.endDate?.toDateString() ?? '';

        const addedNotabilities =
          updatedDepartment.notabilities
            ?.filter((val) => !originalDepartment?.notabilities?.includes(val))
            .map((val) => {
              return {
                label: camelToSentence(val),
                color: 'success',
                prepend: <EuiIcon size="s" type="plus" />,
              };
            }) ?? [];

        const removedNotabilities =
          originalDepartment?.notabilities
            ?.filter((val) => !updatedDepartment?.notabilities?.includes(val))
            .map((val) => {
              return {
                label: camelToSentence(val),
                color: 'danger',
                prepend: <EuiIcon size="s" type="cross" />,
              };
            }) ?? [];

        const unchangedNotabilities =
          originalDepartment?.notabilities
            ?.filter((val) => updatedDepartment?.notabilities?.includes(val))
            .map((val) => {
              return {
                label: camelToSentence(val),
              };
            }) ?? [];

        const changedNotabilities = [
          ...addedNotabilities,
          ...removedNotabilities,
        ];

        return (
          <EuiToolTip key={updatedDepartment.departmentId} title="Updated">
            <EuiPanel
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <EuiText>{departmentInfo?.name}</EuiText>
              <TextDiff
                beforeText={originalStartDate}
                afterText={updatedDepartment.startDate?.toDateString() ?? ''}
                label="Start"
                hideOnNoDiff
              />
              <TextDiff
                beforeText={originalEndDate}
                afterText={updatedDepartment.endDate?.toDateString() ?? ''}
                label="End"
                hideOnNoDiff
              />
              <TextDiff
                beforeText={originalDepartment?.badgeNumber}
                afterText={updatedDepartment?.badgeNumber}
                label="Badge number"
                hideOnNoDiff
              />
              <TextDiff
                beforeText={originalDepartment?.position}
                afterText={updatedDepartment?.position}
                label="Position"
                hideOnNoDiff
              />
              {changedNotabilities.length ? (
                <Flex align="center">
                  <EuiText
                    size="s"
                    color="subdued"
                    style={{ wordBreak: 'keep-all', marginRight: 8 }}
                  >
                    Notabilities:
                  </EuiText>
                  <EuiSpacer size="s" />
                  <EuiComboBox
                    aria-label="Ways in which this cop is notable"
                    selectedOptions={[
                      ...changedNotabilities,
                      ...unchangedNotabilities,
                    ]}
                    isDisabled
                  />
                </Flex>
              ) : null}
            </EuiPanel>
          </EuiToolTip>
        );
      })}
    </Flex>
  );
});
