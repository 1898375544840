import { observer } from 'mobx-react';
import { useStores } from '../../hooks';
import { Flex } from '../common';
import { MediaEditItem } from './media-edit-item';

export interface MediaEditListProps {
  mediaId: string;
}

export const MediaEditList = observer((props: MediaEditListProps) => {
  const { mediaId } = props;
  const { mediaEditStore } = useStores();

  const mapping = mediaEditStore.getEditMapping(mediaId);
  if (!mapping) {
    return null;
  }

  const { edits } = mapping;

  return (
    <Flex column gap={8} style={{ width: '100%' }}>
      {edits.map((edit) => (
        <MediaEditItem key={edit.id} edit={edit} />
      ))}
    </Flex>
  );
});
