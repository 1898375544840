import { EuiIcon, EuiPanel, EuiText, EuiToolTip } from '@elastic/eui';
import {
  IncidentInformationDto,
  PersonIncidentAssociationDto,
} from '@unfrl/copdb-sdk';
import { isEqual } from 'lodash';
import { CSSProperties } from 'react';
import { useStores } from '../../hooks';
import { camelToSentence, mapResponseToDetailItem } from '../../utils';
import { Flex, TextDiff } from '../common';
import { CopDetailItem } from '../cop';

export interface IncidentEditCopDiffProps {
  before: PersonIncidentAssociationDto[];
  after: PersonIncidentAssociationDto[];
}

export const IncidentEditCopDiff = (props: IncidentEditCopDiffProps) => {
  const { copStore } = useStores();

  const { before, after } = props;

  const copPanelStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 90,
    minWidth: 250,
  };

  const editedCopInfos: {
    personId: string;
    original?: IncidentInformationDto;
    edited?: IncidentInformationDto;
  }[] = [];

  // Populate editedCopInfos. It holds info on cops whose incidentInformation was edited
  before.forEach((beforeCop) => {
    const editedCop = after.find(
      (afterCop) =>
        afterCop.personId === beforeCop.personId &&
        !isEqual(afterCop.incidentInformation, beforeCop.incidentInformation),
    );
    if (editedCop) {
      editedCopInfos.push({
        personId: beforeCop.personId!,
        original: beforeCop.incidentInformation,
        edited: editedCop.incidentInformation,
      });
    }
  });

  const beforeCops = copStore.data.orderedItems
    .filter((cop) => before.find((pii) => pii.personId === cop.personId))
    .sort();
  const afterCops = copStore.data.orderedItems
    .filter((cop) => after.find((pii) => pii.personId === cop.personId))
    .sort();

  const elementResults: React.ReactElement[] = [];

  // Add cops that were removed to elementResults
  beforeCops.forEach((cop) => {
    if (afterCops.some((afterCop) => afterCop.id === cop.id)) {
      return;
    }
    elementResults.push(
      <EuiPanel
        grow={false}
        color="danger"
        paddingSize="s"
        key={cop.id}
        style={copPanelStyle}
      >
        <EuiToolTip title={'Deleted'}>
          <Flex align={'center'} style={{ height: 50 }} gap={8}>
            <EuiIcon type="cross" />
            <CopDetailItem {...mapResponseToDetailItem(cop)} />
          </Flex>
        </EuiToolTip>
      </EuiPanel>,
    );
  });

  // Add cops that were added to elementResults
  afterCops.forEach((cop) => {
    if (beforeCops.some((beforeCop) => beforeCop.id === cop.id)) {
      return;
    }
    const addedCopInfo = after.find((info) => info.personId === cop.personId);

    elementResults.push(
      <EuiPanel
        grow={false}
        color="success"
        paddingSize="s"
        key={cop.id}
        style={copPanelStyle}
      >
        <EuiToolTip title={'Added'}>
          <Flex align={'center'} gap={8}>
            <EuiIcon type="plus" />
            <Flex align={'center'} column style={{ minHeight: 50 }}>
              <CopDetailItem {...mapResponseToDetailItem(cop)} />
              {/* If they have incidentInformation we need to render that as well */}
              {addedCopInfo?.incidentInformation &&
                Object.keys(addedCopInfo.incidentInformation).map((infoKey) => {
                  const val = (addedCopInfo.incidentInformation as any)[
                    infoKey
                  ];
                  if (!val) {
                    return null;
                  }
                  return (
                    <Flex wrap key={infoKey}>
                      <EuiText color="subdued">
                        {camelToSentence(infoKey)}:
                      </EuiText>
                      <div style={{ marginRight: 8 }} />
                      <EuiText>{val.toString()}</EuiText>
                    </Flex>
                  );
                })}
            </Flex>
          </Flex>
        </EuiToolTip>
      </EuiPanel>,
    );
  });

  // Add cops whose incidentInformation was edited to elementResults
  editedCopInfos.forEach((info) => {
    const copDetails = copStore.data.orderedItems.find(
      (cop) => cop.personId === info.personId,
    );
    if (!copDetails) {
      return;
    }

    const incidentInfoKeys = new Set<keyof IncidentInformationDto>();

    [
      ...Object.keys(info.original ?? {}),
      ...Object.keys(info.edited ?? {}),
    ].forEach((infoKey) =>
      incidentInfoKeys.add(infoKey as keyof IncidentInformationDto),
    );

    const infoDiffs = [...incidentInfoKeys].map((infoKey) => {
      const originalValue = info.original ? info.original[infoKey] : null;
      const editedValue = info.edited ? info.edited[infoKey] : null;

      return (
        <TextDiff
          key={infoKey}
          label={camelToSentence(infoKey)}
          beforeText={originalValue?.toString()}
          afterText={editedValue?.toString()}
        />
      );
    });

    elementResults.push(
      <EuiPanel
        grow={false}
        hasBorder
        paddingSize="s"
        key={info.personId}
        style={copPanelStyle}
      >
        <CopDetailItem {...mapResponseToDetailItem(copDetails)} />
        {infoDiffs}
      </EuiPanel>,
    );
  });

  return (
    <EuiPanel>
      <EuiText color="subdued">{'Cops'}:</EuiText>
      <Flex wrap align={'center'} gap={8}>
        {elementResults}
      </Flex>
    </EuiPanel>
  );
};
