import { EuiComboBox } from '@elastic/eui';
import { DepartmentResponseDto } from '@unfrl/copdb-sdk';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useState } from 'react';
import { apiClient } from '../../api';
import { useStores } from '../../hooks';
import { logger } from '../../utils';

export interface DepartmentSearchProps {
  onSelected: (department: DepartmentResponseDto) => void;
}

export const DepartmentSearch = observer((props: DepartmentSearchProps) => {
  const { toastStore } = useStores();
  const { onSelected } = props;
  const [results, setResults] = useState<DepartmentResponseDto[]>([]);
  const [loading, setLoading] = useState(false);

  const handleSearchChange = useCallback(async (value: string) => {
    setLoading(true);
    setResults([]);

    try {
      const { departmentResponses } =
        await apiClient.departments.listDepartments({ name: value });
      setResults(departmentResponses ?? []);
    } catch (error) {
      toastStore.showApiError(error);
      logger.error('searching departments', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    // trigger initial load
    handleSearchChange('');
  }, [handleSearchChange]);

  const options = results.map((department) => ({
    label: department.name,
    key: department.id,
    value: department.id,
  }));

  return (
    <EuiComboBox
      async
      fullWidth
      placeholder="Search by name"
      isLoading={loading}
      options={options}
      onSearchChange={debounce(handleSearchChange, 250)}
      onChange={(opts) => {
        const [selected] = opts
          .map((opt) => results.find((dept) => dept.id === opt.value))
          .filter(Boolean) as DepartmentResponseDto[];

        onSelected(selected);
      }}
    />
  );
});
