import {
  EuiButton,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiCodeBlock,
  EuiConfirmModal,
  EuiFieldPassword,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiHealth,
  EuiHorizontalRule,
  EuiSpacer,
  EuiText,
  EuiTitle,
  copyToClipboard,
} from '@elastic/eui';
import { TfaConfigurationDto } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { apiClient } from '../../api';
import { useStores } from '../../hooks';
import { Flex, Spinner } from '../common';

const totpKey = (email: string, secret: string) => {
  return `otpauth://totp/CopDB:${email}?secret=${secret}&issuer=CopDB`;
};

export const TwoFactorForm = observer(() => {
  const { toastStore } = useStores();
  const [tfaConfig, setTfaConfig] = useState<TfaConfigurationDto | null>(null);
  const [totp, setTotp] = useState('');
  const [loading, setLoading] = useState(false);
  const [showConfirmDisable, setShowConfirmDisable] = useState(false);
  const [password, setPassword] = useState('');
  const [recoveryCodes, setRecoveryCodes] = useState<string[] | null>(null);

  const load = useCallback(async () => {
    try {
      const config = await apiClient.accounts.tfaConfiguration();
      setTfaConfig(config);
    } catch (error) {
      toastStore.showApiError(error);
    }
  }, [toastStore]);

  useEffect(() => {
    load();
  }, [load]);

  const handleCopyKey = () => {
    if (tfaConfig?.authenticatorKey) {
      copyToClipboard(tfaConfig.authenticatorKey);
      toastStore.showSuccess('Authenticator key copied to clipboard');
    }
  };

  const handleEnableTfa = async () => {
    if (!tfaConfig || !totp) {
      return;
    }

    try {
      setLoading(true);

      const { recoveryCodes } = await apiClient.accounts.tfaSetup({
        tfaSetupCode: totp,
      });

      setRecoveryCodes(recoveryCodes);
      setTfaConfig({ ...tfaConfig, isTfaEnabled: true });
      setTotp('');
    } catch (error) {
      toastStore.showApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDisableTfa = async () => {
    if (!tfaConfig || !password) {
      return;
    }

    try {
      setLoading(true);

      await apiClient.accounts.tfaRemove({ password });
      await load();

      setPassword('');
      setShowConfirmDisable(false);
    } catch (error) {
      toastStore.showApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const renderContent = () => {
    if (!tfaConfig) {
      return <Spinner />;
    }

    if (tfaConfig.isTfaEnabled) {
      return (
        <>
          <Flex column gap={8}>
            {recoveryCodes?.length ? (
              <>
                <EuiTitle size="xs">
                  <h4>Recovery codes</h4>
                </EuiTitle>
                <EuiText size="s">
                  Store these in a safe place. These can be used to bypass 2FA.
                  These will only be shown once.
                </EuiText>
                <EuiCodeBlock isCopyable>
                  {recoveryCodes.join('\n')}
                </EuiCodeBlock>
              </>
            ) : null}
            <EuiButtonEmpty
              size="s"
              color="danger"
              onClick={() => setShowConfirmDisable(true)}
            >
              Disable two-factor authentication
            </EuiButtonEmpty>
          </Flex>
          {showConfirmDisable ? (
            <EuiConfirmModal
              title="Confirm action"
              buttonColor="danger"
              confirmButtonText="Disable"
              cancelButtonText="Cancel"
              onConfirm={handleDisableTfa}
              onCancel={() => setShowConfirmDisable(false)}
              isLoading={loading}
              confirmButtonDisabled={loading || !password}
            >
              <EuiText>
                Enter your password to disable two-factor authentication.
              </EuiText>
              <EuiFieldPassword
                fullWidth
                type="dual"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </EuiConfirmModal>
          ) : null}
        </>
      );
    }

    return (
      <>
        <EuiFormRow fullWidth label="Authenticator key">
          <>
            <Flex justify="center">
              <div style={{ padding: 16, background: 'white' }}>
                <QRCode
                  value={totpKey(tfaConfig.email, tfaConfig.authenticatorKey!)}
                />
              </div>
            </Flex>
            <Flex gap={8} justify="center" align="flex-end">
              <EuiText color="subdued">{tfaConfig.authenticatorKey}</EuiText>
              <EuiButtonIcon
                size="s"
                color="text"
                iconType="copyClipboard"
                title="Copy authenticator key"
                aria-label="Copy authenticator key to clipboard"
                onClick={handleCopyKey}
              />
            </Flex>
          </>
        </EuiFormRow>
        <EuiFormRow fullWidth label="Verification code">
          <EuiFieldText
            fullWidth
            value={totp}
            onChange={(e) => setTotp(e.target.value)}
          />
        </EuiFormRow>
        <EuiSpacer size="m" />
        <Flex justify="flex-end">
          <EuiButton
            fill
            size="s"
            disabled={!tfaConfig.authenticatorKey || !totp || loading}
            onClick={handleEnableTfa}
          >
            {loading ? 'Enabling 2FA...' : 'Enable 2FA'}
          </EuiButton>
        </Flex>
      </>
    );
  };

  return (
    <EuiForm>
      <Flex justify="space-between" align="center">
        <EuiTitle size="s">
          <h3>Two-factor authentication</h3>
        </EuiTitle>
        {loading || !tfaConfig ? null : (
          <EuiHealth color={tfaConfig.isTfaEnabled ? 'success' : 'danger'}>
            {tfaConfig.isTfaEnabled ? 'Enabled' : 'Not setup'}
          </EuiHealth>
        )}
      </Flex>
      <EuiHorizontalRule margin="s" />
      {renderContent()}
    </EuiForm>
  );
});
