import {
  EuiButton,
  EuiFieldPassword,
  EuiForm,
  EuiFormRow,
  EuiHorizontalRule,
  EuiSpacer,
  EuiTitle,
} from '@elastic/eui';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { apiClient } from '../../api';
import { useStores } from '../../hooks';
import { Flex } from '../common';

export const ChangePasswordForm = observer(() => {
  const { toastStore } = useStores();
  const [saving, setSaving] = useState(false);
  const [changePassword, setChangePassword] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const canSaveNewPassword = Boolean(
    changePassword.currentPassword &&
      changePassword.newPassword &&
      changePassword.currentPassword !== changePassword.newPassword &&
      changePassword.newPassword === changePassword.confirmNewPassword,
  );

  const handleUpdate = async () => {
    if (!canSaveNewPassword) {
      return;
    }

    try {
      setSaving(true);

      await apiClient.accounts.changePassword({
        updatePasswordDto: {
          currentPassword: changePassword.currentPassword,
          newPassword: changePassword.newPassword,
        },
      });

      toastStore.showSuccess(
        'Password updated',
        'Your password was successfully updated.',
      );
      setChangePassword({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      });
    } catch (error) {
      toastStore.showApiError(error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <EuiForm>
      <EuiTitle size="s">
        <h5>Change password</h5>
      </EuiTitle>
      <EuiHorizontalRule margin="s" />
      <EuiFormRow fullWidth label="Current password">
        <EuiFieldPassword
          fullWidth
          type="dual"
          value={changePassword.currentPassword}
          onChange={(e) =>
            setChangePassword({
              ...changePassword,
              currentPassword: e.target.value,
            })
          }
        />
      </EuiFormRow>
      <EuiFormRow fullWidth label="New password">
        <EuiFieldPassword
          fullWidth
          type="dual"
          value={changePassword.newPassword}
          onChange={(e) =>
            setChangePassword({
              ...changePassword,
              newPassword: e.target.value,
            })
          }
        />
      </EuiFormRow>
      <EuiFormRow fullWidth label="Confirm new password">
        <EuiFieldPassword
          fullWidth
          type="dual"
          value={changePassword.confirmNewPassword}
          onChange={(e) =>
            setChangePassword({
              ...changePassword,
              confirmNewPassword: e.target.value,
            })
          }
        />
      </EuiFormRow>
      <EuiSpacer size="m" />
      <Flex justify="flex-end">
        <EuiButton
          fill
          size="s"
          disabled={!canSaveNewPassword}
          isLoading={saving}
          onClick={handleUpdate}
        >
          {saving ? 'Updating...' : 'Update password'}
        </EuiButton>
      </Flex>
    </EuiForm>
  );
});
