import { ReportFilterResponse } from '@unfrl/copdb-sdk';
import { ReportDetails } from '../../stores/report.store';
import { BasicTable, BasicTableProps } from '../table';
import { reportTableColumns } from './report-table-columns';

const columns = [
  reportTableColumns.title(),
  reportTableColumns.reportStatus(),
  reportTableColumns.dateOccurred(),
  reportTableColumns.location(),
  reportTableColumns.updatedAt(),
];

export interface ReportTableProps
  extends Pick<BasicTableProps<ReportDetails>, 'onTableChange' | 'loading'> {
  result: ReportFilterResponse;
}

export const ReportTable = (props: ReportTableProps) => {
  const { onTableChange, loading, result } = props;

  return (
    <BasicTable
      columns={columns}
      loading={loading}
      onTableChange={onTableChange}
      result={{
        ...result,
        items: (result.reportResponses ?? []).map((dto) => ({
          id: dto.report.id,
          report: dto.report,
          version: dto.latestVersion,
        })),
      }}
      subject="Reports"
    />
  );
};
