import {
  CopDepartmentResponseDto,
  CopResponseDto,
  DepartmentResponseDto,
  IncidentResponseDto,
} from '@unfrl/copdb-sdk';
import { formatNameFromResponseDto } from './cop.utils';
import { copProfilePath, departmentProfilePath } from './route.utils';

export const getCopJsonLD = (
  copResponse: CopResponseDto,
  copDepartments?: CopDepartmentResponseDto[],
  profilePhotoUrl?: string,
): string | undefined => {
  if (!copResponse) {
    return;
  }

  // put current departments under 'worksfor'
  const activeDepartments = copDepartments
    ?.filter((cd) => !cd.endDate)
    .map((cd) => ({
      '@type': 'Organization',
      name: cd.name,
      alternateName: cd.shortName,
      url: departmentProfilePath(cd.departmentId),
    }));

  // put previous departments under 'affiliation'
  const previousDepartments = copDepartments
    ?.filter((cd) => cd.endDate)
    .map((cd) => ({
      '@type': 'Organization',
      name: cd.name,
      alternateName: cd.shortName,
      url: departmentProfilePath(cd.departmentId),
    }));

  let mainEntity: any = {
    '@type': 'Person',
    name: formatNameFromResponseDto(copResponse),
  };

  if (copResponse.person.gender) {
    mainEntity = { ...mainEntity, gender: copResponse.person.gender };
  }

  if (profilePhotoUrl) {
    mainEntity = { ...mainEntity, image: profilePhotoUrl };
  }

  if (activeDepartments && activeDepartments.length > 0) {
    mainEntity = { ...mainEntity, worksFor: [...activeDepartments] };
  }

  if (previousDepartments && previousDepartments.length > 0) {
    mainEntity = { ...mainEntity, affiliation: [...previousDepartments] };
  }

  let jsonld: any = {
    '@context': 'https://schema.org',
    '@type': 'ProfilePage',
    mainEntity,
  };

  return JSON.stringify(jsonld);
};

export const getIncidentJsonLD = (
  incident: IncidentResponseDto,
): string | undefined => {
  if (!incident) {
    return;
  }

  let jsonld: any = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    dateCreated: incident.createdAt.toISOString(),
  };

  if (incident.summary) {
    jsonld = { ...jsonld, headline: incident.summary };
  }

  if (incident.location?.placeName) {
    jsonld = {
      ...jsonld,
      contentLocation: {
        '@type': 'Place',
        address: incident.location.placeName,
        geo: {
          '@type': 'GeoCoordinates',
          latitude: incident.location.coordinates.centerLatitude,
          longitude: incident.location.coordinates.centerLongitude,
        },
      },
    };
  }

  if (incident.dateOccurred) {
    jsonld = {
      ...jsonld,
      contentReferenceTime: new Date(incident.dateOccurred).toISOString(),
    };
  }

  const cops = incident.cops?.map((cop) => ({
    '@type': 'Person',
    name: formatNameFromResponseDto(cop),
    url: copProfilePath(cop.id),
  }));

  if (cops) {
    jsonld = {
      ...jsonld,
      character: [...cops],
    };
  }

  return JSON.stringify(jsonld);
};

export const getDepartmentJsonLD = (
  department: DepartmentResponseDto,
): string | undefined => {
  if (!department) {
    return;
  }

  let mainEntity: any = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: department.name,
    alternateName: department.shortName,
  };

  if (department.location?.placeName) {
    mainEntity = {
      ...mainEntity,
      location: {
        '@type': 'Place',
        address: department.location.placeName,
        geo: {
          '@type': 'GeoCoordinates',
          latitude: department.location.coordinates.centerLatitude,
          longitude: department.location.coordinates.centerLongitude,
        },
      },
    };
  }

  let jsonld: any = {
    '@context': 'https://schema.org',
    '@type': 'ProfilePage',
    mainEntity,
  };

  return JSON.stringify(jsonld);
};
