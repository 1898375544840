import { EuiComboBox } from '@elastic/eui';
import { camelToSentence } from '../../utils';

export interface StringComboBoxProps {
  options: string[];
  value?: string | undefined;
  placeholder?: string | undefined;
  isClearable?: boolean;
  onChange: (selected: string | undefined) => void;
}

export const StringComboBox = (props: StringComboBoxProps) => {
  const { options, value, placeholder, isClearable, onChange } = props;

  const mappedOptions = options.map((val) => ({
    label: camelToSentence(val),
    key: val,
    value: val,
  }));

  const mapValueToOption = (value: string) => {
    return {
      label: camelToSentence(value),
      key: value,
      value: value,
    };
  };

  return (
    <EuiComboBox
      async
      fullWidth
      isClearable={isClearable}
      placeholder={placeholder}
      options={mappedOptions}
      singleSelection={{ asPlainText: true }}
      selectedOptions={value ? [mapValueToOption(value)] : []}
      onChange={(selected) => {
        onChange(selected[0]?.value);
      }}
    />
  );
};
