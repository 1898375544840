import { EuiButtonEmpty, EuiContextMenu, EuiPopover } from '@elastic/eui';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useStores } from '../../hooks';

export enum EditAction {
  Approve,
  Deny,
  Overwrite,
}

export interface EditActionsProps {
  onAction: (action: EditAction) => Promise<void> | void;
  disableOverwrite?: boolean;
  disableApproveReason?: string;
}

export const EditActions = observer((props: EditActionsProps) => {
  const { dialogStore } = useStores();
  const { onAction, disableOverwrite, disableApproveReason } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleActionClick = (action: EditAction) => () => {
    handleToggleOpen();

    switch (action) {
      case EditAction.Approve:
        return dialogStore.openDialog({
          title: 'Approve this edit?',
          content:
            'This will apply the changes and remove all other remaining edits.',
          confirmText: 'Approve',
          onConfirm: () => onAction(EditAction.Approve),
        });
      case EditAction.Deny:
        return dialogStore.openDialog({
          title: 'Deny this edit?',
          content:
            'This will set the edit status to denied and remove it from the list.',
          confirmText: 'Deny',
          isDelete: true,
          onConfirm: () => onAction(EditAction.Deny),
        });
      case EditAction.Overwrite:
        return onAction(EditAction.Overwrite);
    }
  };

  const actions: any = [
    {
      name: 'Approve',
      key: 'approved',
      icon: 'check',
      disabled: Boolean(disableApproveReason),
      toolTipContent: disableApproveReason,
      onClick: handleActionClick(EditAction.Approve),
    },
    {
      name: 'Deny',
      key: 'denied',
      icon: 'cross',
      onClick: handleActionClick(EditAction.Deny),
    },
  ];

  if (!disableOverwrite) {
    actions.push(
      {
        isSeparator: true,
        key: 'sep',
      },
      {
        name: 'Overwrite',
        key: 'overwrite',
        icon: 'pencil',
        onClick: handleActionClick(EditAction.Overwrite),
      },
    );
  }

  return (
    <EuiPopover
      id="edit-cop-actions"
      button={
        <EuiButtonEmpty
          size="s"
          iconType="arrowDown"
          iconSide="right"
          onClick={handleToggleOpen}
        >
          Actions
        </EuiButtonEmpty>
      }
      isOpen={isOpen}
      closePopover={handleToggleOpen}
      panelPaddingSize="none"
      anchorPosition="downLeft"
    >
      <EuiContextMenu
        initialPanelId={0}
        panels={[
          {
            id: 0,
            title: 'Actions',
            items: actions,
          },
        ]}
      />
    </EuiPopover>
  );
});
