import { EuiFormRow } from '@elastic/eui';
import { LocationDto } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { LocationSearch } from './location-search';
import { NewPlaceSuggestion } from '../../api';

const mapLocationToPlace = (location: LocationDto): NewPlaceSuggestion => {
  const suggestion: NewPlaceSuggestion = {
    displayName: location.place ?? location.placeName,
    placeName: location.placeName,
    id: '',
  };

  return suggestion;
};

export interface LocationFormProps {
  location?: LocationDto;
  label?: string;
  required?: boolean;
  onLocationChange: (location?: LocationDto) => void;
}

export const LocationForm = observer((props: LocationFormProps) => {
  const { location, label, required, onLocationChange } = props;

  return (
    <EuiFormRow fullWidth label={label}>
      <LocationSearch
        required={required}
        selectedPlaces={
          location?.placeName ? [mapLocationToPlace(location)] : []
        }
        onSelected={onLocationChange}
      />
    </EuiFormRow>
  );
});
