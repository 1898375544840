import {
  EuiButton,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiHorizontalRule,
  EuiIcon,
  EuiLink,
  EuiSpacer,
  EuiText,
} from '@elastic/eui';
import { SignUpDto } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { FormEvent, Fragment, Suspense, lazy, useState } from 'react';
import { Link } from 'react-router-dom';
import { CAPTCHA_SITEKEY } from '../../api/config';
import { useStores } from '../../hooks';
import {
  forgotPasswordPath,
  homePath,
  isValidPassword,
  loginPath,
  signupPath,
} from '../../utils';
import { Flex, Logo } from '../common';
import { PasswordRules } from './password-rules';

const HCaptcha = lazy(() => import('@hcaptcha/react-hcaptcha'));

export interface AuthFormProps {
  isSignUp: boolean;
  onSubmit: (dto: SignUpDto, captchaToken: string | undefined) => void;
}

export const AuthForm = observer(({ isSignUp, onSubmit }: AuthFormProps) => {
  const [state, setState] = useState<SignUpDto>({
    email: '',
    userName: '',
    password: '',
  });

  const [captchaToken, setCaptchaToken] = useState<string | undefined>();

  const { featuresStore, toastStore } = useStores();

  const { captchaEnabled } = featuresStore.features;

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    onSubmit(state, captchaToken);
  };

  const canSubmit = () => {
    const { email, userName, password } = state;

    if (!isSignUp) {
      return email && password;
    }

    return (
      email &&
      userName &&
      password &&
      isValidPassword(password) &&
      (captchaEnabled ? captchaToken : true)
    );
  };

  return (
    <>
      <Flex column align="center">
        <Link to={homePath()}>
          <Logo size="lg" />
        </Link>
        <EuiSpacer size="s" />
        <EuiText>
          <h1>{isSignUp ? 'Sign up' : 'Login'}</h1>
        </EuiText>
      </Flex>
      <EuiSpacer />
      <EuiForm component="form" onSubmit={handleSubmit}>
        <EuiFormRow label="Email" fullWidth>
          <EuiFieldText
            fullWidth
            name="email"
            type="email"
            prepend={<EuiIcon type="email" />}
            value={state.email ?? ''}
            onChange={(e) => setState({ ...state, email: e.target.value })}
          />
        </EuiFormRow>
        {isSignUp && (
          <EuiFormRow label="Username" fullWidth>
            <EuiFieldText
              fullWidth
              name="username"
              prepend={<EuiIcon type="user" />}
              value={state.userName ?? ''}
              onChange={(e) => setState({ ...state, userName: e.target.value })}
            />
          </EuiFormRow>
        )}

        <EuiFormRow label="Password" fullWidth>
          <Fragment>
            <EuiFieldText
              fullWidth
              name="password"
              type="password"
              prepend={<EuiIcon type="lock" />}
              value={state.password ?? ''}
              onChange={(e) => setState({ ...state, password: e.target.value })}
            />
            {isSignUp && (
              <div style={{ marginLeft: 8, marginTop: 8 }}>
                <PasswordRules password={state.password} />
              </div>
            )}
          </Fragment>
        </EuiFormRow>
        {isSignUp && captchaEnabled && (
          <EuiFormRow label="Verify" fullWidth>
            <Suspense>
              <HCaptcha
                sitekey={CAPTCHA_SITEKEY}
                onVerify={(token, key) => setCaptchaToken(token)}
                onError={(info) => {
                  setCaptchaToken(undefined);
                  toastStore.showError('Failed Captcha', info);
                }}
              />
            </Suspense>
          </EuiFormRow>
        )}
        <EuiSpacer />
        <EuiButton
          type="submit"
          size="s"
          fill
          fullWidth
          disabled={!canSubmit()}
        >
          {isSignUp ? 'Sign up' : 'Login'}
        </EuiButton>
      </EuiForm>
      <EuiHorizontalRule />
      <Flex align="center" column gap={8}>
        <Link to={isSignUp ? loginPath() : signupPath()}>
          <EuiLink>
            {isSignUp
              ? 'Login with existing'
              : featuresStore.features.signUpEnabled
              ? 'Create an account'
              : ''}
          </EuiLink>
        </Link>

        <Link to={forgotPasswordPath()}>
          <EuiLink>Forgot password</EuiLink>
        </Link>
      </Flex>
    </>
  );
});
