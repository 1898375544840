import {
  EuiComboBox,
  EuiFilterGroup,
  EuiIconTip,
  EuiSpacer,
} from '@elastic/eui';
import {
  CopDepartmentNotability,
  EyeColor,
  Gender,
  HairColor,
  Race,
} from '@unfrl/copdb-sdk';
import { Fragment } from 'react';
import { FilterSelect, Flex } from '../common';
import { CopPositionFilter } from './cop-position-filter';

export interface CopFilterItems {
  hairColors?: HairColor[];
  eyeColors?: EyeColor[];
  genders?: Gender[];
  races?: Race[];
  notabilities?: CopDepartmentNotability[];
  visibleTattoos?: boolean;
  badgeNumber?: string;
  position?: string;
}

export interface CopTableFiltersProps {
  values: CopFilterItems;
  onChange: (filters: CopFilterItems) => void;
}

export const CopTableFilters = (props: CopTableFiltersProps) => {
  const { values, onChange } = props;

  return (
    <Fragment>
      <EuiFilterGroup fullWidth>
        <FilterSelect
          label="Hair color"
          options={Object.keys(HairColor)}
          values={values.hairColors}
          onChange={(selected) => {
            onChange({
              ...values,
              hairColors: selected ? (selected as HairColor[]) : undefined,
            });
          }}
        />
        <FilterSelect
          label="Eye color"
          options={Object.keys(EyeColor)}
          values={values.eyeColors}
          onChange={(selected) => {
            onChange({
              ...values,
              eyeColors: selected ? (selected as EyeColor[]) : undefined,
            });
          }}
        />
        <FilterSelect
          label="Gender"
          options={Object.keys(Gender)}
          values={values.genders}
          onChange={(selected) => {
            onChange({
              ...values,
              genders: selected ? (selected as Gender[]) : undefined,
            });
          }}
        />
        <FilterSelect
          label="Race"
          options={Object.keys(Race)}
          values={values.races}
          onChange={(selected) => {
            onChange({
              ...values,
              races: selected ? (selected as Race[]) : undefined,
            });
          }}
        />
        <FilterSelect
          label="Notability"
          options={Object.keys(CopDepartmentNotability)}
          values={values.notabilities}
          onChange={(selected) => {
            onChange({
              ...values,
              notabilities: selected
                ? (selected as CopDepartmentNotability[])
                : undefined,
            });
          }}
        />
        <FilterSelect
          label="Visible tattoos"
          options={['Yes', 'No']}
          values={
            values.visibleTattoos === true
              ? ['Yes']
              : values.visibleTattoos === false
              ? ['No']
              : undefined
          }
          singleSelection
          onChange={(selected) => {
            onChange({
              ...values,
              visibleTattoos: selected?.includes('Yes')
                ? true
                : selected?.includes('No')
                ? false
                : undefined,
            });
          }}
        />
      </EuiFilterGroup>
      <EuiSpacer size="s" />
      <Flex gap={8}>
        <EuiComboBox
          fullWidth
          prepend={
            <EuiIconTip
              content="Type in all of or part of a badge number and press enter"
              position="right"
              color={values.badgeNumber ? 'accent' : 'default'}
            />
          }
          aria-label="Badge number filter"
          placeholder="Badge number"
          noSuggestions
          singleSelection={{ asPlainText: true }}
          selectedOptions={
            values.badgeNumber
              ? [
                  {
                    label: values.badgeNumber.toUpperCase(),
                    key: values.badgeNumber,
                    value: values.badgeNumber,
                  },
                ]
              : undefined
          }
          onChange={(_) => {
            // This is only used to clear when singleSelection is set
            const { badgeNumber, ...rest } = values;
            onChange(rest);
          }}
          onCreateOption={(badgeNumber) =>
            onChange({
              ...values,
              badgeNumber: badgeNumber ? badgeNumber : undefined,
            })
          }
          isClearable={true}
        />
        <CopPositionFilter
          positionRegex={values.position}
          onChange={(newPositionRegex) => {
            onChange({
              ...values,
              position: newPositionRegex ? newPositionRegex : undefined,
            });
          }}
        />
      </Flex>
    </Fragment>
  );
};
