import {
  MediaRelationType,
  Operation,
  UpsertMediaRelationDto,
} from '@unfrl/copdb-sdk';
import { useStores } from '../../hooks';
import { EuiPanel, EuiToolTip, EuiIcon, EuiText } from '@elastic/eui';
import { mapResponseToDetailItem } from '../../utils';
import { Flex } from '../common';
import { CopDetailItem } from '../cop';
import { CSSProperties } from 'react';

export interface MediaEditCopDiffProps {
  mediaRelationDtos: UpsertMediaRelationDto[];
}

export const MediaEditCopDiff = (props: MediaEditCopDiffProps) => {
  const { copStore } = useStores();

  const { mediaRelationDtos } = props;

  const copRelationDtos = mediaRelationDtos.filter(
    (mrd) => mrd.type === MediaRelationType.Person,
  );

  if (!copRelationDtos.length) {
    return null;
  }

  const copPanelStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 90,
    minWidth: 250,
  };

  const elementResults: React.ReactElement[] = [];

  copRelationDtos.forEach((mrd) => {
    const cop = copStore.data.orderedItems.find((c) => c.personId === mrd.id);
    if (!cop) {
      return;
    }

    elementResults.push(
      <EuiPanel
        grow={false}
        color={mrd.operation === Operation.Upsert ? 'success' : 'danger'}
        paddingSize="s"
        key={cop.id}
        style={copPanelStyle}
      >
        <EuiToolTip
          title={mrd.operation === Operation.Upsert ? 'Added' : 'Removed'}
        >
          <Flex align={'center'} style={{ height: 50 }} gap={8}>
            <EuiIcon
              type={mrd.operation === Operation.Upsert ? 'plus' : 'cross'}
            />
            <CopDetailItem {...mapResponseToDetailItem(cop)} />
          </Flex>
        </EuiToolTip>
      </EuiPanel>,
    );
  });

  return (
    <EuiPanel>
      <EuiText color="subdued">{'Cops'}:</EuiText>
      <Flex wrap align={'center'} gap={8}>
        {elementResults}
      </Flex>
    </EuiPanel>
  );
};
