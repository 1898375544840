import {
  EuiAccordion,
  EuiListGroup,
  EuiListGroupItem,
  EuiText,
} from '@elastic/eui';
import { IncidentInformationDto } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { CSSProperties } from 'react';
import { useNavProps } from '../../hooks';
import { copProfilePath } from '../../utils';
import { Flex } from '../common';
import { CopDetailItem, CopDetailItemProps } from './cop-detail-item';
import { CopIncidentInfo } from './cop-incident-info';

export interface CopDetailListProps {
  cops: CopDetailItemProps[];
  bordered?: boolean;
  style?: CSSProperties;
  onDelete?: (index: number) => void;
  onEdit?: (index: number) => void;
  onEditIncidentInfo?: (
    index: number,
    incidentInfo: IncidentInformationDto,
  ) => void;
}

export const CopDetailList = observer((props: CopDetailListProps) => {
  const { cops, bordered, style, onDelete, onEdit, onEditIncidentInfo } = props;
  const getNavProps = useNavProps();

  if (!cops.length) {
    return (
      <EuiText color="subdued" size="relative">
        No cops recorded.
      </EuiText>
    );
  }

  return (
    <EuiListGroup
      flush
      maxWidth={false}
      style={{ margin: 0, ...style }}
      bordered={bordered}
    >
      {cops.map((cop, index) => {
        const navProps = cop.id
          ? getNavProps(copProfilePath(cop.id))
          : undefined;

        return (
          <div key={cop.id ?? `${cop.firstName} ${cop.lastName}`}>
            <EuiListGroupItem
              label={<CopDetailItem {...cop} />}
              extraAction={
                onDelete
                  ? {
                      'aria-label': 'Delete cop',
                      title: 'Delete cop',
                      color: 'danger',
                      iconType: 'trash',
                      alwaysShow: true,
                      size: 's',
                      onClick: () => onDelete(index),
                    }
                  : undefined
              }
              title={onEdit && !navProps ? 'Click to edit' : 'Click to view'}
              onClick={onEdit && !navProps ? () => onEdit(index) : undefined}
              {...navProps}
            />

            {(onEditIncidentInfo || cop.incidentInformation) && (
              <EuiListGroupItem
                label={
                  <EuiAccordion
                    id="cop-additional-info-accordion"
                    buttonContent={
                      <Flex gap={8}>
                        <EuiText>Incident info</EuiText>
                      </Flex>
                    }
                    initialIsOpen={Object.values(
                      cop.incidentInformation ?? {},
                    ).some((val) => !!val)}
                  >
                    <CopIncidentInfo
                      incidentInfo={cop.incidentInformation}
                      onEdit={
                        onEditIncidentInfo
                          ? (incidentInfo) =>
                              onEditIncidentInfo(index, incidentInfo)
                          : undefined
                      }
                    />
                  </EuiAccordion>
                }
              />
            )}
          </div>
        );
      })}
    </EuiListGroup>
  );
});
