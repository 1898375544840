import { CommentDto, UpsertCommentDto } from '@unfrl/copdb-sdk';
import { makeAutoObservable, runInAction } from 'mobx';
import { apiClient, rtmClient } from '../api';
import { DataStore } from './data.store';
import { RootStore } from './root.store';

export class ReportCommentStore {
  private readonly _root: RootStore;

  public data: DataStore<CommentDto> = new DataStore();

  public creating: boolean = false;

  public constructor(rootStore: RootStore) {
    this._root = rootStore;

    rtmClient.reports.comments.onAdded(this.handleCommentUpserted);
    rtmClient.reports.comments.onUpdated(this.handleCommentUpserted);
    rtmClient.reports.comments.onDeleted(this.handleCommentDeleted);

    makeAutoObservable(this);
  }

  public fetchComments = async (reportId: string): Promise<void> => {
    const comments = await apiClient.reports.listReportComments({ reportId });

    await this._root.userStore.fetchUserProfiles(
      comments.map((comment) => comment.createdBy),
    );

    this.data.setList(comments);
  };

  public createComment = async (
    reportId: string,
    dto: UpsertCommentDto,
  ): Promise<void> => {
    this.creating = true;

    try {
      const comment = await apiClient.reports.createReportComment({
        reportId,
        upsertCommentDto: dto,
      });

      await this.handleCommentUpserted(comment);
    } finally {
      runInAction(() => (this.creating = false));
    }
  };

  public updateComment = async (
    reportId: string,
    commentId: string,
    content: string,
  ): Promise<void> => {
    const comment = await apiClient.reports.updateReportComment({
      reportId,
      commentId,
      upsertCommentDto: {
        content,
      },
    });

    await this.handleCommentUpserted(comment);
  };

  public deleteComment = async (
    reportId: string,
    commentId: string,
  ): Promise<void> => {
    await apiClient.reports.deleteReportComment({ reportId, commentId });
  };

  //#region Actions

  private handleCommentUpserted = async (
    comment: CommentDto,
  ): Promise<void> => {
    this.data.setItem(comment);

    await this._root.userStore.fetchUserProfiles([comment.createdBy]);
  };

  private handleCommentDeleted = (commentId: string): void => {
    this.data.deleteItem(commentId);
  };

  //#endregion
}
