import { makeAutoObservable } from 'mobx';
import { ReactNode } from 'react';

export interface DialogOption {
  title: string;
  content: string | ReactNode;
  cancelText?: string;
  confirmText?: string;
  onConfirm: () => Promise<void> | void;
  isDelete?: boolean;
}

export class DialogStore {
  public dialogOption: DialogOption | null = null;

  public constructor() {
    makeAutoObservable(this);
  }

  public openDialog = (option: DialogOption) => {
    this.dialogOption = option;
  };

  public closeDialog = () => {
    this.dialogOption = null;
  };
}
