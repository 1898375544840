import { EuiText, EuiTextProps } from '@elastic/eui';

export interface MissingDataTextProps extends EuiTextProps {
  text?: string;
}

export const MissingDataText = ({ text, ...rest }: MissingDataTextProps) => {
  return (
    <EuiText color="subdued" size="relative" {...rest}>
      {text || 'Not recorded.'}
    </EuiText>
  );
};
