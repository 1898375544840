import { EuiLoadingSpinner } from '@elastic/eui';
import { CSSProperties } from 'react';

export const Spinner = ({ style }: { style?: CSSProperties }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', ...style }}>
      <EuiLoadingSpinner size="m" />
    </div>
  );
};
