import { observer } from 'mobx-react';
import { AuthRole } from '../../stores';
import RoleRequired, { RoleRequiredProps } from './role-required';

/**
 * Wraps routes/components using `RoleRequired` component with Administrators
 * and Moderators roles.
 */
const ModerationRequired = ({ ...rest }: Omit<RoleRequiredProps, 'roles'>) => {
  return (
    <RoleRequired
      roles={[AuthRole.Administrators, AuthRole.Moderators]}
      {...rest}
    />
  );
};

export default observer(ModerationRequired);
