import { CSSProperties, ComponentProps } from 'react';

type JustifyValues = CSSProperties['justifyContent'];

type AlignValues = CSSProperties['alignItems'];

export interface FlexProps extends ComponentProps<'div'> {
  auto?: boolean;
  column?: boolean;
  align?: AlignValues;
  justify?: JustifyValues;
  shrink?: boolean;
  reverse?: boolean;
  gap?: number;
  wrap?: boolean;
  children: any;
  style?: CSSProperties;
}

export const Flex = (props: FlexProps) => {
  const {
    auto,
    column,
    align,
    justify,
    shrink,
    reverse,
    gap,
    children,
    wrap,
    style,
    ...rest
  } = props;

  const flexStyle: CSSProperties = {
    display: 'flex',
    flex: auto ? '1 1 auto' : 'initial',
    flexDirection: `${column ? 'column' : 'row'}${reverse ? '-reverse' : ''}`,
    alignItems: align,
    justifyContent: justify,
    flexShrink: shrink ? 1 : 'initial',
    flexWrap: wrap ? `wrap${reverse ? '-reverse' : ''}` : undefined,
    gap: gap ? `${gap}px` : 'initial',
    ...style,
  };

  return (
    <div style={flexStyle} {...rest}>
      {children}
    </div>
  );
};
