import { AppStore } from './app.store';
import { AuthStore } from './auth.store';
import { CopEditStore } from './cop-edit.store';
import { CopStore } from './cop.store';
import { DepartmentStore } from './department.store';
import { DialogStore } from './dialog.store';
import { FeaturesStore } from './features.store';
import { IncidentEditStore } from './incident-edit.store';
import { IncidentStore } from './incident.store';
import { MappingStore } from './mapping.store';
import { MediaEditStore } from './media-edit.store';
import { MediaStore } from './media.store';
import { ReportCommentStore } from './report-comment.store';
import { ReportStore } from './report.store';
import { ToastStore } from './toast.store';
import { UserStore } from './user.store';

export class RootStore {
  public readonly appStore: AppStore;
  public readonly authStore: AuthStore;
  public readonly copStore: CopStore;
  public readonly copEditStore: CopEditStore;
  public readonly departmentStore: DepartmentStore;
  public readonly dialogStore: DialogStore;
  public readonly incidentStore: IncidentStore;
  public readonly incidentEditStore: IncidentEditStore;
  public readonly mediaEditStore: MediaEditStore;
  public readonly mediaStore: MediaStore;
  public readonly reportStore: ReportStore;
  public readonly reportCommentStore: ReportCommentStore;
  public readonly toastStore: ToastStore;
  public readonly userStore: UserStore;
  public readonly featuresStore: FeaturesStore;
  public readonly mappingStore: MappingStore;

  public constructor() {
    this.appStore = new AppStore();
    this.authStore = new AuthStore(this);
    this.copStore = new CopStore();
    this.copEditStore = new CopEditStore(this);
    this.departmentStore = new DepartmentStore();
    this.dialogStore = new DialogStore();
    this.incidentStore = new IncidentStore(this);
    this.incidentEditStore = new IncidentEditStore(this);
    this.mediaEditStore = new MediaEditStore(this);
    this.mediaStore = new MediaStore(this);
    this.reportStore = new ReportStore(this);
    this.reportCommentStore = new ReportCommentStore(this);
    this.toastStore = new ToastStore();
    this.userStore = new UserStore();
    this.featuresStore = new FeaturesStore();
    this.mappingStore = new MappingStore();
  }
}
