import { EuiSpacer } from '@elastic/eui';
import { observer } from 'mobx-react-lite';
import { ChangePasswordForm } from './change-password-form';
import { TwoFactorForm } from './two-factor-form';

export const SecuritySettings = observer(() => {
  return (
    <>
      <ChangePasswordForm />
      <EuiSpacer />
      <TwoFactorForm />
    </>
  );
});
