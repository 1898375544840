import { useEffect } from 'react';
import { unstable_usePrompt } from 'react-router-dom';

/**
 * When the condition is met, this will prompt with a browser confirmation
 * dialog when the user attempts to refresh the page OR if they try to navigate
 * away. This is useful for confirming that the user wants to navigate away when
 * they have unsaved changes.
 */
export const usePrompt = ({
  when,
  message = 'You have unsaved changes. Are you sure you want to navigate away?',
}: {
  when: boolean;
  message?: string;
}): void => {
  unstable_usePrompt({ when, message });

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
    };

    if (when) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [when]);
};
