import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTextColor,
} from '@elastic/eui';
import {
  CopDepartmentDto,
  CopDepartmentResponseDto,
  CopEdit,
  CopResponseDto,
  EditStatus,
  PersonMediaResponseDto,
  UpsertCopDto,
} from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { Fragment, useState } from 'react';
import { useStores } from '../../hooks';
import {
  camelToSentence,
  dateUtils,
  formatEditCitation,
  formatNameFromResponseDto,
  logger,
  mapResponseToUpsertDto,
} from '../../utils';
import { Flex, TextDiff } from '../common';
import { DepartmentEditDiff } from '../department';
import { MediaEditDiff } from '../media';
import { EditAction, EditActions } from '../moderate';
import { CopAvatar } from './cop-avatar';
import { CopEditForm } from './cop-edit-form';

export interface CopEditItemProps {
  cop: CopResponseDto;
  edit: CopEdit;
  existingPersonMedias: PersonMediaResponseDto[];
  existingCopDepartments: CopDepartmentResponseDto[];
}

export const CopEditItem = observer((props: CopEditItemProps) => {
  const { cop, edit, existingPersonMedias, existingCopDepartments } = props;
  const { copEditStore, dialogStore, toastStore, mediaStore, authStore } =
    useStores();
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);

  const disableApproveReason =
    !authStore.isAdmin && authStore.user?.id === edit.createdBy
      ? 'Non-Admin users cannot approve edits they created'
      : undefined;

  const { editDto } = edit;
  const originalDto = mapResponseToUpsertDto(cop);

  let editedPhotoId = '';

  const newProfilePhoto = edit.editDto.personMediaDtos?.find(
    (photo) => photo.profilePhoto,
  );
  const originalProfilePhoto = existingPersonMedias.find(
    (photo) => photo.profilePhoto,
  );
  if (
    newProfilePhoto?.mediaId &&
    newProfilePhoto?.mediaId !== originalProfilePhoto?.mediaId
  ) {
    editedPhotoId = newProfilePhoto.mediaId;
  }

  const handleUpdateStatus = async (status: EditStatus) => {
    try {
      setSaving(true);
      await copEditStore.updateEditStatus(edit.id, status);
    } catch (error: any) {
      logger.error('handleUpdateStatus', error);
      await toastStore.showApiError(error);
    } finally {
      setSaving(false);
    }
  };

  const handleOverwrite = async (dto: UpsertCopDto) => {
    try {
      setSaving(true);
      await copEditStore.overwriteCopEdit(cop.id, dto);
      handleToggleOverwrite();
    } catch (error: any) {
      logger.error('handleOverwrite', error);
      await toastStore.showApiError(error);
    } finally {
      setSaving(false);
    }
  };

  const handleToggleOverwrite = () => {
    setEditing(!editing);
  };

  const handleConfirmOverwrite = (dto: UpsertCopDto) => {
    dialogStore.openDialog({
      title: 'Overwrite this edit?',
      content:
        'This will apply your changes and remove all other remaining edits.',
      confirmText: 'Overwrite',
      onConfirm: () => handleOverwrite(dto),
    });
  };

  const handleAction = (action: EditAction) => {
    switch (action) {
      case EditAction.Approve:
        return handleUpdateStatus(EditStatus.Approved);
      case EditAction.Deny:
        return handleUpdateStatus(EditStatus.Denied);
      case EditAction.Overwrite:
        return handleToggleOverwrite();
    }
  };

  return (
    <EuiPanel hasShadow={false} color="subdued">
      <EuiFlexGroup gutterSize="s">
        <EuiFlexItem>
          <div>
            {editedPhotoId ? (
              <Fragment>
                <EuiText color="subdued">Profile Photo:</EuiText>

                <CopAvatar
                  size="s"
                  name={formatNameFromResponseDto(cop)}
                  photoUrl={mediaStore.data.getItem(editedPhotoId)?.url}
                />
              </Fragment>
            ) : null}
            <EuiSpacer />
            {Object.keys(originalDto).map((key) => {
              const objKey = key as keyof UpsertCopDto;

              if (objKey === 'copDepartmentDtos') {
                return (
                  <Fragment key={key}>
                    <EuiSpacer size="s" />
                    <EuiText color="subdued">Departments:</EuiText>
                    <DepartmentEditDiff
                      beforeDepartments={existingCopDepartments}
                      afterDepartments={editDto[objKey] as CopDepartmentDto[]}
                    />
                  </Fragment>
                );
              }

              if (objKey === 'personMediaDtos') {
                return (
                  <Fragment key={key}>
                    <EuiSpacer size="s" />
                    <EuiText color="subdued">Media:</EuiText>
                    <MediaEditDiff
                      upsertPersonMediaDtos={editDto.personMediaDtos ?? []}
                      existingPersonMedias={existingPersonMedias}
                    />
                  </Fragment>
                );
              }

              if (objKey === 'visibleTattoos') {
                let before = String(originalDto[objKey] || false);
                let after = String(editDto[objKey] || false);

                if (before === after) {
                  return null;
                }

                return before !== after ? (
                  <Flex wrap gap={8} align="center" key={objKey}>
                    <EuiText color="subdued">Visible tattoos:</EuiText>
                    <EuiTextColor color="danger">{before}</EuiTextColor>
                    <EuiTextColor color="success">{after}</EuiTextColor>
                  </Flex>
                ) : null;
              }

              const before = originalDto[objKey] as string;
              const after = editDto[objKey] as string;
              if (before === after) {
                return null;
              }

              const camelCaseValues: string[] = [
                'hairColor',
                'eyeColor',
                'gender',
                'race',
              ];
              if (camelCaseValues.includes(objKey)) {
                return (
                  <TextDiff
                    key={key}
                    beforeText={camelToSentence(before)}
                    afterText={camelToSentence(after)}
                    label={camelToSentence(key)}
                  />
                );
              }

              return (
                <TextDiff
                  key={key}
                  beforeText={before}
                  afterText={after}
                  label={camelToSentence(key)}
                />
              );
            })}
          </div>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="s" />
      <EuiHorizontalRule margin="s" />
      <Flex justify="space-between" align="center">
        <EuiText
          color="subdued"
          size="s"
          title={dateUtils.format(edit.createdAt)}
        >
          {formatEditCitation(edit)}
        </EuiText>
        <EditActions
          onAction={handleAction}
          disableApproveReason={disableApproveReason}
        />
      </Flex>
      {editing && (
        <CopEditForm
          title="Overwrite edit"
          copDto={editDto}
          onClose={handleToggleOverwrite}
          onSave={handleConfirmOverwrite}
          saving={saving}
        />
      )}
    </EuiPanel>
  );
});
