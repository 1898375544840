import {
  EuiBadge,
  EuiCheckbox,
  EuiDescriptionList,
  EuiDescriptionListTitle,
  EuiIconTip,
  EuiMarkdownFormat,
  EuiPanel,
  EuiSpacer,
  EuiText,
} from '@elastic/eui';
import { observer } from 'mobx-react';
import { Flex } from '../common';

export interface IncidentPreviewProps {
  summary?: string | null;
  preliminary?: boolean | null;
  tags?: string[] | null;
  description?: string | null;
  dateOccurred?: Date | null;
}

export const IncidentPreview = observer(
  ({
    summary,
    preliminary,
    tags,
    description,
    dateOccurred,
  }: IncidentPreviewProps) => {
    if (!summary && !dateOccurred && !description) {
      return (
        <EuiText color="subdued" size="relative">
          No incident recorded.
        </EuiText>
      );
    }

    return (
      <EuiDescriptionList compressed>
        <Flex gap={8} align="center">
          <EuiCheckbox
            id={'preliminary'}
            label={'Preliminary'}
            checked={preliminary || false}
            onChange={(e) => {}}
            disabled
          />
          <EuiIconTip
            content="Setting this will inform viewers that the Incident is incomplete and needs more information."
            position="right"
          />
        </Flex>
        <EuiSpacer size="m" />

        <EuiDescriptionListTitle>Summary</EuiDescriptionListTitle>
        <EuiPanel color="transparent" paddingSize="s">
          {summary ? (
            <EuiText>{summary}</EuiText>
          ) : (
            <EuiText color="subdued" size="relative">
              Summary not provided.
            </EuiText>
          )}
        </EuiPanel>

        <EuiDescriptionListTitle>Tags</EuiDescriptionListTitle>
        <EuiPanel color="transparent" paddingSize="s">
          {tags?.length ? (
            <Flex gap={4}>
              {tags.map((tag) => (
                <Flex key={tag}>
                  <EuiBadge color="">{tag}</EuiBadge>
                </Flex>
              ))}
            </Flex>
          ) : (
            <EuiText color="subdued" size="relative">
              No tags set.
            </EuiText>
          )}
        </EuiPanel>

        <EuiDescriptionListTitle>Description</EuiDescriptionListTitle>
        <EuiPanel color="transparent" paddingSize="s">
          {description ? (
            <EuiMarkdownFormat>{description}</EuiMarkdownFormat>
          ) : (
            <EuiText color="subdued" size="relative">
              Description not provided.
            </EuiText>
          )}
        </EuiPanel>
      </EuiDescriptionList>
    );
  },
);
