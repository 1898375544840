import {
  EuiButton,
  EuiButtonEmpty,
  EuiMarkdownEditor,
  getDefaultEuiMarkdownPlugins,
} from '@elastic/eui';
import { observer } from 'mobx-react';
import { FormEvent, useState } from 'react';
import { Flex } from '../common';

const { parsingPlugins, processingPlugins, uiPlugins } =
  getDefaultEuiMarkdownPlugins({ exclude: ['tooltip'] });

export interface CommentEditorProps {
  onSubmitComment: (value: string) => Promise<void>;
  onCancel?: () => void;
  initialValue?: string;
  submitText?: string;
}

export const CommentEditor = observer((props: CommentEditorProps) => {
  const { onSubmitComment, onCancel, initialValue, submitText } = props;
  const [value, setValue] = useState(initialValue ?? '');
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!value?.trim()) {
      return;
    }

    try {
      setSubmitting(true);

      await onSubmitComment(value);

      setValue('');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{ display: 'flex', alignItems: 'flex-start' }}
    >
      <div style={{ flexGrow: 1 }}>
        <EuiMarkdownEditor
          aria-label="Add a comment"
          placeholder="Add a comment"
          value={value}
          onChange={(value: string) => setValue(value)}
          height={150}
          initialViewMode="editing"
          readOnly={submitting}
          parsingPluginList={parsingPlugins}
          processingPluginList={processingPlugins}
          uiPlugins={uiPlugins}
        />
        <Flex
          justify="flex-end"
          gap={16}
          style={{ marginTop: 16, marginBottom: 16 }}
        >
          {onCancel ? (
            <EuiButtonEmpty size="s" color="text" onClick={onCancel}>
              Cancel
            </EuiButtonEmpty>
          ) : null}
          <EuiButton
            fill
            disabled={!value}
            size="s"
            type="submit"
            isLoading={submitting}
            color="success"
          >
            {submitText ?? 'Comment'}
          </EuiButton>
        </Flex>
      </div>
    </form>
  );
});
