import { EuiIcon, EuiText } from '@elastic/eui';
import {
  hasDigit,
  hasLowercaseCharacter,
  hasNonAlphanumeric,
  hasUppercaseCharacter,
  hasValidLength,
} from '../../utils';
import { Flex } from '../common';

export const PasswordRules = ({ password }: { password: string }) => {
  if (!password) {
    return null;
  }

  const rules = [
    { name: '6 characters minimum', validate: hasValidLength },
    { name: 'One uppercase character', validate: hasUppercaseCharacter },
    { name: 'One lowercase character', validate: hasLowercaseCharacter },
    { name: 'One digit', validate: hasDigit },
    { name: 'One non-alphanumeric character', validate: hasNonAlphanumeric },
  ];

  return (
    <Flex column gap={4}>
      {rules.map((rule) => (
        <Flex gap={4} key={rule.name}>
          <EuiIcon
            type="checkInCircleFilled"
            color={rule.validate(password) ? 'success' : 'subdued'}
          />
          <EuiText size="s">{rule.name}</EuiText>
        </Flex>
      ))}
    </Flex>
  );
};
