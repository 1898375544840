import { EuiBottomBar, useEuiTheme } from '@elastic/eui';
import { CSSProperties, ReactNode } from 'react';

export interface BottomBarProps {
  children: ReactNode;
  style?: CSSProperties;
}

export const BottomBar = (props: BottomBarProps) => {
  const { children, style } = props;
  const { euiTheme } = useEuiTheme();

  return (
    <EuiBottomBar style={{ borderTop: euiTheme.border.thin }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: 16,
          ...style,
        }}
      >
        {children}
      </div>
    </EuiBottomBar>
  );
};
