import { EuiComboBox, EuiComboBoxOptionOption } from '@elastic/eui';
import { observer } from 'mobx-react';
import { useState } from 'react';

export interface CopPositionFilterProps {
  positionRegex?: string;
  onChange: (positionRegex: string | undefined) => void;
}

export const CopPositionFilter = observer((props: CopPositionFilterProps) => {
  const { positionRegex, onChange } = props;

  const staticOptions = [
    { label: 'Officer', color: 'accent' },
    { label: 'Sergeant', color: 'accent' },
    { label: 'Deputy', color: 'accent' },
    { label: 'Corrections', color: 'accent' },
    { label: 'Lieutenant', color: 'accent' },
    { label: 'Captain', color: 'accent' },
    { label: 'Chief', color: 'accent' },
  ];

  const parsedOptions = positionRegex?.split('|').map((clause) => {
    const staticOptionIndex = staticOptions.findIndex(
      (staticOption) => staticOption.label.toLowerCase() === clause,
    );
    if (staticOptionIndex !== -1) {
      return staticOptions[staticOptionIndex];
    }
    return { label: clause, color: 'accent' };
  });

  const [options, setOptions] = useState(staticOptions);

  const onCreateOption = (
    searchValue: string,
    flattenedOptions: EuiComboBoxOptionOption<any>[],
  ): void => {
    const normalizedSearchValue = searchValue.trim().toLowerCase();

    if (!normalizedSearchValue) {
      return;
    }

    const newOption = {
      label: searchValue,
      color: 'accent',
    };

    // Create the option if it doesn't exist.
    if (
      flattenedOptions.findIndex(
        (option) => option.label.trim().toLowerCase() === normalizedSearchValue,
      ) === -1
    ) {
      setOptions([...options, newOption]);
    }
    onChange(positionRegex ? `${positionRegex}|${searchValue}` : searchValue);
  };

  return (
    <EuiComboBox
      fullWidth
      aria-label="Position filter"
      placeholder="Select or type a position"
      options={options}
      selectedOptions={parsedOptions}
      onChange={(selectedOptions) => {
        onChange(
          selectedOptions
            ? selectedOptions.map((option) => option.label).join('|')
            : undefined,
        );
      }}
      onCreateOption={onCreateOption}
      isClearable={true}
    />
  );
});
