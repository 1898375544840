import { formatDate } from '@elastic/eui';
import moment from 'moment';

const format = (date: Date, option?: string) => {
  return formatDate(date, option ?? 'MMM D, YYYY - LT');
};

const fromNow = (date: Date) => {
  return moment(date).fromNow();
};

const toMoment = (date?: Date | null) => {
  return date ? moment(date) : null;
};

const hasValue = (date?: Date | null) => {
  return moment.isDate(date) && !moment(date).isSame('0001-01-01T00:00:00');
};

/**
 * Simple wrapper for moment to keep consistent styling and only the necessary
 * functionality.
 */
export const dateUtils = {
  format,
  fromNow,
  toMoment,
  hasValue,
};
