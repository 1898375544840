const REQUEST_HEADERS = {
  CONTENT: 'Content-Type',
  AMZ_ACL: 'x-amz-acl',
};

export interface UploadFileOptions {
  uploadUrl: string;
  onProgress?: (percentage: number) => void;
  /**
   * Provide this callback to receive the abort function to cancel the request.
   */
  onStart?: (abort: () => void) => void;
}

export const FileSizes = {
  oneKb: 1000,
  oneMb: 1000 * 1000,
  oneGb: 1000 * 1000 * 1000,
};

export const uploadFile = async (
  file: File,
  options: UploadFileOptions,
): Promise<boolean> => {
  const { uploadUrl, onProgress, onStart } = options;

  return await new Promise<boolean>((resolve, reject) => {
    const request = new XMLHttpRequest();

    request.upload.onprogress = (e) => {
      if (onProgress && e.lengthComputable) {
        onProgress(Math.floor((e.loaded / e.total) * 100));
      }
    };

    request.onerror = () => {
      const message = `${request.status} ${request.statusText}`;
      reject(new Error(message));
    };

    request.onabort = () => {
      reject(new Error('Request cancelled'));
    };

    request.onloadend = () => {
      resolve(request.status >= 200 && request.status < 400);
    };

    request.open('PUT', uploadUrl);
    request.setRequestHeader(REQUEST_HEADERS.CONTENT, file.type);
    request.setRequestHeader(REQUEST_HEADERS.AMZ_ACL, 'public-read');
    request.send(file);

    if (onStart) {
      onStart(() => request.abort());
    }
  });
};

export const fileUtils = {
  uploadFile,
};
