import { latLng, LatLng } from 'leaflet';
import { makeAutoObservable } from 'mobx';

const LAST_KNOWN_LOCATION = 'LAST_KNOWN_LOCATION';
const LAST_KNOWN_VIEW = 'LAST_KNOWN_VIEW';
const LAST_ZOOM = 'LAST_ZOOM';

export class MappingStore {
  private _locationEnabled: boolean = false;

  public get locationEnabled(): boolean {
    return this._locationEnabled;
  }

  private _locating: boolean = false;

  public get locating(): boolean {
    return this._locating;
  }

  public get zoom(): number {
    try {
      const parsedZoom = Number(
        JSON.parse(localStorage.getItem(LAST_ZOOM) ?? '9'),
      );
      return parsedZoom;
    } catch {
      localStorage.removeItem(LAST_ZOOM);
      return 6;
    }
  }

  private set zoom(newZoom: number) {
    localStorage.setItem(LAST_ZOOM, newZoom.toString());
  }

  public get lastKnownView(): LatLng | undefined {
    const lsResult = localStorage.getItem(LAST_KNOWN_VIEW);
    if (lsResult) {
      try {
        return JSON.parse(lsResult) as LatLng;
      } catch {
        this.lastKnownView = undefined;
        return undefined;
      }
    }
    return undefined;
  }

  private set lastKnownView(view: LatLng | undefined) {
    if (view) {
      localStorage.setItem(LAST_KNOWN_VIEW, JSON.stringify(view));
    } else {
      localStorage.removeItem(LAST_KNOWN_VIEW);
    }
  }

  public get lastKnownLocation(): LatLng | undefined {
    const lsResult = localStorage.getItem(LAST_KNOWN_LOCATION);
    if (lsResult) {
      var result = JSON.parse(lsResult) as LatLng;
      return result.lat && result.lng ? result : undefined; // Check for the correct fields so we only use the current schema
    }
    return undefined;
  }

  private set lastKnownLocation(location: LatLng | undefined) {
    if (location) {
      localStorage.setItem(LAST_KNOWN_LOCATION, JSON.stringify(location));
    } else {
      localStorage.removeItem(LAST_KNOWN_LOCATION);
    }
  }

  public get positionLatLng(): LatLng {
    return this.lastKnownView ?? this.lastKnownLocation ?? this.defaultCenter;
  }

  public persistView(zoom: number, center: LatLng) {
    this.zoom = zoom;
    this.lastKnownView = center;
  }

  // Salt Lake City
  public readonly defaultCenter = latLng(40.7587, -111.8761);

  private watcher: number | undefined;

  public constructor() {
    makeAutoObservable(this);
  }

  public startLocating = () => {
    this._locating = true;

    if (!this.locationEnabled) {
      this.enableLocation();
      return;
    }
  };

  public stopLocating = () => {
    this._locating = false;
  };

  private getGeoLocation = (): Geolocation => {
    this.lastKnownView = undefined;
    const geolocation = navigator.geolocation;
    if (!geolocation) {
      const error = 'Geolocation is not supported';
      console.error(error);
      throw new Error(error);
    }
    if (this.watcher !== undefined) {
      // Old watchers should be cleared
      geolocation.clearWatch(this.watcher);
    }
    return geolocation;
  };

  public enableLocation = () => {
    this.lastKnownView = undefined;

    const geolocation = this.getGeoLocation();
    geolocation.getCurrentPosition(this.onChange, this.onError);
    this.watcher = geolocation.watchPosition(this.onChange, this.onError);
    this._locationEnabled = true;
  };

  public disableLocation = () => {
    // Getting a new Geolocation invalidates any old watchers
    this.getGeoLocation();
    this._locationEnabled = false;
  };

  private onChange = (position: GeolocationPosition) => {
    const { coords } = position;
    if (this.locating) {
      this.lastKnownView = undefined;
      this.lastKnownLocation = latLng(coords.latitude, coords.longitude);
    }
  };

  private onError = (positionError: GeolocationPositionError) => {
    console.error(positionError.message);
    this.disableLocation();
  };
}
