const MIN_LENGTH = 6;

/**
 * Contain an uppercase character, lowercase character, a digit, a
 * non-alphanumeric character, and is at least 6 characters long.
 */
const PASSWORD_REGEX =
  /^.*(?=.{6,})(?=.*[a-zA-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s:]).*$/;

export const isValidPassword = (value: string) => {
  return PASSWORD_REGEX.test(value);
};

export const hasValidLength = (value: string) => {
  return value.length >= MIN_LENGTH;
};

export const hasUppercaseCharacter = (value: string) => {
  return /[A-Z]/.test(value);
};

export const hasLowercaseCharacter = (value: string) => {
  return /[a-z]/.test(value);
};

export const hasDigit = (value: string) => {
  return /\d/.test(value);
};

export const hasNonAlphanumeric = (value: string) => {
  return /[^a-zA-Z\d\s:]/.test(value);
};
