import { CopDepartmentDto, CopDepartmentResponseDto } from '@unfrl/copdb-sdk';
import { last, sortBy } from 'lodash';

type CopDepartmentLike = {
  startDate?: Date | null;
  endDate?: Date | null;
  departmentId: string;
};

export const getMostRecentDepartment = <T extends CopDepartmentLike>(
  copDepartments: T[],
  departmentIdFilter?: string,
): T | undefined => {
  if (!copDepartments?.length) {
    return;
  }

  let relevantDepartments = departmentIdFilter
    ? copDepartments.filter((dep) => dep.departmentId === departmentIdFilter)
    : copDepartments;

  if (!relevantDepartments.length) {
    return;
  }

  const currentDepartments = relevantDepartments.filter((dep) => !dep.endDate);
  if (currentDepartments.length) {
    return currentDepartments[0];
  }

  const mostRecentDepartments = sortBy(
    relevantDepartments.filter((dep) => dep.startDate),
    (dep) => dep.startDate,
  );

  return mostRecentDepartments.length
    ? last(mostRecentDepartments)
    : relevantDepartments[0];
};

export const getDepartmentStatusText = (
  copDepartments: CopDepartmentResponseDto[],
  departmentIdFilter?: string,
): string | null => {
  const mostRecentDepartment = getMostRecentDepartment(
    copDepartments,
    departmentIdFilter,
  );

  if (!mostRecentDepartment) {
    return null;
  }

  return (
    mostRecentDepartment.name +
    (mostRecentDepartment.endDate ? ' (Previously)' : '')
  );
};

export const getPositionText = (
  copDepartments: CopDepartmentDto[] | null | undefined,
  departmentIdFilter?: string,
): string | null => {
  const mostRecentDepartment = getMostRecentDepartment(
    copDepartments ?? [],
    departmentIdFilter,
  );

  if (!mostRecentDepartment?.position) {
    return null;
  }

  return (
    mostRecentDepartment.position +
    (mostRecentDepartment.endDate ? ' (Previously)' : '')
  );
};

export const getBadgeNumberText = (
  copDepartments: CopDepartmentDto[] | null | undefined,
  departmentIdFilter?: string,
): string | null => {
  const mostRecentDepartment = getMostRecentDepartment(
    copDepartments ?? [],
    departmentIdFilter,
  );

  if (!mostRecentDepartment?.badgeNumber) {
    return null;
  }

  return (
    mostRecentDepartment.badgeNumber +
    (mostRecentDepartment.endDate ? ' (Previously)' : '')
  );
};
