import { EuiButtonEmpty } from '@elastic/eui';
import { CSSProperties } from 'react';
import { useNavProps } from '../../hooks';

export interface NavBackLinkProps {
  to: string;
  title: string;
  style?: CSSProperties;
}

export const NavBackLink = (props: NavBackLinkProps) => {
  const { to, title, style } = props;
  const getNavProps = useNavProps();

  return (
    <EuiButtonEmpty
      size="s"
      iconType="sortLeft"
      color="primary"
      style={{ marginLeft: -8, marginTop: -8, ...style }}
      {...getNavProps(to)}
    >
      {title}
    </EuiButtonEmpty>
  );
};
