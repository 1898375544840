import {
  EuiBadge,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButtonIcon,
  EuiDescriptionList,
  EuiFieldNumber,
  EuiMarkdownFormat,
  EuiScreenReaderOnly,
  EuiText,
} from '@elastic/eui';
import {
  CopDepartmentDto,
  IncidentInformationDto,
  UpsertCopDto,
} from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { ReactNode, useEffect, useState } from 'react';
import { useStores } from '../../hooks';
import {
  camelToSentence,
  formatNameFromUpsertDto,
  getMostRecentDepartment,
  getProfilePhotoFromUpsert,
} from '../../utils';
import { Flex, LabelOrMissing, MissingDataText } from '../common';
import { CopAvatar } from '../cop';

const NewCopAvatar = observer(({ cop }: { cop: UpsertCopDto }) => {
  const name = formatNameFromUpsertDto(cop);
  return (
    <Flex align="center" gap={12}>
      <CopAvatar
        name={name}
        photoUrl={getProfilePhotoFromUpsert(cop)}
        size={64}
      />
      <EuiText size="relative">{name}</EuiText>
    </Flex>
  );
});

const NewCopDetails = observer(({ cop }: { cop: UpsertCopDto }) => {
  const { departmentStore } = useStores();
  return (
    <EuiDescriptionList
      style={{ padding: 8 }}
      rowGutterSize="m"
      listItems={[
        {
          title: 'Description',
          description: (
            <EuiMarkdownFormat textSize="relative">
              {cop.description ?? 'N/A'}
            </EuiMarkdownFormat>
          ),
        },
        {
          title: 'Demographic information',
          description: (
            <Flex column>
              <LabelOrMissing
                name="Eye color"
                value={cop.eyeColor}
                size="relative"
                type="Text"
              />
              <LabelOrMissing
                name="Hair color"
                value={cop.hairColor}
                size="relative"
                type="Text"
              />
              <LabelOrMissing
                name="Gender"
                value={cop.gender}
                size="relative"
                type="Text"
              />
              <LabelOrMissing
                name="Race"
                value={cop.race}
                size="relative"
                type="Text"
              />
              <LabelOrMissing
                name="Visible tattoos"
                value={
                  cop.visibleTattoos != null
                    ? cop.visibleTattoos
                      ? 'Yes'
                      : 'No'
                    : undefined
                }
                size="relative"
                type="Text"
              />
            </Flex>
          ),
        },
        {
          title: 'Department history',
          description: (
            <EuiBasicTable
              columns={[
                {
                  name: 'Name',
                  render: (department: CopDepartmentDto) =>
                    departmentStore.data.getItem(department.departmentId)?.name,
                },
                {
                  name: 'Start date',
                  field: 'startDate',
                  render: (startDate?: Date) =>
                    startDate
                      ? new Date(startDate).toLocaleDateString()
                      : 'N/A',
                },
                {
                  name: 'End date',
                  field: 'endDate',
                  render: (endDate?: Date) =>
                    endDate ? new Date(endDate).toLocaleDateString() : 'N/A',
                },
                {
                  name: 'Position',
                  field: 'position',
                  render: (position?: string) => position ?? 'N/A',
                },
                {
                  name: 'Badge number',
                  field: 'badgeNumber',
                  render: (badgeNumber?: string) => badgeNumber ?? 'N/A',
                },
                {
                  name: 'Notabilities',
                  render: (department: CopDepartmentDto) => {
                    if (!department.notabilities?.length) {
                      return 'N/A';
                    }

                    return (
                      <Flex wrap gap={4}>
                        {department.notabilities.map((notability) => (
                          <Flex key={notability}>
                            <EuiBadge>{camelToSentence(notability)}</EuiBadge>
                          </Flex>
                        ))}
                      </Flex>
                    );
                  },
                },
              ]}
              items={cop.copDepartmentDtos ?? []}
            />
          ),
        },
      ]}
    />
  );
});

type CopDtoWithIndex = { index: number } & UpsertCopDto;

export interface NewCopsTableProps {
  /**
   * List of new cops to render in a table.
   */
  cops: UpsertCopDto[];
  /**
   * Optional edit actions. It not provided, table will be rendered in a
   * readonly mode.
   */
  editActions?: {
    onDelete: (index: number) => void;
    onEdit: (index: number) => void;
    onEditIncidentInfo: (
      index: number,
      incidentInfo: IncidentInformationDto,
    ) => void;
  };
}

export const NewCopsTable = observer((props: NewCopsTableProps) => {
  const { cops, editActions } = props;
  const { departmentStore } = useStores();
  const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState<
    Record<string, ReactNode>
  >({});

  if (!cops.length) {
    return (
      <EuiText color="subdued" size="relative">
        No cops recorded.
      </EuiText>
    );
  }

  useEffect(() => {
    const departmentIds = new Set<string>();
    cops.forEach((cop) => {
      if (cop.copDepartmentDtos) {
        cop.copDepartmentDtos.forEach((copDepartment) =>
          departmentIds.add(copDepartment.departmentId),
        );
      }
    });

    departmentStore.fetchDepartments([...departmentIds.values()]);
  }, [cops, departmentStore]);

  const handleToggleCopDetails = (cop: CopDtoWithIndex) => {
    const itemIdToExpandedRowMapValues = { ...itemIdToExpandedRowMap };

    if (itemIdToExpandedRowMapValues[cop.index]) {
      delete itemIdToExpandedRowMapValues[cop.index];
    } else {
      itemIdToExpandedRowMapValues[cop.index] = <NewCopDetails cop={cop} />;
    }

    setItemIdToExpandedRowMap(itemIdToExpandedRowMapValues);
  };

  // we map the cop dto with its index for edit and delete operations since we
  // don't have an ID to reference
  const copDtosWithIndex: CopDtoWithIndex[] = cops.map((cop, index) => ({
    index,
    ...cop,
  }));

  const columns: EuiBasicTableColumn<CopDtoWithIndex>[] = [
    {
      name: 'Name',
      render: (cop: UpsertCopDto) => <NewCopAvatar cop={cop} />,
      width: '250px',
    },
    {
      name: 'Department',
      render: (cop: UpsertCopDto) => {
        const current = getMostRecentDepartment(cop.copDepartmentDtos ?? []);
        if (!current) {
          return <MissingDataText text="N/A" />;
        }

        return departmentStore.data.getItem(current.departmentId)?.name;
      },
    },
    {
      name: 'Position',
      render: (cop: UpsertCopDto) => {
        const current = getMostRecentDepartment(cop.copDepartmentDtos ?? []);
        return current?.position ?? <MissingDataText text="N/A" />;
      },
    },
    {
      name: 'Badge number',
      render: (cop: UpsertCopDto) => {
        const current = getMostRecentDepartment(cop.copDepartmentDtos ?? []);
        return current?.badgeNumber ?? <MissingDataText text="N/A" />;
      },
    },
    {
      name: 'Shots fired',
      width: '120px',
      render: (cop: CopDtoWithIndex) => {
        if (!editActions) {
          return (
            cop.incidentInformation?.shotsFired ?? (
              <MissingDataText text="N/A" />
            )
          );
        }

        return (
          <EuiFieldNumber
            aria-label="Enter the number of shots fired"
            value={cop.incidentInformation?.shotsFired ?? ''}
            min={0}
            onChange={(e) => {
              editActions.onEditIncidentInfo(cop.index, {
                ...cop.incidentInformation,
                shotsFired: e.target.value
                  ? Number.parseInt(e.target.value)
                  : undefined,
              });
            }}
          />
        );
      },
    },
  ];

  if (editActions) {
    columns.push({
      name: 'Actions',
      actions: [
        {
          name: 'Edit',
          description: 'Edit cop details',
          type: 'icon',
          icon: 'pencil',
          onClick: (copDto: CopDtoWithIndex) => {
            editActions.onEdit(copDto.index);
          },
        },
        {
          name: 'Delete',
          description: 'Delete new cop',
          type: 'icon',
          icon: 'trash',
          color: 'danger',
          onClick: (copDto: CopDtoWithIndex) => {
            editActions.onDelete(copDto.index);
          },
        },
      ],
    });
  }

  return (
    <EuiBasicTable
      columns={[
        ...columns,
        {
          align: 'right',
          width: '40px',
          isExpander: true,
          name: (
            <EuiScreenReaderOnly>
              <span>Expand row</span>
            </EuiScreenReaderOnly>
          ),
          mobileOptions: { header: false },
          render: (cop: CopDtoWithIndex) => {
            return (
              <EuiButtonIcon
                onClick={() => handleToggleCopDetails(cop)}
                aria-label={
                  itemIdToExpandedRowMap[cop.index] ? 'Collapse' : 'Expand'
                }
                iconType={
                  itemIdToExpandedRowMap[cop.index] ? 'arrowDown' : 'arrowRight'
                }
              />
            );
          },
        },
      ]}
      items={copDtosWithIndex}
      itemIdToExpandedRowMap={itemIdToExpandedRowMap}
      itemId="index"
    />
  );
});
