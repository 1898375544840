import { EuiIcon, EuiLink, EuiText, EuiTextProps } from '@elastic/eui';
import { observer } from 'mobx-react';
import { Flex } from '../common';
import { LocationDto } from '@unfrl/copdb-sdk';

export interface LocationPlaceLinkProps extends EuiTextProps {
  location?: LocationDto;
  withIcon?: boolean;
}

export const LocationPlaceLink = observer((props: LocationPlaceLinkProps) => {
  const { location, withIcon, ...rest } = props;

  const placeLink = location ? (
    <EuiText {...rest}>
      <EuiLink
        href={`https://maps.google.com/maps/?q=${location.coordinates.centerLatitude},${location.coordinates.centerLongitude}`}
        target="_blank"
        rel="noreferrer"
      >
        {location.placeName}
        {location.place ? ` (${location.place})` : null}
      </EuiLink>
    </EuiText>
  ) : (
    <EuiText color="subdued" size="relative" {...rest}>
      No location recorded.
    </EuiText>
  );

  if (!withIcon) {
    return placeLink;
  }

  return (
    <Flex align="center">
      <EuiIcon title="Location" type="pin" style={{ marginRight: 4 }} />
      {placeLink}
    </Flex>
  );
});
