import { EuiText } from '@elastic/eui';
import { CommentDto } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { dateUtils } from '../../utils';
import { Flex } from '../common';

export interface CommentTimestampProps {
  comment: CommentDto;
}

export const CommentTimestamp = observer((props: CommentTimestampProps) => {
  const { comment } = props;

  return (
    <Flex align="center">
      <EuiText
        title={`created: ${dateUtils.format(comment.createdAt)}`}
        size="relative"
        color="subdued"
      >
        - {dateUtils.fromNow(comment.createdAt)}
      </EuiText>
      {comment.updatedAt > comment.createdAt ? (
        <EuiText
          title={`edited: ${dateUtils.format(comment.updatedAt)}`}
          size="relative"
          color="subdued"
          style={{ marginLeft: 4 }}
        >
          (edited)
        </EuiText>
      ) : null}
    </Flex>
  );
});
