import { EuiPanel, EuiSpacer } from '@elastic/eui';
import { Media } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { Fragment } from 'react';
import { Flex } from '../../common';
import { MediaLink } from './media-link';

export interface AudioPreviewProps {
  media: Media;
  displayMediaDetailsLink?: boolean;
}

export const AudioPreview = observer((props: AudioPreviewProps) => {
  const { media, displayMediaDetailsLink } = props;
  return (
    <Fragment>
      <EuiPanel
        hasShadow={false}
        color="transparent"
        paddingSize="none"
        grow={false}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <audio
          src={media.url ?? undefined}
          title={media.name || media.objectKey}
          controls={true}
        />
      </EuiPanel>
      <EuiSpacer size="s" />
      <Flex justify="center">
        <MediaLink
          media={media}
          displayMediaDetailsLink={displayMediaDetailsLink}
        />
      </Flex>
    </Fragment>
  );
});
