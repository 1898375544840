import { EuiIcon, EuiPanel } from '@elastic/eui';
import { Media } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { Flex } from '../../common';
import { MediaLink } from './media-link';

export interface DocumentPreviewProps {
  media: Media;
  displayMediaDetailsLink?: boolean;
}

export const DocumentPreview = observer((props: DocumentPreviewProps) => {
  const { media, displayMediaDetailsLink } = props;
  return (
    <EuiPanel hasBorder color="transparent" paddingSize="s" grow={false}>
      <Flex gap={8}>
        <EuiIcon type="document" size="l" />
        <MediaLink
          media={media}
          displayMediaDetailsLink={displayMediaDetailsLink}
        />
      </Flex>
    </EuiPanel>
  );
});
