import { IncidentResponseDto, UpsertIncidentDto } from '@unfrl/copdb-sdk';
import { EditIncidentViewModel } from '../models';

export const mapIncidentToUpsertDto = (
  incident: IncidentResponseDto | EditIncidentViewModel,
): UpsertIncidentDto => {
  return {
    dateOccurred: incident.dateOccurred,
    description: incident.description,
    summary: incident.summary,
    location: incident.location,
    personIncidentAssociations: incident.personIncidentAssociations,
    mediaAssociations: incident.mediaAssociations,
    preliminary: incident.preliminary,
    tags: incident.tags,
  };
};
