import {
  EuiButton,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiPopover,
  EuiPopoverFooter,
  EuiText,
} from '@elastic/eui';
import { useState } from 'react';

export interface DeleteConfirmProps {
  onDelete: () => Promise<void>;
  text: string;
  buttonText?: string;
  iconOnly?: boolean;
  disabled?: boolean;
}

export const DeleteConfirm = (props: DeleteConfirmProps) => {
  const { onDelete, text, buttonText, iconOnly, disabled } = props;
  const [deleting, setDeleting] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const handleDelete = async () => {
    try {
      setDeleting(true);
      await onDelete();
    } finally {
      setDeleting(false);
      setShowDelete(false);
    }
  };

  const renderButton = () => {
    const label = buttonText || 'Delete';

    if (iconOnly) {
      return (
        <EuiButtonIcon
          size="s"
          iconType="trash"
          color="danger"
          title={label}
          aria-label={label}
          onClick={() => setShowDelete(true)}
          disabled={disabled}
        />
      );
    }

    return (
      <EuiButtonEmpty
        size="s"
        color="danger"
        onClick={() => setShowDelete(true)}
        disabled={disabled}
      >
        {label}
      </EuiButtonEmpty>
    );
  };

  return (
    <EuiPopover
      isOpen={showDelete}
      closePopover={() => setShowDelete(false)}
      button={renderButton()}
    >
      <EuiText style={{ width: 300 }}>{text}</EuiText>
      <EuiPopoverFooter paddingSize="s">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <EuiButtonEmpty
            size="s"
            disabled={deleting}
            color="text"
            onClick={() => setShowDelete(false)}
            style={{ marginRight: 16 }}
          >
            Cancel
          </EuiButtonEmpty>
          <EuiButton
            size="s"
            isLoading={deleting}
            fill
            color="danger"
            onClick={handleDelete}
          >
            Delete
          </EuiButton>
        </div>
      </EuiPopoverFooter>
    </EuiPopover>
  );
};
