import { PublicUserProfileDto } from '@unfrl/copdb-sdk';
import { makeAutoObservable } from 'mobx';
import { apiClient } from '../api';
import { DataStore } from './data.store';

export class UserStore {
  public data: DataStore<PublicUserProfileDto> = new DataStore();

  public constructor() {
    makeAutoObservable(this);
  }

  public fetchUserProfiles = async (userIds: string[]): Promise<void> => {
    const idsToFetch = userIds.filter((id) => !this.data.itemExists(id));
    if (!idsToFetch.length) {
      return;
    }

    const profiles = await apiClient.users.getPublicUserProfiles({
      ids: idsToFetch,
    });

    profiles.forEach(this.data.setItem);
  };
}
