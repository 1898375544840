import { EuiFilePicker, EuiFormRow } from '@elastic/eui';
import { observer } from 'mobx-react';
import { useRef, useState } from 'react';
import { useStores } from '../../hooks';
import { FileSizes } from '../../utils';

export interface MediaFormProps {
  onMediaAdded: (mediaIds: string[]) => void;
}

export const MediaForm = observer((props: MediaFormProps) => {
  const { onMediaAdded } = props;
  const { mediaStore, toastStore } = useStores();
  const [uploading, setUploading] = useState(false);
  const filePickerRef: any = useRef();

  const handleFilesChange = async (files: FileList | null) => {
    const filesToUpload = files?.length ? Array.from(files) : [];
    if (!filesToUpload.length) {
      return;
    }

    for (const file of filesToUpload) {
      if (file.size > FileSizes.oneGb) {
        const error = `The provided file, ${file.name}, is too large. Uploads must be 1Gb or less`;
        toastStore.showError(error);
        filePickerRef.current.removeFiles();
        return;
      }
    }

    try {
      setUploading(true);

      const responses = await Promise.all(
        filesToUpload.map((file) => mediaStore.createMedia(file)),
      );
      const newMediaIds = responses.map((m) => m.id);

      filePickerRef.current.removeFiles();

      onMediaAdded(newMediaIds);
    } finally {
      setUploading(false);
    }
  };

  return (
    <EuiFormRow fullWidth label="Upload media">
      <EuiFilePicker
        id="media-file-picker"
        ref={filePickerRef}
        multiple
        fullWidth
        compressed
        disabled={uploading}
        isLoading={uploading}
        initialPromptText="Select or drag and drop multiple files"
        onChange={handleFilesChange}
        style={{ width: '100%' }}
      />
    </EuiFormRow>
  );
});
