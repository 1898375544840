import { EuiButton, EuiCallOut, EuiPageTemplate, EuiText } from '@elastic/eui';
import { Fragment, ReactNode } from 'react';
import { useRouteError } from 'react-router-dom';
import { Flex } from './flex';
import { DocumentHead } from './document-head';

export const ErrorElement = () => {
  let error: any = useRouteError();

  const withContainer = (title: string, content: ReactNode) => {
    return (
      <EuiPageTemplate restrictWidth={400}>
        <DocumentHead title={title} />
        <EuiPageTemplate.Section color="transparent">
          <EuiCallOut title={title} color="danger" iconType="alert">
            {content}
          </EuiCallOut>
        </EuiPageTemplate.Section>
      </EuiPageTemplate>
    );
  };

  const reload = (
    <Flex justify="center" style={{ marginTop: 8 }}>
      <EuiButton onClick={() => window.location.reload()} color="text">
        Reload page
      </EuiButton>
    </Flex>
  );

  const isChunkError = error?.message
    ? (error.message as string).match(/chunk/)
    : false;
  if (isChunkError) {
    return withContainer(
      'Loading failed',
      <Fragment>
        <EuiText>
          Part of the application failed to load. This may be because it was
          updated since you opened the tab or because of a failed network
          request. Please try reloading.
        </EuiText>
        {reload}
      </Fragment>,
    );
  }

  return withContainer(
    'Unexpected error occurred',
    <Fragment>
      <EuiText>
        An unrecoverable error occurred. Please try reloading the page, or
        contact support if the issue persists.
      </EuiText>
      {reload}
    </Fragment>,
  );
};
