import {
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormFieldset,
  EuiHorizontalRule,
  EuiText,
  EuiTitle,
} from '@elastic/eui';
import { MediaAssociationDto } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks';
import { EditReport } from '../../models';
import { MediaCopAssociationList } from './media-cop-association-list';
import { MediaIncidentAssociation } from './media-incident-association';
import { MediaPreview } from './media-preview';
import { MediaUploadAction, MediaUploadToolbar } from './media-upload-toolbar';
import { MediaLink } from './previews';

export interface MediaUploadProps {
  mediaAssociation: MediaAssociationDto;
  report: EditReport;
}

/**
 * Shows media upload progress/controls and options for associating media with
 * the incident and cops.
 */
export const MediaUpload = observer((props: MediaUploadProps) => {
  const { mediaAssociation, report } = props;
  const { mediaStore, toastStore } = useStores();

  const media = mediaStore.data.getItem(mediaAssociation.mediaId);
  const uploading = mediaStore.getUploadingMedia(mediaAssociation.mediaId);

  if (!media) {
    return null;
  }

  const handleAction = (action: MediaUploadAction) => {
    switch (action) {
      case 'delete':
        return report.deleteMedia(media.id);
      case 'cancel':
        return Promise.resolve(mediaStore.cancelUploadMedia(media.id));
    }
  };

  const handleSetDisplayName = (displayName: string) => {
    try {
      mediaStore.updateDisplayName(media.id, displayName);
    } catch (error) {
      toastStore.showApiError(error);
    }
  };

  const renderPreview = () => {
    if (uploading && !media.mediaProcessInfo[0]?.failureReason) {
      return <MediaLink media={media} />;
    }

    return (
      <MediaPreview media={media} onSetDisplayName={handleSetDisplayName} />
    );
  };

  const renderAssociations = () => {
    if (!report.hasDataToAssociate) {
      return (
        <EuiEmptyPrompt
          iconType="alert"
          iconColor="default"
          title={<h4>No data to associate</h4>}
          titleSize="xxs"
          paddingSize="none"
          body={
            <EuiText size="s">
              Document an incident or add cops to associate media with.
            </EuiText>
          }
        />
      );
    }

    return (
      <EuiFormFieldset
        legend={{
          children: (
            <EuiTitle size="xxs">
              <span>Select all options this media applies to</span>
            </EuiTitle>
          ),
        }}
      >
        {report.hasIncident && (
          <MediaIncidentAssociation
            id={`${media.id}-incident`}
            checked={!!mediaAssociation.includesIncident}
            onCheckedChange={(checked) =>
              report.updateMediaAssociation({
                ...mediaAssociation,
                includesIncident: checked,
              })
            }
          />
        )}
        {report.hasIncident &&
        (report.existingCops.length || report.newCops?.length) ? (
          <EuiHorizontalRule margin="s" />
        ) : null}
        <MediaCopAssociationList
          mediaAssociation={mediaAssociation}
          report={report}
        />
      </EuiFormFieldset>
    );
  };

  return (
    <div>
      <EuiFlexGroup>
        <EuiFlexItem style={{ justifyContent: 'center', alignItems: 'center' }}>
          {renderPreview()}
        </EuiFlexItem>
        <EuiFlexItem style={{ paddingTop: 8 }}>
          {renderAssociations()}
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiHorizontalRule margin="s" />
      <MediaUploadToolbar
        media={media}
        uploading={uploading}
        onAction={handleAction}
      />
    </div>
  );
});
