import {
  EuiButton,
  EuiButtonEmpty,
  EuiCheckbox,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiFormRow,
  EuiIconTip,
  EuiMarkdownEditor,
  EuiSpacer,
  EuiTextArea,
  EuiTitle,
  getDefaultEuiMarkdownPlugins,
  useGeneratedHtmlId,
} from '@elastic/eui';
import { UpsertIncidentDto } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { Fragment } from 'react';
import { EditIncidentViewModel } from '../../models';
import { Flex } from '../common';
import { CopDetailList, CopSearch } from '../cop';
import { DateOccurredForm } from '../date-occurred';
import { LocationForm } from '../location';
import { MediaForm } from '../media';
import { IncidentMediaAssociationList } from './incident-media-association-list';
import { IncidentTagSelect } from './incident-tag-select';

export interface IncidentFormFlyoutProps {
  onClose: () => void;
  onSave: (dto: UpsertIncidentDto) => void;
  saving?: boolean;
  editIncident: EditIncidentViewModel;
  title: string;
}

const { parsingPlugins, processingPlugins, uiPlugins } =
  getDefaultEuiMarkdownPlugins({ exclude: ['tooltip'] });

const SUMMARY_LIMIT = 200;

export const IncidentForm = observer((props: IncidentFormFlyoutProps) => {
  const { onClose, onSave, saving, editIncident, title } = props;
  const formFlyoutId = useGeneratedHtmlId({
    prefix: 'incidentFormFlyoutTitle',
  });

  return (
    <EuiFlyout maxWidth={700} onClose={onClose}>
      <EuiFlyoutHeader hasBorder aria-labelledby={formFlyoutId}>
        <EuiTitle>
          <h2 id={formFlyoutId}>{title}</h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiFormRow
          fullWidth
          label="Summary"
          helpText={`${
            (editIncident.summary || '').length
          } / ${SUMMARY_LIMIT} characters`}
        >
          <EuiTextArea
            compressed
            fullWidth
            value={editIncident.summary || ''}
            onChange={(e) => editIncident.update({ summary: e.target.value })}
            maxLength={SUMMARY_LIMIT}
            placeholder="Enter a summary"
            rows={2}
          />
        </EuiFormRow>

        <DateOccurredForm
          date={editIncident.dateOccurred}
          onDateChange={(date) => {
            editIncident.update({ dateOccurred: date });
          }}
        />

        <EuiFormRow fullWidth>
          <Flex gap={8} align="center">
            <EuiCheckbox
              id={'preliminary'}
              label={'Preliminary'}
              checked={editIncident.preliminary}
              onChange={(e) => {
                editIncident.update({ preliminary: e.target.checked });
              }}
            />
            <EuiIconTip
              content="Setting this will inform viewers that the Incident is incomplete and needs more information."
              position="right"
            />
          </Flex>
        </EuiFormRow>

        <LocationForm
          location={editIncident.location}
          label="Location"
          onLocationChange={(location) => {
            editIncident.update({ location });
          }}
        />

        <EuiFormRow fullWidth label="Tags">
          <IncidentTagSelect
            tags={editIncident.tags}
            onChange={(tags) => editIncident.update({ tags })}
          />
        </EuiFormRow>

        <EuiFormRow fullWidth label="Description">
          <EuiMarkdownEditor
            aria-label="Incident description editor"
            placeholder="Description of the incident..."
            value={editIncident.description || ''}
            onChange={(value) => editIncident.update({ description: value })}
            height={250}
            initialViewMode="editing"
            parsingPluginList={parsingPlugins}
            processingPluginList={processingPlugins}
            uiPlugins={uiPlugins}
          />
        </EuiFormRow>

        <EuiFormRow fullWidth label="Cops">
          <Fragment>
            <CopSearch onSelected={editIncident.associateCop} />
            <EuiSpacer size="s" />
            <CopDetailList
              cops={editIncident.copDetailItems}
              onDelete={editIncident.unassociateCop}
              onEditIncidentInfo={editIncident.updateAssociatedCop}
            />
          </Fragment>
        </EuiFormRow>

        <MediaForm
          onMediaAdded={(mediaIds) => editIncident.associateMedias(mediaIds)}
        />

        <EuiSpacer size="m" />

        <IncidentMediaAssociationList editIncident={editIncident} />
      </EuiFlyoutBody>
      <EuiFlyoutFooter>
        <Flex align="center" justify="flex-end" gap={16}>
          <EuiButtonEmpty
            size="s"
            iconType="cross"
            color="text"
            onClick={onClose}
            disabled={saving}
          >
            Cancel
          </EuiButtonEmpty>
          <EuiButton
            fill
            size="s"
            onClick={() => {
              if (editIncident.canSave) {
                onSave(editIncident.asDto);
              }
            }}
            isLoading={saving}
            disabled={!editIncident.canSave}
          >
            Submit
          </EuiButton>
        </Flex>
      </EuiFlyoutFooter>
    </EuiFlyout>
  );
});
