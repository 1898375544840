import { observer } from 'mobx-react';
import { CopEditMapping } from '../../stores';
import { Flex } from '../common';
import { CopEditItem } from './cop-edit-item';

export interface CopEditListProps {
  mapping: CopEditMapping;
}

export const CopEditList = observer((props: CopEditListProps) => {
  const { mapping } = props;

  return (
    <Flex column gap={8} style={{ width: '100%' }}>
      {mapping.edits.map((edit) => (
        <CopEditItem
          key={edit.id}
          edit={edit}
          cop={mapping.cop}
          existingPersonMedias={mapping.existingPersonMediaDtos}
          existingCopDepartments={mapping.existingCopDepartments}
        />
      ))}
    </Flex>
  );
});
