import {
  CopDepartmentDto,
  CopDepartmentResponseDto,
  CopResponseDto,
  Operation,
  PersonIncidentAssociationDto,
  PersonMedia,
  PersonMediaResponseDto,
  UpsertCopDto,
  UpsertPersonMediaDto,
} from '@unfrl/copdb-sdk';
import moment from 'moment';
import { CopDetailItemProps } from '../components';
import { stores } from '../stores';
import { dateUtils } from './date.utils';
import { getBadgeNumberText, getPositionText } from './department.utils';

export const formatNameFromResponseDto = (cop: CopResponseDto): string => {
  const { person } = cop;

  return formatName(person.firstName, person.middleName, person.lastName);
};

export const formatNameFromUpsertDto = (cop: UpsertCopDto) => {
  return [cop.firstName, cop.middleName, cop.lastName]
    .filter(Boolean)
    .join(' ');
};

export const formatName = (
  firstName?: string | null,
  middleName?: string | null,
  lastName?: string | null,
): string => {
  const name = `${firstName || ''}${middleName ? ` ${middleName} ` : ' '}${
    lastName || ''
  }`.trim();
  return name || 'N/A';
};

export const getProfilePhotoFromResponse = (
  cop: CopResponseDto,
): string | undefined => {
  return cop.person.photoMedias
    ? cop.person.photoMedias[0]?.url ?? undefined
    : undefined;
};

export const getProfilePhotoFromUpsert = (
  upsertDto: UpsertCopDto,
): string | undefined => {
  const copProfileMedias = upsertDto.personMediaDtos?.filter(
    (dto) => dto.profilePhoto,
  );
  if (!copProfileMedias?.length) {
    return undefined;
  }

  const mediaId = copProfileMedias[0].mediaId;
  return stores.mediaStore.data.getItem(mediaId)?.url ?? undefined;
};

/**
 * Maps a cop response DTO to the upsert DTO for editing.
 * @param cop - The cop response DTO
 * @param personMedias - Optional - if included will be mapped to personMediaDtos, otherwise the cop's personMedia records will be used
 * @param copDepartments - Optional cop department responses to be mapped to the upsert's `copDepartmentDtos` property
 */
export const mapResponseToUpsertDto = (
  cop: CopResponseDto,
  personMedias?: PersonMedia[],
  copDepartments?: CopDepartmentResponseDto[],
): UpsertCopDto => {
  return {
    description: cop.description,
    firstName: cop.person.firstName,
    middleName: cop.person.middleName,
    lastName: cop.person.lastName,
    suffix: cop.person.suffix,
    phoneNumber: cop.person.phoneNumber,
    eyeColor: cop.person.eyeColor,
    hairColor: cop.person.hairColor,
    gender: cop.person.gender,
    race: cop.person.race,
    visibleTattoos: cop.person.visibleTattoos,
    personMediaDtos: mapPersonMediaResponseToUpdateDto(
      personMedias ?? cop.person.photoMedias ?? [],
    ),
    copDepartmentDtos: (copDepartments ?? []).map((copDept) => ({
      departmentId: copDept.departmentId,
      startDate: copDept.startDate,
      endDate: copDept.endDate,
      badgeNumber: copDept.badgeNumber,
      position: copDept.position,
      notabilities: copDept.notabilities,
    })),
  };
};

const mapPersonMediaResponseToUpdateDto = (
  personMedias?: PersonMediaResponseDto[] | PersonMedia[],
): UpsertPersonMediaDto[] => {
  return personMedias
    ? personMedias.map((pm) => ({
        mediaId: pm.mediaId ?? '',
        profilePhoto: pm.profilePhoto,
        operation: Operation.Upsert,
      }))
    : [];
};

export const mapResponseToDetailItem = (
  response: CopResponseDto,
  personIncidentAssociations?: PersonIncidentAssociationDto[],
): CopDetailItemProps => {
  const incidentInformation = personIncidentAssociations?.find(
    (info) => info.personId === response.personId,
  )?.incidentInformation;
  const photoUrls = response.person.photoMedias
    ?.filter((m) => m.profilePhoto)
    .map((m) => m.url);

  return {
    ...mapResponseToUpsertDto(response),
    position: getPositionText(response.copDepartments),
    badgeNumber: getBadgeNumberText(response.copDepartments),
    id: response.id,
    incidentInformation,
    photoUrls,
  };
};

export const mapResponsesToDetailItems = (
  responses: CopResponseDto[],
  personIncidentAssociations?: PersonIncidentAssociationDto[],
): CopDetailItemProps[] => {
  return responses.map((response) =>
    mapResponseToDetailItem(response, personIncidentAssociations),
  );
};

export const mapUpsertDtoToDetailItem = (
  dto: UpsertCopDto,
): CopDetailItemProps => {
  const photoUrl = getProfilePhotoFromUpsert(dto);
  return {
    ...dto,
    position: getPositionText(dto.copDepartmentDtos),
    badgeNumber: getBadgeNumberText(dto.copDepartmentDtos),
    photoUrls: photoUrl ? [photoUrl] : [],
  };
};

export const mapUpsertDtosToDetailItems = (
  dtos: UpsertCopDto[],
): CopDetailItemProps[] => {
  return dtos.map(mapUpsertDtoToDetailItem);
};

export const validateCopDepartmentDtos = (
  copDepartmentDtos: CopDepartmentDto[],
) => {
  const depErrors = new Map<string, string[]>();
  const now = moment();

  for (const departmentDto of copDepartmentDtos) {
    const errors: string[] = [];
    const startDate = dateUtils.toMoment(departmentDto.startDate);
    const endDate = dateUtils.toMoment(departmentDto.endDate);

    if (startDate && startDate > now) {
      errors.push('Start Date cannot be in the future');
    }

    if (endDate && endDate > now) {
      errors.push('End Date cannot be in the future');
    }

    if (startDate && endDate && startDate > endDate) {
      errors.push('Start Date cannot be after end date');
    }

    depErrors.set(departmentDto.departmentId, errors);
  }

  return depErrors;
};
