import { EuiButtonEmpty, EuiButtonIcon, EuiFieldText } from '@elastic/eui';
import { useState } from 'react';
import { Flex } from './flex';

export interface ConfirmTextInputProps {
  initialText?: string;
  placeholder: string;
  onSave: (value: string) => void;
  onCancel: () => void;
}

export const ConfirmTextInput = (props: ConfirmTextInputProps) => {
  const { initialText, onSave, onCancel, placeholder } = props;
  const [value, setValue] = useState(initialText || '');

  return (
    <Flex align="center">
      <EuiFieldText
        compressed
        fullWidth
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeholder}
        append={
          <EuiButtonIcon
            aria-label="Clear text"
            color="text"
            iconType="cross"
            onClick={() => setValue('')}
          />
        }
      />
      <EuiButtonEmpty
        size="s"
        onClick={() => {
          onSave(value);
        }}
        style={{ marginLeft: 8 }}
      >
        Save
      </EuiButtonEmpty>
      <EuiButtonEmpty size="s" color="text" onClick={onCancel}>
        Cancel
      </EuiButtonEmpty>
    </Flex>
  );
};
