import { EuiPanel, EuiText, EuiToolTip } from '@elastic/eui';
import {
  Media,
  Operation,
  PersonMediaResponseDto,
  UpsertPersonMediaDto,
} from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks';
import { Flex } from '../common';
import { MediaPreview } from './media-preview';

export interface MediaEditDiffProps {
  upsertPersonMediaDtos: UpsertPersonMediaDto[];
  existingPersonMedias: PersonMediaResponseDto[];
}

export const MediaEditDiff = observer((props: MediaEditDiffProps) => {
  const { upsertPersonMediaDtos, existingPersonMedias } = props;
  const { mediaStore } = useStores();

  const addedMedias = upsertPersonMediaDtos
    .filter(
      (pmdto) =>
        pmdto.operation !== Operation.Delete &&
        !existingPersonMedias.find((pm) => pm.mediaId === pmdto.mediaId),
    )
    .map((pmdto) => mediaStore.data.getItem(pmdto.mediaId))
    .filter(Boolean) as Media[];

  const deletedMedias = upsertPersonMediaDtos
    .filter(
      (pmdto) =>
        pmdto.operation === Operation.Delete &&
        existingPersonMedias.find((pm) => pm.mediaId === pmdto.mediaId),
    )
    .map((pmdto) => mediaStore.data.getItem(pmdto.mediaId))
    .filter(Boolean) as Media[];

  if (!addedMedias.length && !deletedMedias.length) {
    return (
      <EuiText size="s" color="subdued">
        No changes
      </EuiText>
    );
  }

  return (
    <Flex column gap={8}>
      {addedMedias.map((addedMedia) => (
        <EuiToolTip key={addedMedia.id} title="Added">
          <EuiPanel
            color="success"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <MediaPreview media={addedMedia} />
          </EuiPanel>
        </EuiToolTip>
      ))}

      {deletedMedias.map((deletedMedia) => (
        <EuiToolTip key={deletedMedia.id} title="Deleted">
          <EuiPanel
            color="danger"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <MediaPreview media={deletedMedia} />
          </EuiPanel>
        </EuiToolTip>
      ))}
    </Flex>
  );
});
