import { EuiIcon, EuiText, EuiTextProps } from '@elastic/eui';
import { dateUtils } from '../../utils';
import { Flex, MissingDataText } from '../common';

export interface DateOccurredProps extends EuiTextProps {
  date?: Date | null;
  withIcon?: boolean;
}

export const DateOccurred = (props: DateOccurredProps) => {
  const { date, withIcon, ...rest } = props;

  const text = dateUtils.hasValue(date) ? (
    <EuiText size="relative" {...rest}>
      {dateUtils.format(date!)}
    </EuiText>
  ) : (
    <MissingDataText text="No date recorded." {...rest} />
  );

  if (!withIcon) {
    return text;
  }

  return (
    <Flex align="center">
      <EuiIcon title="Date occurred" type="clock" style={{ marginRight: 4 }} />
      {text}
    </Flex>
  );
};
