import { EuiCallOut } from '@elastic/eui';
import { UpsertReportDto } from '@unfrl/copdb-sdk';
import { pluralize } from '../../utils';
import { Flex } from '../common';

export interface UpsertReportSummaryProps {
  upsertReportDto: UpsertReportDto;
}

export const UpsertReportSummary = (props: UpsertReportSummaryProps) => {
  const { upsertReportDto } = props;

  const hasIncident = upsertReportDto.summary || upsertReportDto.description;
  const incident = hasIncident ? 'An incident' : '';
  const newCops = upsertReportDto.newCops?.length
    ? `${pluralize(upsertReportDto.newCops.length, 'new cop')}${
        hasIncident ? ' associated with the incident' : ''
      }`
    : '';
  const existingCops = upsertReportDto.existingCopIds?.length
    ? `${pluralize(upsertReportDto.existingCopIds.length, 'existing cop')}${
        hasIncident ? ' associated with the incident' : ''
      }`
    : '';
  const media = upsertReportDto.mediaAssociations?.length
    ? `${upsertReportDto.mediaAssociations.length} new media`
    : '';

  const summaryItems = [incident, newCops, existingCops, media].filter(Boolean);
  if (!summaryItems.length) {
    return null;
  }

  return (
    <EuiCallOut title="This report has the following data:">
      <Flex column gap={4}>
        {summaryItems.map((item) => (
          <div key={item}>{item}</div>
        ))}
      </Flex>
    </EuiCallOut>
  );
};
