import { EuiBadge, EuiSuperSelect } from '@elastic/eui';
import { ReportStatus } from '@unfrl/copdb-sdk';
import { ReportStatusBadge } from './report-status-badge';

const statusOptions = [
  {
    value: 'All',
    inputDisplay: <EuiBadge color="hollow">All</EuiBadge>,
  },
  {
    value: ReportStatus.Draft,
    inputDisplay: <ReportStatusBadge status={ReportStatus.Draft} />,
  },
  {
    value: ReportStatus.Pending,
    inputDisplay: <ReportStatusBadge status={ReportStatus.Pending} />,
  },
  {
    value: ReportStatus.Approved,
    inputDisplay: <ReportStatusBadge status={ReportStatus.Approved} />,
  },
  {
    value: ReportStatus.Denied,
    inputDisplay: <ReportStatusBadge status={ReportStatus.Denied} />,
  },
];

export interface ReportTableToolbarProps {
  status?: ReportStatus;
  onStatusChange: (status?: ReportStatus) => void;
  /**
   * Set to true to toggle moderating mode. This will remove draft from the list
   * of status options to filter by.
   */
  isModerating?: boolean;
}

export const ReportTableToolbar = (props: ReportTableToolbarProps) => {
  const { status, onStatusChange, isModerating } = props;

  const reportStatusOptions = isModerating
    ? statusOptions.filter((option) => option.value !== ReportStatus.Draft)
    : statusOptions;

  return (
    <EuiSuperSelect
      options={reportStatusOptions}
      valueOfSelected={status ?? 'All'}
      onChange={(value) =>
        onStatusChange(value === 'All' ? undefined : (value as ReportStatus))
      }
    />
  );
};
