import { EuiBadge } from '@elastic/eui';
import { ReportStatus } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { CSSProperties } from 'react';

const statusColors = {
  [ReportStatus.Draft]: 'hollow',
  [ReportStatus.Pending]: 'default',
  [ReportStatus.Approved]: 'success',
  [ReportStatus.Denied]: 'danger',
  [ReportStatus.Archived]: 'warning',
};

export const ReportStatusBadge = observer(
  ({
    status,
    style,
  }: {
    status: ReportStatus | 'draft';
    style?: CSSProperties;
  }) => {
    return (
      <EuiBadge color={statusColors[status]} style={style}>
        {status}
      </EuiBadge>
    );
  },
);
