import {
  EuiEmptyPrompt,
  EuiLoadingSpinner,
  EuiPanel,
  EuiSpacer,
  EuiSplitPanel,
  EuiStat,
  EuiText,
  EuiTextColor,
} from '@elastic/eui';
import { PublicStatisticsResponseDto } from '@unfrl/copdb-sdk';
import { useEffect, useState } from 'react';
import { apiClient } from '../../api';
import { useNavProps, useStores } from '../../hooks';
import {
  copProfilePath,
  mapResponseToDetailItem,
  pluralize,
} from '../../utils';
import { Flex } from '../common';
import { CopDetailItem } from '../cop';

export const Statistics = () => {
  const { toastStore } = useStores();
  const [loading, setLoading] = useState(true);
  const [statistics, setStatistics] =
    useState<PublicStatisticsResponseDto | null>(null);

  const getNavProps = useNavProps();

  const getStatistics = async () => {
    setLoading(true);

    try {
      const response = await apiClient.meta.getPublicStatistics();
      setStatistics(response);
    } catch (error) {
      await toastStore.showApiError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // trigger initial load
    getStatistics();
  }, []);

  if (loading) {
    return (
      <EuiEmptyPrompt
        style={{ marginTop: 8, marginBottom: 8 }}
        title={<h3>Loading statistics</h3>}
        body={
          <EuiLoadingSpinner
            size="l"
            style={{ marginLeft: 8, marginRight: 8 }}
          />
        }
      />
    );
  }

  if (!statistics) {
    return (
      <EuiEmptyPrompt
        style={{ marginTop: 8 }}
        color="danger"
        title={<h2>Error</h2>}
        body={<EuiText>Unable to load statistics</EuiText>}
      />
    );
  }

  const {
    copsAddedWithinDurationCount,
    copsUpdatedWithinDurationCount,
    incidentsAddedWithinDurationCount,
    mediaAddedWithinDurationCount,
    durationDays,
    totalCopsCount,
    totalIncidentsCount,
    totalDepartmentsCount,
    latestCops,
  } = statistics;

  return (
    <EuiSplitPanel.Outer
      grow
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: 16,
        marginBottom: 16,
      }}
    >
      <EuiSplitPanel.Inner>
        <EuiText textAlign="center">
          <h3>
            CopDB has information on{' '}
            <EuiTextColor color="danger">
              {pluralize(totalCopsCount, 'cop')}
            </EuiTextColor>{' '}
            in{' '}
            <EuiTextColor color="danger">
              {pluralize(totalDepartmentsCount, 'department')}
            </EuiTextColor>{' '}
            and documented{' '}
            <EuiTextColor color="danger">
              {pluralize(totalIncidentsCount, 'incident')}
            </EuiTextColor>
            !
          </h3>
        </EuiText>
        <EuiSpacer size="s" />
        <Flex align="center" column>
          <EuiText>{`In the last ${durationDays} days:`}</EuiText>
          <EuiSpacer size="xs" />
          <Flex justify="center" gap={24}>
            <EuiStat
              title={copsUpdatedWithinDurationCount}
              description="Cops updated"
              textAlign="center"
            />
            <EuiStat
              title={copsAddedWithinDurationCount}
              description="Cops added"
              textAlign="center"
            />
            <EuiStat
              title={incidentsAddedWithinDurationCount}
              description="Incidents added"
              textAlign="center"
            />
            <EuiStat
              title={mediaAddedWithinDurationCount}
              description="Media added"
              textAlign="center"
            />
          </Flex>
        </Flex>
      </EuiSplitPanel.Inner>
      <EuiSplitPanel.Inner color="subdued">
        <EuiText>Latest cops:</EuiText>
        <Flex
          gap={8}
          style={{
            overflowX: 'scroll',
            minHeight: 130,
          }}
        >
          {latestCops?.map((cop) => {
            const navProps = getNavProps(copProfilePath(cop.id));

            return (
              <EuiPanel key={cop.id} {...navProps} style={{ minWidth: 200 }}>
                <CopDetailItem {...mapResponseToDetailItem(cop)} />
              </EuiPanel>
            );
          })}
        </Flex>
      </EuiSplitPanel.Inner>
    </EuiSplitPanel.Outer>
  );
};
