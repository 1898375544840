import {
  EuiBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIconTip,
  EuiText,
} from '@elastic/eui';
import {
  IncidentResponseDto,
  IncidentTagDto,
  LocationDto,
} from '@unfrl/copdb-sdk';
import { dateUtils, incidentPath } from '../../utils';
import { Flex, MissingDataText } from '../common';
import { DateOccurred } from '../date-occurred';
import { LocationPlaceLink } from '../location';
import { BasicTableColumn, TitleCell } from '../table';

const TagCell = ({ tags }: { tags?: string[] }) => {
  return (
    <EuiFlexGroup
      style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
      wrap
      responsive={false}
      gutterSize="xs"
    >
      {tags?.length ? (
        tags.map((tag) => {
          return (
            <EuiFlexItem
              grow={false}
              key={tag}
              style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              <EuiBadge>{tag}</EuiBadge>
            </EuiFlexItem>
          );
        })
      ) : (
        <MissingDataText text="No tags recorded." />
      )}
    </EuiFlexGroup>
  );
};

const summary = (
  searchValue?: string,
): BasicTableColumn<IncidentResponseDto> => {
  return {
    field: 'summary',
    name: 'Summary',
    width: '30%',
    sortable: true,
    dataType: 'string',
    mobileOptions: {
      header: false,
      truncateText: false,
      enlarge: true,
      width: '100%',
    },
    render: (summary: string, item: IncidentResponseDto) => {
      return (
        <Flex gap={8} align="center">
          <TitleCell
            title={summary || 'Untitled summary'}
            linkTo={incidentPath(item.id!)}
            searchValue={searchValue}
          />
          {item.preliminary ? (
            <EuiIconTip
              type="warning"
              content='This incident has been marked "preliminary" and needs more information'
              position="right"
            />
          ) : null}
        </Flex>
      );
    },
  };
};

const dateOccurred = (): BasicTableColumn<IncidentResponseDto> => {
  return {
    field: 'dateOccurred',
    name: 'Date occurred',
    sortable: true,
    dataType: 'date',
    render: (date?: Date) => <DateOccurred date={date} />,
  };
};

const location = (): BasicTableColumn<IncidentResponseDto> => {
  return {
    field: 'location',
    name: 'Location',
    render: (location: LocationDto) => {
      return <LocationPlaceLink location={location} size="relative" />;
    },
  };
};

const tags = (): BasicTableColumn<IncidentResponseDto> => {
  return {
    field: 'tags',
    name: 'Tags',
    width: '105px',
    render: (tags?: IncidentTagDto[]) => {
      return <TagCell tags={tags?.map((tag) => tag.name)} />;
    },
  };
};

const createdAt = (): BasicTableColumn<IncidentResponseDto> => {
  return {
    field: 'createdAt',
    name: 'Created',
    sortable: true,
    dataType: 'date',
    width: '100px',
    render: (created: Date) => {
      return (
        <EuiText size="relative" title={dateUtils.format(created)}>
          {dateUtils.fromNow(created)}
        </EuiText>
      );
    },
  };
};

const updatedAt = (): BasicTableColumn<IncidentResponseDto> => {
  return {
    field: 'updatedAt',
    name: 'Last updated',
    sortable: true,
    dataType: 'date',
    width: '100px',
    render: (updated: Date) => {
      return (
        <EuiText size="relative" title={dateUtils.format(updated)}>
          {dateUtils.fromNow(updated)}
        </EuiText>
      );
    },
  };
};

export const incidentTableColumns = {
  summary,
  dateOccurred,
  location,
  tags,
  createdAt,
  updatedAt,
};
