import {
  EuiAvatar,
  EuiBadge,
  EuiScreenReaderOnly,
  EuiText,
  EuiToken,
  RIGHT_ALIGNMENT,
} from '@elastic/eui';
import { UserProfileDto } from '@unfrl/copdb-sdk';
import { Flex } from '../common';
import { BasicTableColumn } from '../table';

const username = (): BasicTableColumn<UserProfileDto> => {
  return {
    field: 'userName',
    name: 'Username',
    width: '200px',
    render: (value: string) => {
      return (
        <Flex align="center">
          <EuiAvatar name={value} size="m" />
          <div style={{ marginRight: 12 }} />
          <EuiText size="relative" grow={true}>
            {value}
          </EuiText>
        </Flex>
      );
    },
  };
};

const email = () => {
  return {
    field: 'email',
    name: 'Email',
    width: '200px',
  };
};

const role = () => {
  return {
    field: 'roles',
    name: 'Role',
    width: '150px',
    render: (values: string[]) => {
      return (
        <Flex wrap align="center">
          {values.map((value) => (
            <EuiBadge key={value} style={{ margin: 4 }}>
              {value}
            </EuiBadge>
          ))}
        </Flex>
      );
    },
  };
};

const lockedOut = () => {
  return {
    field: 'lockoutEnd',
    name: 'Status',
    render: (lockoutEnd?: Date) => {
      if (!lockoutEnd) {
        return '';
      }

      return <EuiBadge color="danger">Banned</EuiBadge>;
    },
  };
};

const twoFactor = () => {
  return {
    field: 'twoFactorEnabled',
    name: '2-Factor',
    render: (twoFactorEnabled: boolean) => {
      return twoFactorEnabled ? (
        <EuiToken
          iconType="lock"
          size="s"
          shape="square"
          fill="light"
          color="euiColorVis0"
        />
      ) : (
        <EuiToken
          iconType="lockOpen"
          size="s"
          shape="square"
          fill="light"
          color="euiColorVis9"
        />
      );
    },
  };
};

const actions = (
  onEdit: (user: UserProfileDto) => void,
): BasicTableColumn<UserProfileDto> => {
  return {
    align: RIGHT_ALIGNMENT,
    name: (
      <EuiScreenReaderOnly>
        <span>Actions</span>
      </EuiScreenReaderOnly>
    ),
    actions: [
      {
        name: 'Edit',
        description: 'Edit this user',
        type: 'icon',
        icon: 'pencil',
        onClick: onEdit,
      },
    ],
  };
};

export const userTableColumns = {
  username,
  email,
  role,
  lockedOut,
  twoFactor,
  actions,
};
