import {
  EditStatus,
  IncidentEdit,
  IncidentResponseDto,
  UpsertIncidentDto,
} from '@unfrl/copdb-sdk';
import { uniq } from 'lodash';
import {
  makeAutoObservable,
  onBecomeObserved,
  onBecomeUnobserved,
  toJS,
} from 'mobx';
import { apiClient } from '../api';
import { EditIncidentViewModel } from '../models';
import { DataStore } from './data.store';
import { RootStore } from './root.store';

export interface IncidentEditMapping {
  id: string;
  incident: IncidentResponseDto;
  edits: IncidentEdit[];
}

export class IncidentEditStore {
  private readonly _root: RootStore;

  public data: DataStore<IncidentEdit> = new DataStore();

  public loading: boolean = false;

  private get uniqueIncidentIds(): string[] {
    return uniq(this.data.orderedItems.map((edit) => edit.incidentId));
  }

  public get incidentEditMap(): IncidentEditMapping[] {
    return this.uniqueIncidentIds
      .map((id) => ({
        id,
        incident: this._root.incidentStore.data.getItem(id),
        edits: this.data.orderedItems.filter((edit) => edit.incidentId === id),
      }))
      .filter((mapping) =>
        Boolean(mapping.incident && mapping.edits.length),
      ) as IncidentEditMapping[];
  }

  public constructor(rootStore: RootStore) {
    this._root = rootStore;

    makeAutoObservable(this);

    onBecomeObserved(this, 'incidentEditMap', () =>
      this.fetchIncidentEdits(EditStatus.Pending),
    );
    onBecomeUnobserved(this, 'incidentEditMap', this.data.clearItems);
  }

  public getEditMapping = (
    incidentId: string,
  ): IncidentEditMapping | undefined => {
    return this.incidentEditMap.find((map) => map.id === incidentId);
  };

  public getEditIncidentViewModel = (
    incident: IncidentResponseDto,
    upsertDto?: UpsertIncidentDto,
  ): EditIncidentViewModel => {
    return new EditIncidentViewModel(
      this._root,
      toJS(incident),
      toJS(upsertDto),
    );
  };

  public fetchIncidentEdits = async (
    status?: EditStatus,
    incidentIds?: string[],
  ): Promise<void> => {
    try {
      this.setLoading(true);

      const edits = await apiClient.incidents.listIncidentEdits({
        status,
        incidentIds,
      });

      this.data.setList(edits);

      const incidentsToFetch = uniq(edits.map((edit) => edit.incidentId));
      const copPersonIdsToFetch = uniq(
        edits
          .flatMap((edit) =>
            edit.editDto.personIncidentAssociations?.map(
              (assoc) => assoc.personId,
            ),
          )
          .filter(Boolean),
      ) as string[];
      const mediaIdsToFetch = uniq(
        edits
          .flatMap((edit) => edit.editDto.mediaAssociations ?? [])
          .map((association) => association.mediaId),
      );

      await Promise.all([
        this._root.incidentStore.fetchIncidents(incidentsToFetch),
        this._root.copStore.fetchCopsByPersonIds(copPersonIdsToFetch),
        this._root.mediaStore.fetchMedias(mediaIdsToFetch),
      ]);
    } finally {
      this.setLoading(false);
    }
  };

  public createIncidentEdit = async (
    incidentId: string,
    incidentDto: UpsertIncidentDto,
  ): Promise<void> => {
    const edit = await apiClient.incidents.createIncidentEdit({
      incidentId: incidentId,
      upsertIncidentDto: incidentDto,
    });

    this.data.setItem(edit);
  };

  public updateIncidentEditStatus = async (
    editId: string,
    status: EditStatus,
  ): Promise<void> => {
    const updated = await apiClient.incidents.updateIncidentEditStatus({
      editId,
      status,
    });

    this.handleUpdatedEdit(updated);
  };

  public overwriteIncidentEdit = async (
    incidentId: string,
    incidentDto: UpsertIncidentDto,
  ): Promise<void> => {
    const edit = await apiClient.incidents.createIncidentEdit({
      incidentId,
      upsertIncidentDto: incidentDto,
    });

    await this.updateIncidentEditStatus(edit.id, EditStatus.Approved);
  };

  //#region Actions

  private handleUpdatedEdit = async (updated: IncidentEdit): Promise<void> => {
    const { incidentId, id } = updated;

    if (updated.status === EditStatus.Approved) {
      await this._root.incidentStore.fetchIncidents([incidentId]);

      this.data.orderedItems
        .filter((edit) => edit.incidentId === incidentId)
        .forEach((edit) => this.data.deleteItem(edit.id));
    }

    if (updated.status === EditStatus.Denied) {
      this.data.deleteItem(id);
    }
  };

  private setLoading = (loading: boolean): void => {
    this.loading = loading;
  };

  //#endregion
}
