import { ReactNode, useState } from 'react';

interface ExpandedMap {
  [key: string]: ReactNode;
}

export const useExpandedMap = (
  renderExpanded: (id: string) => ReactNode,
  initial: ExpandedMap = {},
) => {
  const [expandedMap, setExpandedMap] = useState<ExpandedMap>(initial);

  const isExpanded = (id: string) => {
    return !!expandedMap[id];
  };

  const toggleExpanded = (id: string) => {
    const toUpdate = { ...expandedMap };
    if (toUpdate[id]) {
      delete toUpdate[id];
    } else {
      toUpdate[id] = renderExpanded(id);
    }

    setExpandedMap(toUpdate);
  };

  const collapseAll = () => {
    setExpandedMap(initial);
  };

  return { expandedMap, isExpanded, toggleExpanded, collapseAll };
};
