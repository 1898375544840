import {
  EuiFilterButton,
  EuiPopover,
  EuiPopoverTitle,
  EuiSelectable,
  useGeneratedHtmlId,
} from '@elastic/eui';
import { useState } from 'react';
import { camelToSentence } from '../../utils';

export interface FilterSelectProps {
  options: string[];
  label: string;
  values?: string[] | undefined;
  onChange: (selected: string[] | undefined) => void;
  singleSelection?: boolean;
}

export const FilterSelect = (props: FilterSelectProps) => {
  const { options, label, values, onChange, singleSelection } = props;

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const items: {
    label: string;
    value: string;
    checked?: string;
  }[] = options.map((opt) => {
    return {
      label: camelToSentence(opt),
      value: opt,
      checked: values?.includes(opt) ? 'on' : undefined,
    };
  });

  const filterGroupPopoverId = useGeneratedHtmlId({
    prefix: `${label.replaceAll(/\s*/g, '')}FilterGroupPopover`,
  });

  const button = (
    <EuiFilterButton
      iconType="arrowDown"
      onClick={() => setIsPopoverOpen(!isPopoverOpen)}
      isSelected={isPopoverOpen}
      numFilters={items.filter((item) => item.checked !== 'off').length}
      hasActiveFilters={!!items.find((item) => item.checked === 'on')}
      numActiveFilters={items.filter((item) => item.checked === 'on').length}
    >
      {label}
    </EuiFilterButton>
  );

  return (
    <EuiPopover
      id={filterGroupPopoverId}
      button={button}
      isOpen={isPopoverOpen}
      closePopover={() => setIsPopoverOpen(false)}
      panelPaddingSize="none"
    >
      <EuiSelectable
        searchable
        singleSelection={singleSelection}
        searchProps={{
          placeholder: 'Filter list',
          compressed: true,
          autoFocus: true,
        }}
        aria-label={label}
        options={items as any}
        onChange={(newOptions) => {
          onChange(
            newOptions
              .filter((opt) => opt.checked === 'on')
              .map((opt) => (opt as any).value),
          );
        }}
        emptyMessage="No filters available"
        noMatchesMessage="No filters found"
      >
        {(list, search) => (
          <div style={{ width: 300 }}>
            <EuiPopoverTitle paddingSize="s">{search}</EuiPopoverTitle>
            {list}
          </div>
        )}
      </EuiSelectable>
    </EuiPopover>
  );
};
