import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiMarkdownFormat,
  EuiText,
} from '@elastic/eui';
import { IncidentResponseDto } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { DateOccurred } from '../date-occurred';
import { Fragment } from 'react';

export interface IncidentDetailItemProps {
  incident: IncidentResponseDto;
}

export const IncidentDetailItem = observer((props: IncidentDetailItemProps) => {
  const { incident } = props;

  return (
    <EuiFlexGroup responsive={false} gutterSize="m" alignItems="center">
      <EuiFlexItem>
        <Fragment>
          <EuiText size="relative">{incident.summary}</EuiText>

          <DateOccurred date={incident.dateOccurred} />

          <div>
            <EuiMarkdownFormat
              textSize="xs"
              style={{ maxHeight: 30, overflow: 'clip' }}
            >
              {incident.description ?? ''}
            </EuiMarkdownFormat>
          </div>
        </Fragment>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
});
