import { EuiFieldNumber, EuiText } from '@elastic/eui';
import { CopResponseDto, IncidentInformationDto } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { MissingDataText } from '../common';
import { copTableColumns } from '../cop';
import { BasicTable, BasicTableColumn } from '../table';

type CopWithIncidentInformation = CopResponseDto & {
  incidentInformation?: IncidentInformationDto;
};

export interface ExistingCopsTableProps {
  cops: CopWithIncidentInformation[];
  editActions?: {
    onDelete: (copId: string) => void;
    onEditIncidentInfo: (
      copId: string,
      incidentInfo: IncidentInformationDto,
    ) => void;
  };
}

export const ExistingCopsTable = observer((props: ExistingCopsTableProps) => {
  const { cops, editActions } = props;

  if (!cops.length) {
    return (
      <EuiText color="subdued" size="relative">
        No cops recorded.
      </EuiText>
    );
  }

  const columns: BasicTableColumn<CopWithIncidentInformation>[] = [
    copTableColumns.name(),
    copTableColumns.department(),
    copTableColumns.position(),
    copTableColumns.badgeNumber(),
    {
      name: 'Shots fired',
      width: '120px',
      render: (cop: CopWithIncidentInformation) => {
        if (!editActions) {
          return (
            cop.incidentInformation?.shotsFired ?? (
              <MissingDataText text="N/A" />
            )
          );
        }

        return (
          <EuiFieldNumber
            aria-label="Enter the number of shots fired"
            value={cop.incidentInformation?.shotsFired ?? ''}
            min={0}
            onChange={(e) => {
              editActions.onEditIncidentInfo(cop.id, {
                ...cop.incidentInformation,
                shotsFired: e.target.value
                  ? Number.parseInt(e.target.value)
                  : undefined,
              });
            }}
          />
        );
      },
    },
  ];

  if (editActions) {
    columns.push({
      name: 'Actions',
      actions: [
        {
          name: 'Delete',
          description: 'Remove existing cop',
          type: 'icon',
          icon: 'trash',
          color: 'danger',
          onClick: (cop) => editActions.onDelete(cop.id),
        },
      ],
    });
  }

  return (
    <BasicTable
      hideResultsCount
      disablePagination
      columns={columns}
      result={{ items: cops }}
      subject="Cops"
    />
  );
});
