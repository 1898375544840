import {
  CopResponseDto,
  IncidentInformationDto,
  IncidentTagDto,
  LocationDto,
  MediaAssociationDto,
  Operation,
  PersonIncidentAssociationDto,
  UpsertCopDto,
  UpsertReportDto,
} from '@unfrl/copdb-sdk';
import { uniq } from 'lodash';
import { makeAutoObservable } from 'mobx';
import { RootStore } from '../stores/root.store';
import { logger } from '../utils';

/**
 * Observable implementation for the `UpsertReportDto` interface.
 */
export class EditReport implements UpsertReportDto {
  private readonly _root: RootStore;

  public title?: string | null;

  public existingCopIds?: string[] | null;

  public newCops?: UpsertCopDto[] | null;

  public mediaAssociations?: MediaAssociationDto[] | null;

  public existingCopIncidentInformations?:
    | PersonIncidentAssociationDto[]
    | null;

  public description?: string | null;

  public dateOccurred?: Date | null;

  public summary?: string | null;

  public preliminary?: boolean;

  public tags?: IncidentTagDto[] | null;

  public location?: LocationDto;

  public get existingCops(): CopResponseDto[] {
    if (!this.existingCopIds?.length) {
      return [];
    }

    return this.existingCopIds
      .map((existingId) => this._root.copStore.data.getItem(existingId))
      .filter(Boolean) as CopResponseDto[];
  }

  public get hasIncident(): boolean {
    return Boolean(this.summary || this.description);
  }

  /**
   * Returns true if optional parts of an Incident are filled in but
   * not any all the required ones
   */
  public get hasInvalidIncident(): boolean {
    return Boolean(
      (this.location ||
        this.dateOccurred ||
        this.preliminary ||
        this.tags?.length) &&
        !this.hasIncident,
    );
  }

  public get hasCops(): boolean {
    return Boolean(
      (this.newCops && this.newCops.length > 0) ||
        (this.existingCopIds && this.existingCopIds.length > 0),
    );
  }

  /**
   * Returns true if this report has sufficient data to be able to associate
   * with media items.
   */
  public get hasDataToAssociate(): boolean {
    return Boolean(
      this.hasIncident || this.existingCops.length || this.newCops?.length,
    );
  }

  public constructor(rootStore: RootStore, existingDto: UpsertReportDto = {}) {
    this._root = rootStore;
    this.updateFromDto(existingDto);
    makeAutoObservable(this);
  }

  public toDto = (): UpsertReportDto => {
    return {
      title: this.title,
      existingCopIds: this.existingCopIds,
      newCops: this.newCops,
      mediaAssociations: this.mediaAssociations,
      description: this.description,
      dateOccurred: this.dateOccurred,
      summary: this.summary,
      preliminary: this.preliminary,
      tags: this.tags,
      location: this.location,
      existingCopIncidentInformations: this.existingCopIncidentInformations,
    };
  };

  public updateFromDto = (dto: UpsertReportDto): void => {
    this.title = dto.title;
    this.existingCopIds = dto.existingCopIds;
    this.newCops = dto.newCops;
    this.mediaAssociations = dto.mediaAssociations;
    this.description = dto.description;
    this.dateOccurred = dto.dateOccurred;
    this.summary = dto.summary;
    this.preliminary = dto.preliminary;
    this.tags = dto.tags;
    this.location = dto.location;
    this.existingCopIncidentInformations = dto.existingCopIncidentInformations;
  };

  public updateTitle = (title: string): void => {
    this.title = title;
  };

  public updateLocation = (location?: LocationDto): void => {
    this.location = location;
  };

  public updateSummary = (summary: string | undefined): void => {
    this.summary = summary;
  };

  public updatePreliminary = (preliminary?: boolean): void => {
    this.preliminary = preliminary;
  };

  public updateTags = (tags?: IncidentTagDto[]): void => {
    this.tags = tags;
  };

  public updateDescription = (description: string | undefined): void => {
    this.description = description;
  };
  public updateDateOccured = (dateOccurred: Date | undefined): void => {
    this.dateOccurred = dateOccurred;
  };

  public updateExistingCopIncidentInfo = (
    copId: string,
    incidentInformation: IncidentInformationDto,
  ) => {
    const existingCop = this.existingCops?.find((cop) => cop.id === copId);
    if (!existingCop) {
      throw new Error(`No existing cop found for provided ID: ${copId}`);
    }

    const { personId } = existingCop;

    if (!this.existingCopIncidentInformations?.length) {
      this.existingCopIncidentInformations = [
        { incidentInformation, personId },
      ];
      return;
    }

    const infoIndex = this.existingCopIncidentInformations.findIndex(
      (info) => info.personId === personId,
    );
    if (infoIndex < 0) {
      this.existingCopIncidentInformations.push({
        incidentInformation,
        personId,
      });
    } else {
      this.existingCopIncidentInformations[infoIndex] = {
        incidentInformation,
        personId,
      };
    }
  };

  public updateNewCopIncidentInfo = (
    index: number,
    incidentInfo: IncidentInformationDto,
  ) => {
    if (this.newCops?.length) {
      this.newCops[index].incidentInformation = incidentInfo;
    }
  };

  public updateDateOccurred = (dateOccurred?: Date): void => {
    this.dateOccurred = dateOccurred;
  };

  public addNewCop = (newCop: UpsertCopDto): void => {
    if (this.newCops?.length) {
      this.newCops.push(newCop);
    } else {
      this.newCops = [newCop];
    }
  };

  public deleteNewCop = (index: number): void => {
    this.newCops?.splice(index, 1);
  };

  public updateNewCop = (index: number, newCop: UpsertCopDto): void => {
    if (this.newCops?.length) {
      this.newCops[index] = newCop;
    }
  };

  public addExistingCop = (cop: CopResponseDto) => {
    this.existingCopIds = uniq([...(this.existingCopIds ?? []), cop.id]);
    this._root.copStore.data.setItem(cop);
  };

  public deleteExistingCop = (copId: string): void => {
    if (!this.existingCopIds?.length) {
      return;
    }

    const index = this.existingCopIds.findIndex((id) => id === copId);
    if (!this.existingCopIds[index]) {
      return;
    }

    this.existingCopIds.splice(index, 1);

    if (!this.mediaAssociations?.length) {
      return;
    }

    const cop = this._root.copStore.data.getItem(copId);
    if (!cop) {
      logger.warn(
        'unable to delete mediaAssociations because cop not found in copStore',
      );
      return;
    }

    for (const association of this.mediaAssociations) {
      if (!association.personIds?.length) {
        continue;
      }

      const copAssociationIndex = association.personIds.indexOf(cop.personId);
      if (copAssociationIndex > -1) {
        association.personIds.splice(copAssociationIndex, 1);
      }
    }
  };

  public addMedia = (mediaIds: string[]): void => {
    const newAssociations: MediaAssociationDto[] = mediaIds.map((id) => ({
      mediaId: id,
    }));
    if (this.mediaAssociations?.length) {
      this.mediaAssociations.unshift(...newAssociations);
    } else {
      this.mediaAssociations = newAssociations;
    }
  };

  public deleteMedia = async (mediaId: string): Promise<void> => {
    await this._root.mediaStore.deleteMedia(mediaId);

    this.deleteMediaAssociations(mediaId);
  };

  public updateMediaAssociation = (
    mediaAssociation: MediaAssociationDto,
  ): void => {
    if (!this.mediaAssociations?.length) {
      return;
    }

    const indexToUpdate = this.mediaAssociations.findIndex(
      (association) => association.mediaId === mediaAssociation.mediaId,
    );
    if (indexToUpdate > -1) {
      this.mediaAssociations[indexToUpdate] = mediaAssociation;
    }
  };

  public associateExistingCopWithMedia = (
    mediaId: string,
    personId: string,
  ): void => {
    const mediaAssociation = this.mediaAssociations?.find(
      (association) => association.mediaId === mediaId,
    );
    if (!mediaAssociation) {
      return;
    }

    if (!mediaAssociation.personIds?.length) {
      mediaAssociation.personIds = [personId];
      return;
    }

    if (!mediaAssociation.personIds.find((id) => id === personId)) {
      mediaAssociation.personIds.push(personId);
    }
  };

  public unassociateExistingCopWithMedia = (
    mediaId: string,
    personId: string,
  ): void => {
    const mediaAssociation = this.mediaAssociations?.find(
      (association) => association.mediaId === mediaId,
    );
    if (!mediaAssociation?.personIds?.length) {
      return;
    }

    const index = mediaAssociation.personIds.findIndex((id) => id === personId);
    if (index > -1) {
      mediaAssociation.personIds.splice(index, 1);
    }
  };

  public associateNewCopWithMedia = (
    mediaId: string,
    copIndex: number,
  ): void => {
    if (!this.newCops?.length || !this.newCops[copIndex]) {
      return;
    }

    const newCop = this.newCops[copIndex];
    if (!newCop.personMediaDtos?.length) {
      newCop.personMediaDtos = [{ mediaId, operation: Operation.Upsert }];
      return;
    }

    if (!newCop.personMediaDtos.find((pmd) => pmd.mediaId === mediaId)) {
      newCop.personMediaDtos.push({ mediaId, operation: Operation.Upsert });
    }
  };

  public unassociateNewCopWithMedia = (
    mediaId: string,
    copIndex: number,
  ): void => {
    if (!this.newCops?.length || !this.newCops[copIndex]) {
      return;
    }

    const newCop = this.newCops[copIndex];
    if (!newCop.personMediaDtos?.length) {
      return;
    }

    const mediaIndex = newCop.personMediaDtos.findIndex(
      (pmd) => pmd.mediaId === mediaId,
    );
    if (mediaIndex > -1) {
      newCop.personMediaDtos.splice(mediaIndex, 1);
    }
  };

  private deleteMediaAssociations = (mediaId: string): void => {
    if (!this.mediaAssociations?.length) {
      return;
    }

    const mediaIndex = this.mediaAssociations.findIndex(
      (association) => association.mediaId === mediaId,
    );
    if (mediaIndex < 0) {
      return;
    }

    this.mediaAssociations.splice(mediaIndex, 1);

    if (!this.newCops?.length) {
      return;
    }

    for (const newCop of this.newCops) {
      if (!newCop.personMediaDtos?.length) {
        continue;
      }

      const copMediaIndex = newCop.personMediaDtos.findIndex(
        (pmd) => pmd.mediaId === mediaId,
      );
      if (copMediaIndex > -1) {
        newCop.personMediaDtos.splice(copMediaIndex, 1);
      }
    }
  };
}
