import { makeAutoObservable } from 'mobx';
import { generateId } from '../utils';

export interface ToastOption {
  color?: 'success' | 'warning' | 'danger';
  title: string;
  text?: string;
  iconType?: string;
}

export interface Toast extends ToastOption {
  id: string;
}

export class ToastStore {
  private _toastsMap: Map<string, Toast> = new Map();

  public get toasts(): Toast[] {
    return Array.from(this._toastsMap.values());
  }

  public constructor() {
    makeAutoObservable(this);
  }

  public showToast = (option: ToastOption): void => {
    const id = generateId(10);
    this._toastsMap.set(id, { ...option, id });
  };

  public hideToast = (id: string): void => {
    this._toastsMap.delete(id);
  };

  public showApiError = async (error: any): Promise<void> => {
    if (typeof error?.response?.json === 'function') {
      const { status, title, detail: text } = await error.response.json();
      this.showToast({
        title: status ? `${title} (${status})` : title,
        text,
        color: 'danger',
      });
    } else {
      this.showToast({
        title: 'Unable to complete action',
        text: 'An unknown error occured while communicating with the API',
        color: 'danger',
      });
    }
  };

  public showError = (title: string, text?: string): void => {
    this.showToast({
      title,
      text,
      color: 'danger',
    });
  };

  public showSuccess = (title: string, text?: string): void => {
    this.showToast({ title, text, color: 'success' });
  };
}
