import { EuiComboBox, EuiComboBoxOptionOption, EuiText } from '@elastic/eui';
import { IncidentResponseDto } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { Fragment, ReactNode, useCallback, useEffect, useState } from 'react';
import { apiClient } from '../../api';
import { logger } from '../../utils';
import { debounce } from 'lodash';
import { DateOccurred } from '../date-occurred';
import { MissingDataText } from '../common';

export interface IncidentSearchProps {
  onSelected: (incident: IncidentResponseDto) => void;
}

const mapIncidentsToOptions = (
  incidents: IncidentResponseDto[],
): EuiComboBoxOptionOption<IncidentResponseDto>[] => {
  return incidents.map((incident) => ({
    label: incident.summary ?? incident.id,
    key: incident.id,
    value: incident,
  }));
};

export const IncidentSearch = observer((props: IncidentSearchProps) => {
  const { onSelected } = props;
  const [incidentOptions, setIncidentOptions] = useState<IncidentResponseDto[]>(
    [],
  );
  const [loading, setLoading] = useState(false);

  const comboBoxOptions = mapIncidentsToOptions(incidentOptions);

  const handleSearchChange = useCallback(async (value: string) => {
    setLoading(true);
    setIncidentOptions([]);

    try {
      const { incidentResponses } = await apiClient.incidents.listIncidents({
        searchValue: value,
        sortField: 'summary',
        sortDirection: 'descending',
        page: 1,
        pageSize: 30,
      });

      setIncidentOptions(incidentResponses ?? []);
    } catch (error) {
      logger.error('error when fetching incidents', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    // trigger initial load
    handleSearchChange('');
  }, [handleSearchChange]);

  const renderOption = (
    option: EuiComboBoxOptionOption<IncidentResponseDto>,
    searchValue: string,
  ): ReactNode => {
    const { summary, id, location, dateOccurred } = option.value ?? {};
    return (
      <Fragment>
        <strong>{summary ?? id}</strong>
        <DateOccurred date={dateOccurred} />
        {location?.placeName ? (
          <EuiText size="s">{location?.placeName}</EuiText>
        ) : (
          <MissingDataText text="No location recorded." />
        )}
      </Fragment>
    );
  };

  return (
    <EuiComboBox
      singleSelection
      async
      fullWidth
      aria-label="Search incidents by summary"
      placeholder="Search incidents by summary"
      isLoading={loading}
      options={comboBoxOptions}
      renderOption={renderOption}
      onSearchChange={debounce(handleSearchChange, 100)}
      rowHeight={80}
      onChange={(opts) => {
        const [opt] = opts;
        const mappedToIncident = incidentOptions.find(
          (incident) => incident.id === opt.key!,
        );
        if (mappedToIncident) {
          onSelected(mappedToIncident);
        }
      }}
    />
  );
});
