import {
  EuiButton,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiHorizontalRule,
  EuiIcon,
  EuiLink,
  EuiSpacer,
  EuiText,
  EuiTextAlign,
} from '@elastic/eui';
import { observer } from 'mobx-react';
import { FormEvent, Suspense, lazy, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { apiClient } from '../../api';
import { useStores } from '../../hooks';
import { homePath, loginPath, signupPath, validateEmail } from '../../utils';
import { Flex, Logo } from '../common';
import { CAPTCHA_SITEKEY } from '../../api/config';

const HCaptcha = lazy(() => import('@hcaptcha/react-hcaptcha'));

export const RequestPasswordReset = observer(() => {
  const { toastStore, featuresStore } = useStores();

  const [email, setEmail] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');

  const [saving, setSaving] = useState(false);
  const [navigateHome, setNavigateHome] = useState<boolean>(false);

  const { captchaEnabled } = featuresStore.features;

  const canSubmit = () =>
    email && validateEmail(email) && (captchaEnabled ? captchaToken : true);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!canSubmit()) {
      return;
    }

    try {
      setSaving(true);
      await apiClient.accounts.passwordResetRequest({
        emailActionRequestDto: { email },
        captchaToken,
      });
      setEmail('');
      setCaptchaToken('');
      toastStore.showSuccess('Password reset request sent');
      setNavigateHome(true);
    } catch (error) {
      toastStore.showApiError(error);
    } finally {
      setSaving(false);
    }
  };

  if (navigateHome) {
    return <Navigate to={homePath()} replace={true} />;
  }

  return (
    <>
      <Flex column align="center">
        <Link to={homePath()}>
          <Logo size="lg" />
        </Link>
        <EuiSpacer size="s" />
        <EuiText>
          <EuiTextAlign textAlign="center">
            <h1>Request Password Reset</h1>
          </EuiTextAlign>
        </EuiText>
      </Flex>
      <EuiSpacer />
      <EuiForm component="form" onSubmit={handleSubmit}>
        <EuiFormRow label="Email" fullWidth>
          <EuiFieldText
            fullWidth
            name="email"
            type="email"
            prepend={<EuiIcon type="email" />}
            disabled={saving}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </EuiFormRow>
        {captchaEnabled && (
          <EuiFormRow label="Verify" fullWidth>
            <Suspense>
              <HCaptcha
                sitekey={CAPTCHA_SITEKEY}
                onVerify={(token, key) => setCaptchaToken(token)}
                onError={(info) => {
                  setCaptchaToken('');
                  toastStore.showError('Failed Captcha', info);
                }}
              />
            </Suspense>
          </EuiFormRow>
        )}

        <EuiSpacer />
        <EuiButton
          type="submit"
          size="s"
          fill
          fullWidth
          isLoading={saving}
          disabled={!canSubmit()}
        >
          Send Request
        </EuiButton>
      </EuiForm>
      <EuiHorizontalRule />
      <Flex align="center" column gap={8}>
        <Link to={loginPath()}>
          <EuiLink>Login with existing</EuiLink>
        </Link>
        {featuresStore.features.signUpEnabled ? (
          <Link to={signupPath()}>
            <EuiLink>Create an account</EuiLink>
          </Link>
        ) : null}
      </Flex>
    </>
  );
});
