import {
  EuiAccordion,
  EuiCallOut,
  EuiDescriptionList,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiPanel,
  EuiSpacer,
  EuiText,
} from '@elastic/eui';
import {
  CopResponseDto,
  Media,
  MediaAssociationDto,
  UpsertCopDto,
} from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { Fragment } from 'react';
import {
  mapResponsesToDetailItems,
  mapUpsertDtosToDetailItems,
  mediaUtils,
} from '../../utils';
import { Flex } from '../common';
import { CopDetailList } from '../cop';
import { MediaPreview } from './media-preview';
import { MediaProcessedStatus } from './media-processed-status';

export interface MediaPreviewListProps {
  mediaList: Media[];
  /**
   * Provide the mediaAssociations and any relevant newCops so we can render media associations
   */
  mediaAssociations?: MediaAssociationDto[] | null;
  newCops?: UpsertCopDto[] | null;
  existingCops?: CopResponseDto[] | null;
  hideIncidentAssociation?: boolean;
  displayMediaDetailsLink?: boolean;
}

const MediaAssociation = observer(
  ({
    mediaAssociation,
    newCops,
    existingCops,
    hideIncidentAssociation,
  }: {
    mediaAssociation: MediaAssociationDto;
    newCops?: UpsertCopDto[] | null;
    existingCops?: CopResponseDto[] | null;
    hideIncidentAssociation?: boolean;
  }) => {
    const existingCopsInAssociation =
      existingCops?.filter((existing) =>
        mediaAssociation?.personIds?.includes(existing.personId),
      ) ?? [];
    const newCopsInAssociation =
      newCops?.filter((newCop) =>
        newCop.personMediaDtos?.find(
          (pmd) => pmd.mediaId === mediaAssociation.mediaId,
        ),
      ) ?? [];
    const hasCopAssociations =
      existingCopsInAssociation.length || newCopsInAssociation.length;
    const hasIncidentAssociation = Boolean(mediaAssociation?.includesIncident);

    if (!hasIncidentAssociation && !hasCopAssociations) {
      return (
        <EuiEmptyPrompt
          iconType="alert"
          iconColor="danger"
          title={<h4>No data associated</h4>}
          titleSize="xxs"
          paddingSize="none"
          body={
            <EuiText size="s">
              This media has not been associated with an incident or any cops.
            </EuiText>
          }
        />
      );
    }

    const allCops = [
      ...mapResponsesToDetailItems(existingCopsInAssociation),
      ...mapUpsertDtosToDetailItems(
        newCopsInAssociation.map((dto) => ({
          ...dto,
          // TODO: hack to prevent incident info from rendering next to media preview
          incidentInformation: undefined,
        })),
      ),
    ];

    return (
      <Flex column gap={8}>
        {hasIncidentAssociation && !hideIncidentAssociation ? (
          <EuiCallOut title="Incident associated" iconType="document" size="s">
            <EuiText size="s">
              This media contains details related to the incident.
            </EuiText>
          </EuiCallOut>
        ) : null}
        {hasCopAssociations ? (
          <EuiDescriptionList
            compressed
            listItems={[
              {
                title: 'Cops associated',
                description: <CopDetailList cops={allCops} />,
              },
            ]}
          />
        ) : null}
      </Flex>
    );
  },
);

/**
 * Simplified version of `MediaPreviewList`: does not separate media by type and
 * does not provide editing operations. MediaPreviewList is getting a
 * little unwieldy so didn't want to add anything to it.
 */
export const MediaPreviewListReadonly = observer(
  (
    props: Omit<
      MediaPreviewListProps,
      'hideIncidentAssociation' | 'displayMediaDetailsLink'
    >,
  ) => {
    const { mediaList, newCops, existingCops, mediaAssociations } = props;

    if (!mediaList.length) {
      return (
        <EuiText color="subdued" size="relative">
          No media uploaded.
        </EuiText>
      );
    }

    const renderAssociations = (media: Media) => {
      const mediaAssociation = mediaAssociations?.find(
        (association) => association.mediaId === media.id,
      );
      if (!mediaAssociation) {
        return null;
      }

      return (
        <MediaAssociation
          mediaAssociation={mediaAssociation}
          newCops={newCops}
          existingCops={existingCops}
        />
      );
    };

    return (
      <Flex column gap={8}>
        {mediaList.map((media) => (
          <EuiPanel
            key={media.id}
            hasBorder
            paddingSize="s"
            color="transparent"
          >
            <EuiFlexGroup>
              <EuiFlexItem
                style={{ justifyContent: 'center', alignItems: 'center' }}
              >
                <MediaPreview media={media} />
              </EuiFlexItem>
              {mediaAssociations ? (
                <EuiFlexItem>{renderAssociations(media)}</EuiFlexItem>
              ) : null}
            </EuiFlexGroup>
            <EuiHorizontalRule margin="s" />
            <Flex>
              <MediaProcessedStatus media={media} />
            </Flex>
          </EuiPanel>
        ))}
      </Flex>
    );
  },
);

export const MediaPreviewList = observer((props: MediaPreviewListProps) => {
  const {
    mediaList,
    newCops,
    existingCops,
    mediaAssociations,
    hideIncidentAssociation,
    displayMediaDetailsLink,
  } = props;

  if (!mediaList.length) {
    return (
      <EuiText color="subdued" size="relative">
        No media uploaded.
      </EuiText>
    );
  }

  const renderAssociations = (media: Media) => {
    const mediaAssociation = mediaAssociations?.find(
      (association) => association.mediaId === media.id,
    );
    if (!mediaAssociation) {
      return null;
    }

    return (
      <MediaAssociation
        mediaAssociation={mediaAssociation}
        newCops={newCops}
        existingCops={existingCops}
        hideIncidentAssociation={hideIncidentAssociation}
      />
    );
  };

  const renderList = (title: string, filteredMedia: Media[]) => {
    return (
      <EuiAccordion
        id={title}
        buttonContent={`${title} (${filteredMedia.length})`}
        initialIsOpen={filteredMedia.length > 0}
        paddingSize="s"
      >
        <Flex column gap={8}>
          {filteredMedia.map((media) => (
            <EuiPanel
              key={media.id}
              hasBorder
              paddingSize="s"
              color="transparent"
            >
              <EuiFlexGroup>
                <EuiFlexItem
                  style={{ justifyContent: 'center', alignItems: 'center' }}
                >
                  <MediaPreview
                    media={media}
                    displayMediaDetailsLink={displayMediaDetailsLink}
                  />
                </EuiFlexItem>
                {mediaAssociations ? (
                  <EuiFlexItem>{renderAssociations(media)}</EuiFlexItem>
                ) : null}
              </EuiFlexGroup>
              {mediaAssociations ? (
                <Fragment>
                  <EuiHorizontalRule margin="s" />
                  <Flex>
                    <MediaProcessedStatus media={media} />
                  </Flex>
                </Fragment>
              ) : null}
            </EuiPanel>
          ))}
          {!filteredMedia.length ? (
            <EuiText color="subdued" textAlign="center" size="relative">
              Nothing.
            </EuiText>
          ) : null}
        </Flex>
      </EuiAccordion>
    );
  };

  return (
    <Fragment>
      {renderList('Documents', mediaList.filter(mediaUtils.isDocument))}
      <EuiSpacer size="s" />
      {renderList('Images', mediaList.filter(mediaUtils.isImage))}
      <EuiSpacer size="s" />
      {renderList('Videos', mediaList.filter(mediaUtils.isVideo))}
      <EuiSpacer size="s" />
      {renderList('Audio', mediaList.filter(mediaUtils.isAudio))}
      <EuiSpacer size="s" />
      {renderList('Other', mediaList.filter(mediaUtils.isOther))}
    </Fragment>
  );
});
