import { EuiCheckableCard, EuiIcon, EuiText } from '@elastic/eui';
import { observer } from 'mobx-react';
import { Flex } from '../common';

export interface MediaIncidentAssociationProps {
  id: string;
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
}

export const MediaIncidentAssociation = observer(
  (props: MediaIncidentAssociationProps) => {
    const { id, checked, onCheckedChange } = props;

    return (
      <EuiCheckableCard
        id={id}
        label={
          <Flex gap={8} align="center">
            <EuiIcon type="documents" />
            <EuiText size="relative">
              Contains information about the incident
            </EuiText>
          </Flex>
        }
        checkableType="checkbox"
        checked={checked}
        onChange={() => onCheckedChange(!checked)}
      />
    );
  },
);
