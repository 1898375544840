import {
  MediaRelationType,
  Operation,
  UpsertMediaRelationDto,
} from '@unfrl/copdb-sdk';
import { useStores } from '../../hooks';
import { EuiIcon, EuiPanel, EuiText, EuiToolTip } from '@elastic/eui';
import { Flex } from '../common';
import { IncidentDetailItem } from '../incident';
import { CSSProperties } from 'react';

export interface MediaEditIncidentDiffProps {
  mediaRelationDtos: UpsertMediaRelationDto[];
}

export const MediaEditIncidentDiff = (props: MediaEditIncidentDiffProps) => {
  const { incidentStore } = useStores();

  const { mediaRelationDtos } = props;

  const incidentRelationDtos = mediaRelationDtos.filter(
    (mrd) => mrd.type === MediaRelationType.Incident,
  );

  if (!incidentRelationDtos.length) {
    return null;
  }

  const incidentPanelStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 90,
    minWidth: 250,
  };

  const elementResults: React.ReactElement[] = [];

  incidentRelationDtos.forEach((mrd) => {
    const incident = incidentStore.data.orderedItems.find(
      (inc) => inc.id === mrd.id,
    );
    if (!incident) {
      return;
    }

    elementResults.push(
      <EuiPanel
        grow={false}
        color={mrd.operation === Operation.Upsert ? 'success' : 'danger'}
        paddingSize="s"
        key={incident.id}
        style={incidentPanelStyle}
      >
        <EuiToolTip
          title={mrd.operation === Operation.Upsert ? 'Added' : 'Removed'}
        >
          <Flex align={'center'} style={{ height: 50 }} gap={8}>
            <EuiIcon
              type={mrd.operation === Operation.Upsert ? 'plus' : 'cross'}
            />
            <IncidentDetailItem incident={incident} />
          </Flex>
        </EuiToolTip>
      </EuiPanel>,
    );
  });

  return (
    <EuiPanel>
      <EuiText color="subdued">{'Incidents'}:</EuiText>
      <Flex wrap align={'center'} gap={8}>
        {elementResults}
      </Flex>
    </EuiPanel>
  );
};
