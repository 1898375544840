import {
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiSpacer,
  EuiText,
} from '@elastic/eui';
import { IncidentTagDto } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useState } from 'react';
import { apiClient } from '../../api';
import { logger } from '../../utils';
import { Flex } from '../common';

export interface IncidentTagSelectProps {
  tags?: IncidentTagDto[];
  onChange?: (newTags: IncidentTagDto[] | undefined) => void;
}

export const IncidentTagSelect = observer((props: IncidentTagSelectProps) => {
  const { tags, onChange } = props;

  const tagToOption = (
    tag: IncidentTagDto,
  ): EuiComboBoxOptionOption<IncidentTagDto> => ({
    label: tag.name,
    value: {
      name: tag.name,
      description: tag.description,
    },
  });

  const selected = tags?.map(tagToOption);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] =
    useState<EuiComboBoxOptionOption<IncidentTagDto>[]>();

  const getTagOptions = useCallback(async () => {
    setLoading(true);
    try {
      const optionsRaw = await apiClient.tags.listIncidentTagOptions();

      const parsedOptions: EuiComboBoxOptionOption<IncidentTagDto>[] =
        optionsRaw.map(tagToOption);

      setOptions(parsedOptions);
    } catch (error) {
      logger.error('error when fetching incident tag options', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    // trigger initial load
    getTagOptions();
  }, []);

  const renderOption = (option: EuiComboBoxOptionOption<IncidentTagDto>) => {
    if (!option.value) {
      return <p>Missing tag information</p>;
    }

    const { name, description } = option.value;
    return (
      <Flex column style={{ whiteSpace: 'initial' }}>
        <strong>{name}</strong>
        <EuiSpacer size="xs" />
        <EuiText size="s" color="subdued">
          <p>{description}</p>
        </EuiText>
      </Flex>
    );
  };

  return (
    <EuiComboBox
      rowHeight={100}
      fullWidth
      isLoading={loading}
      aria-label="Select incident tags"
      placeholder="Incident tags"
      options={options}
      selectedOptions={selected}
      onChange={(newTags) => {
        if (onChange) {
          onChange(newTags.map((nt) => nt.value!));
        }
      }}
      renderOption={renderOption}
    />
  );
});
