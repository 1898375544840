import { CopResponseDto } from '@unfrl/copdb-sdk';
import { makeAutoObservable } from 'mobx';
import { apiClient } from '../api';
import { DataStore } from './data.store';

export class CopStore {
  public data: DataStore<CopResponseDto> = new DataStore();

  public constructor() {
    makeAutoObservable(this);
  }

  public fetchCopsByPersonIds = async (personIds: string[]): Promise<void> => {
    if (!personIds.length) {
      return;
    }

    const { cops } = await apiClient.cops.listCops({
      personIds,
      page: 1,
      pageSize: personIds.length,
    });

    if (cops?.length) {
      this.data.setList(cops);
    }
  };

  /**
   * Fetches cops by their IDs, skipping any that are in local store unless
   * `forceFetch` is set to true.
   */
  public fetchCops = async (
    ids: string[],
    forceFetch: boolean = false,
  ): Promise<void> => {
    const idsToFetch = forceFetch
      ? ids
      : ids.filter((id) => !this.data.itemExists(id));
    if (!idsToFetch.length) {
      return;
    }

    const { cops } = await apiClient.cops.listCops({
      ids: idsToFetch,
      page: 1,
      pageSize: idsToFetch.length,
    });
    if (cops?.length) {
      this.data.setList(cops);
    }
  };
}
