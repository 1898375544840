import {
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiProgress,
  EuiText,
} from '@elastic/eui';
import { Media } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { UploadingMedia } from '../../stores';
import { DeleteConfirm, Flex } from '../common';
import { MediaProcessedStatus } from './media-processed-status';

export type MediaUploadAction = 'delete' | 'cancel';

export interface MediaUploadToolbarProps {
  media: Media;
  uploading?: UploadingMedia;
  onAction: (action: MediaUploadAction) => Promise<void>;
}

export const MediaUploadToolbar = observer((props: MediaUploadToolbarProps) => {
  const { media, uploading, onAction } = props;

  const handleAction = (action: MediaUploadAction) => async () => {
    await onAction(action);
  };

  const deleteMedia = (
    <DeleteConfirm
      disabled={uploading && !uploading.error}
      onDelete={handleAction('delete')}
      buttonText="Delete"
      text="Delete this media? This cannot be undone."
    />
  );

  if (!uploading) {
    return (
      <Flex justify="space-between">
        <MediaProcessedStatus media={media} />
        {deleteMedia}
      </Flex>
    );
  }

  if (uploading.error) {
    return (
      <Flex align="center" justify="space-between" gap={8}>
        <Flex gap={8}>
          <EuiIcon type="alert" color="danger" />
          <EuiText size="s">
            {uploading.error || 'Unknown error occurred'}
          </EuiText>
        </Flex>
        <Flex>{deleteMedia}</Flex>
      </Flex>
    );
  }

  return (
    <EuiFlexGroup
      alignItems="flexStart"
      justifyContent="spaceBetween"
      gutterSize="s"
      responsive={false}
    >
      <EuiFlexItem>
        <EuiProgress
          label="Uploading..."
          valueText
          value={uploading.progress ?? 0}
          max={100}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButtonEmpty size="s" onClick={handleAction('cancel')} color="text">
          Cancel
        </EuiButtonEmpty>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
});
