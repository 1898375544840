import {
  CriteriaWithPagination,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiHorizontalRule,
  EuiInMemoryTable,
  EuiSpacer,
} from '@elastic/eui';
import { observer } from 'mobx-react';
import React, { Fragment } from 'react';
import { ResultsCount } from './results-count';

export type BasicTableColumn<T extends object> = EuiBasicTableColumn<T>;

export interface FilteredResult<T> {
  count?: number;
  pageNumber?: number;
  pageSize?: number;
  items: T[];
}

export interface BasicTableProps<T extends object> {
  /**
   * Wrapper around `EuiBasicTableColumn` just to keep the types for this
   * component consistent.
   */
  columns: BasicTableColumn<T>[];
  result: FilteredResult<T>;
  /**
   * Used for displaying results counts, e.g. 1-20 of 40 **Incidents**.
   */
  subject: string;
  /**
   * Provide this callback if handling pagination via API requests, otherwise
   * table defaults to in-memory and expects to have all items up front.
   */
  onTableChange?: (criteria: CriteriaWithPagination<T>) => void;
  sort?: {
    field: keyof T | string;
    direction: 'asc' | 'desc';
  };
  loading?: boolean;
  expandedRowsMap?: { [key: string]: React.ReactNode };
  hideResultsCount?: boolean;
  /**
   * Set to true to disable table pagination. Only applicable for in-memory tables.
   */
  disablePagination?: boolean;
}

export const BasicTable = observer(
  <T extends { id?: string | null }>(props: BasicTableProps<T>) => {
    const {
      columns,
      result,
      subject,
      loading,
      onTableChange,
      sort,
      expandedRowsMap,
      hideResultsCount,
      disablePagination,
    } = props;

    const pageIndex = result.pageNumber ? result.pageNumber - 1 : 0;
    const pageSize = result.pageSize ?? 10;
    const totalItemCount = result?.count ?? 0;

    const pagination = {
      pageIndex,
      pageSize,
      totalItemCount,
    };

    const sorting = sort ? ({ sort } as any) : undefined;

    const renderTable = () => {
      if (onTableChange) {
        return (
          <EuiBasicTable
            tableCaption={subject}
            items={result.items ?? []}
            itemId="id"
            itemIdToExpandedRowMap={expandedRowsMap}
            loading={loading}
            columns={columns}
            pagination={pagination}
            sorting={sorting}
            onChange={onTableChange}
            noItemsMessage={loading ? 'Loading...' : undefined}
          />
        );
      }

      return (
        <EuiInMemoryTable
          tableCaption={subject}
          items={result.items ?? []}
          itemId="id"
          itemIdToExpandedRowMap={expandedRowsMap}
          columns={columns}
          pagination={!!disablePagination ? false : true}
          sorting={sorting}
          loading={loading}
          message={loading ? 'Loading...' : undefined}
        />
      );
    };

    return (
      <Fragment>
        {hideResultsCount ? null : (
          <Fragment>
            <ResultsCount
              pageIndex={pageIndex}
              pageSize={pageSize}
              totalCount={totalItemCount}
              subject={subject}
            />
            <EuiSpacer size="s" />
            <EuiHorizontalRule margin="none" style={{ height: 2 }} />
          </Fragment>
        )}
        {renderTable()}
      </Fragment>
    );
  },
);
