import {
  EuiButton,
  EuiButtonEmpty,
  EuiFilePicker,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiFormRow,
  EuiHorizontalRule,
  EuiTitle,
  useGeneratedHtmlId,
} from '@elastic/eui';
import { Operation, UpsertCopDto } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useStores } from '../../hooks';
import {
  FileSizes,
  formatName,
  getProfilePhotoFromUpsert,
  logger,
  validateCopDepartmentDtos,
} from '../../utils';
import { Flex } from '../common';
import { CopAvatar } from './cop-avatar';
import { CopBasicInputs } from './cop-basic-inputs';
import { CopDepartmentsInput } from './cop-departments-input';

export interface CopNewFormProps {
  onClose: () => void;
  onSave: (copDto: UpsertCopDto) => void;
  existing?: UpsertCopDto;
}

export const CopNewForm = observer((props: CopNewFormProps) => {
  const { onClose, onSave, existing } = props;
  const { mediaStore, toastStore } = useStores();
  const [copDto, setCopDto] = useState<UpsertCopDto>({ ...existing });
  const [uploading, setUploading] = useState(false);
  const filePickerRef: any = useRef();

  useEffect(() => {
    // handle media dtos changing outside of store, because we manage the modified copDto state in this component
    if (copDto.personMediaDtos?.length) {
      mediaStore.fetchMedias(copDto.personMediaDtos.map((dto) => dto.mediaId!));
    }
  }, [copDto.personMediaDtos]);

  const canSave = () => {
    const hasValues = Object.values(copDto).some((value) => !!value);

    const departmentErrors = validateCopDepartmentDtos(
      copDto.copDepartmentDtos ?? [],
    );
    const hasDepartmentErrors = [...departmentErrors.values()].some(
      (val) => val.length > 0,
    );

    return hasValues && !hasDepartmentErrors;
  };

  const formFlyoutId = useGeneratedHtmlId({
    prefix: 'copFormFlyoutTitle',
  });

  const handleUpdateNewCop = <K extends keyof UpsertCopDto>(
    key: K,
    value: UpsertCopDto[K],
  ) => {
    setCopDto({
      ...copDto,
      [key]: value,
    });
  };

  const handleSave = () => {
    if (canSave()) {
      onSave(copDto);
    }
  };

  const handleFilesChange = async (files: FileList | null) => {
    const file = files?.length ? Array.from(files)[0] : null;
    if (!file) {
      handleUpdateNewCop('personMediaDtos', []);
      return;
    }

    if (file.size > FileSizes.oneGb) {
      const error = `The provided file, ${file.name}, is too large. Uploads must be 1Gb or less`;
      toastStore.showError(error);
      filePickerRef?.current?.removeFiles();
      return;
    }

    try {
      setUploading(true);

      const media = await mediaStore.createMedia(file, true);

      const mediaDtos = copDto.personMediaDtos ?? [];

      handleUpdateNewCop('personMediaDtos', [
        { mediaId: media.id, profilePhoto: true, operation: Operation.Upsert },
        ...mediaDtos,
      ]);

      filePickerRef?.current?.removeFiles();
    } catch (error: any) {
      logger.error('failed to upload file', error);
      await toastStore.showApiError(error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <EuiFlyout maxWidth={500} onClose={onClose}>
      <EuiFlyoutHeader hasBorder aria-labelledby={formFlyoutId}>
        <EuiTitle>
          <h2 id={formFlyoutId}>Cop form</h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <Fragment>
          <EuiFormRow fullWidth label="Photo">
            <Flex align="center">
              <CopAvatar
                size="s"
                name={formatName(
                  copDto.firstName,
                  copDto.middleName,
                  copDto.lastName,
                )}
                // TODO: handle case where media still being processed OR preview image from file
                photoUrl={getProfilePhotoFromUpsert(copDto)}
              />
              <div style={{ marginRight: 16 }} />
              <EuiFilePicker
                fullWidth
                accept="image/*"
                multiple={false}
                onChange={handleFilesChange}
                disabled={uploading}
                isLoading={uploading}
                initialPromptText="Select or drag and drop an image"
              />
            </Flex>
          </EuiFormRow>
          <CopBasicInputs
            copDto={copDto}
            onChange={(dto) => setCopDto({ ...copDto, ...dto })}
          />
          <EuiHorizontalRule />
          <CopDepartmentsInput
            copDepartmentDtos={copDto.copDepartmentDtos ?? []}
            onChange={(copDepartmentDtos) =>
              setCopDto({ ...copDto, copDepartmentDtos: copDepartmentDtos })
            }
          />
        </Fragment>
      </EuiFlyoutBody>
      <EuiFlyoutFooter>
        <Flex align="center" justify="flex-end" gap={16}>
          <EuiButtonEmpty
            size="s"
            iconType="cross"
            color="text"
            onClick={onClose}
          >
            Cancel
          </EuiButtonEmpty>
          <EuiButton fill size="s" onClick={handleSave} disabled={!canSave()}>
            Save
          </EuiButton>
        </Flex>
      </EuiFlyoutFooter>
    </EuiFlyout>
  );
});
