import { useNavigate } from 'react-router-dom';

/**
 * Returns a function that converts `to` value to href + onClick handler. Useful
 * for integrating react-router with Eui components.
 */
export const useNavProps = () => {
  const navigate = useNavigate();

  /**
   * Function for href + onclick handler.
   * @param to - The path to navigate to
   * @param extraFn - Optional extra function that will be called before nav
   */
  const getNavProps = (to: string, extraFn?: Function) => {
    const href = to;
    const onClick = (e: any) => {
      if (e.ctrlKey || e.metaKey) {
        return;
      }

      if (e.preventDefault) {
        e.preventDefault();
      }

      if (extraFn) {
        extraFn();
      }

      navigate(to);
    };

    return { href, onClick };
  };

  return getNavProps;
};
