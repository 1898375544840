import { EuiCallOut } from '@elastic/eui';
import { Media } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react-lite';
import { MediaProcessedStatuses, mediaUtils } from '../../utils';

export interface MediaProcessedStatusProps {
  media: Media;
}

export const MediaProcessedStatus = observer(
  (props: MediaProcessedStatusProps) => {
    const { media } = props;

    const mediaStatus = mediaUtils.getProcessingStatus(media);
    let text = 'Media processing';
    let color: 'warning' | 'success' | 'danger' = 'warning';

    if (mediaStatus === MediaProcessedStatuses.Processed) {
      text = 'Media processed';
      color = 'success';
    } else if (mediaStatus === MediaProcessedStatuses.Processing) {
      text = 'Media processing';
      color = 'warning';
    } else {
      text = 'Failed to process';
      color = 'danger';
    }

    return (
      <EuiCallOut size="s" color={color}>
        {text}
      </EuiCallOut>
    );
  },
);
