import {
  EuiButtonIcon,
  EuiScreenReaderOnly,
  RIGHT_ALIGNMENT,
} from '@elastic/eui';
import { BasicTableColumn } from './basic-table';

export interface ExpanderColumnOptions {
  isExpanded: (id: string) => boolean;
  onToggle: (id: string) => void;
  showText?: string;
  hideText?: string;
}

export const expanderColumn = <T extends { id: string }>(
  options: ExpanderColumnOptions,
): BasicTableColumn<T> => {
  const { isExpanded, onToggle, showText, hideText } = options;

  const show = showText ?? 'Expand';
  const hide = hideText ?? 'Collapse';

  return {
    align: RIGHT_ALIGNMENT,
    width: '40px',
    isExpander: true,
    name: (
      <EuiScreenReaderOnly>
        <span>Expand rows</span>
      </EuiScreenReaderOnly>
    ),
    render: (item: T) => {
      const expanded = isExpanded(item.id);

      return (
        <EuiButtonIcon
          title={expanded ? hide : show}
          onClick={() => onToggle(item.id)}
          aria-label={expanded ? hide : show}
          iconType={expanded ? 'arrowUp' : 'arrowDown'}
        />
      );
    },
  };
};
