import { EuiSpacer, EuiTitle } from '@elastic/eui';
import { PublicUserProfileDto } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { Fragment } from 'react';
import { ReportDetails } from '../../stores/report.store';
import { ReportMetadata } from './report-metadata';

export interface ReportViewHeaderProps {
  title: string;
  submitted: ReportDetails;
  author?: PublicUserProfileDto | null;
}

export const ReportViewHeader = observer((props: ReportViewHeaderProps) => {
  const { title, submitted, author } = props;
  const { report } = submitted;

  return (
    <Fragment>
      <EuiTitle>
        <h1>{title}</h1>
      </EuiTitle>
      <EuiSpacer size="s" />
      <ReportMetadata
        status={report.status}
        createdAt={report.createdAt}
        userName={author?.userName}
      />
    </Fragment>
  );
});
