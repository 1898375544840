import {
  EuiComboBox,
  EuiHorizontalRule,
  EuiIcon,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTextColor,
} from '@elastic/eui';
import {
  EditStatus,
  IncidentEdit,
  IncidentResponseDto,
  IncidentTagDto,
  LocationDto,
  MediaAssociationDto,
  PersonIncidentAssociationDto,
  UpsertIncidentDto,
} from '@unfrl/copdb-sdk';
import { isEmpty, isEqual, uniq } from 'lodash';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useStores } from '../../hooks';
import { EditIncidentViewModel } from '../../models';
import {
  camelToSentence,
  dateUtils,
  formatEditCitation,
  locationToString,
  logger,
  mapIncidentToUpsertDto,
} from '../../utils';
import { Flex, TextDiff } from '../common';
import { EditAction, EditActions } from '../moderate';
import { IncidentEditCopDiff } from './incident-edit-cop-diff';
import { IncidentForm } from './incident-form';
import { MediaAssociationEditDiff } from './media-association-edit-diff';

export interface IncidentEditItemProps {
  incident: IncidentResponseDto;
  edit: IncidentEdit;
}

export const IncidentEditItem = observer((props: IncidentEditItemProps) => {
  const { incidentEditStore, dialogStore, toastStore, authStore } = useStores();
  const { incident, edit } = props;
  const [editingIncident, setEditingIncident] =
    useState<EditIncidentViewModel | null>(null);

  const disableApproveReason =
    !authStore.isAdmin && authStore.user?.id === edit.createdBy
      ? 'Non-Admin users cannot approve edits they created'
      : undefined;

  const { editDto } = edit;
  const originalDto = mapIncidentToUpsertDto(incident);

  const handleShowForm = () => {
    setEditingIncident(
      incidentEditStore.getEditIncidentViewModel(incident, editDto),
    );
  };

  const handleCloseForm = () => {
    setEditingIncident(null);
  };

  const handleAction = (action: EditAction) => {
    try {
      switch (action) {
        case EditAction.Approve:
          return incidentEditStore.updateIncidentEditStatus(
            edit.id,
            EditStatus.Approved,
          );
        case EditAction.Deny:
          return incidentEditStore.updateIncidentEditStatus(
            edit.id,
            EditStatus.Denied,
          );
        case EditAction.Overwrite:
          return handleShowForm();
      }
    } catch (error) {
      logger.error('failed to handle incident edit action', error);
      toastStore.showApiError(error);
    }
  };

  const handleSaveOverwrite = async (dto: UpsertIncidentDto) => {
    await incidentEditStore.overwriteIncidentEdit(incident.id, dto);
  };

  const handleConfirmOverwrite = (dto: UpsertIncidentDto) => {
    dialogStore.openDialog({
      title: 'Overwrite this edit?',
      content:
        'This will apply your changes and remove all other remaining edits.',
      confirmText: 'Overwrite',
      onConfirm: () => handleSaveOverwrite(dto),
    });
  };

  const editableKeys = uniq([
    ...Object.keys(originalDto),
    ...Object.keys(editDto),
  ]).sort((a, b) => (b > a ? 1 : -1));

  return (
    <EuiPanel hasShadow={false} color="subdued">
      <Flex column gap={8}>
        {editableKeys.map((key) => {
          const objKey = key as keyof UpsertIncidentDto;

          let before = originalDto[objKey];
          let after = editDto[objKey];

          if (objKey === 'preliminary') {
            before = String(before || false);
            after = String(after || false);

            return before !== after ? (
              <Flex wrap gap={8} align="center" key={objKey}>
                <EuiText color="subdued">{'Preliminary'}:</EuiText>
                <EuiTextColor color="danger">{before}</EuiTextColor>
                <EuiTextColor color="success">{after}</EuiTextColor>
              </Flex>
            ) : null;
          }

          if (objKey === 'dateOccurred') {
            before = before ? dateUtils.format(before as Date) : '';
            after = after ? dateUtils.format(after as Date) : '';
          }

          if (isEmpty(before) && isEmpty(after)) {
            return null;
          }

          if (isEqual(before, after)) {
            return null;
          }

          if (objKey === 'tags') {
            const beforeTags = before ? (before as IncidentTagDto[]) : [];
            const afterTags = after ? (after as IncidentTagDto[]) : [];
            const addedTags = afterTags
              .filter((val) => !beforeTags.some((bt) => bt.name === val.name))
              .map((tag) => ({
                key: tag.name,
                label: tag.name,
                color: 'success',
                prepend: <EuiIcon size="s" type="plus" />,
              }));
            const removedTags = beforeTags
              .filter((val) => !afterTags.some((at) => at.name === val.name))
              .map((tag) => ({
                key: tag.name,
                label: tag.name,
                color: 'danger',
                prepend: <EuiIcon size="s" type="cross" />,
              }));
            const unchangedTags = beforeTags
              .filter((val) => afterTags.some((at) => at.name === val.name))
              .map((tag) => ({
                key: tag.name,
                label: tag.name,
              }));

            const changedTags = [...addedTags, ...removedTags];

            return (
              <Flex align="center" key={objKey}>
                <EuiText
                  size="s"
                  color="subdued"
                  style={{ wordBreak: 'keep-all', marginRight: 8 }}
                >
                  Tags:
                </EuiText>
                <EuiSpacer size="s" />
                <EuiComboBox
                  aria-label="Incident tags"
                  selectedOptions={[...changedTags, ...unchangedTags]}
                  isDisabled
                />
              </Flex>
            );
          }

          if (objKey === 'location') {
            before = locationToString(before as LocationDto);
            after = locationToString(after as LocationDto);
            if (before === after) {
              return null;
            }

            return (
              <Flex wrap align="center" key={objKey}>
                <EuiText color="subdued">{'Location'}:</EuiText>
                <div style={{ marginRight: 8 }} />
                <Flex column>
                  <EuiTextColor color="danger">{before}</EuiTextColor>
                  <EuiTextColor color="success">{after}</EuiTextColor>
                </Flex>
              </Flex>
            );
          }

          if (objKey === 'personIncidentAssociations') {
            return (
              <IncidentEditCopDiff
                before={before as PersonIncidentAssociationDto[]}
                after={after as PersonIncidentAssociationDto[]}
                key={key}
              />
            );
          }

          if (objKey === 'mediaAssociations') {
            return (
              <MediaAssociationEditDiff
                key={key}
                before={before as MediaAssociationDto[]}
                after={after as MediaAssociationDto[]}
              />
            );
          }

          return (
            <TextDiff
              key={key}
              label={camelToSentence(key)}
              beforeText={before?.toString()}
              afterText={after?.toString()}
            />
          );
        })}
      </Flex>
      <EuiHorizontalRule margin="s" />
      <Flex justify="space-between" align="center">
        <EuiText
          color="subdued"
          size="s"
          title={dateUtils.format(edit.createdAt)}
        >
          {formatEditCitation(edit)}
        </EuiText>
        <EditActions
          onAction={handleAction}
          disableApproveReason={disableApproveReason}
        />
      </Flex>
      {editingIncident && (
        <IncidentForm
          title="Overwrite edit"
          onClose={handleCloseForm}
          onSave={handleConfirmOverwrite}
          editIncident={editingIncident}
        />
      )}
    </EuiPanel>
  );
});
