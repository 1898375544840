import dateMath from '@elastic/datemath';
import {
  EuiDatePicker,
  EuiFilterGroup,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSuperDatePicker,
  EuiSwitch,
} from '@elastic/eui';
import { IncidentTagDto } from '@unfrl/copdb-sdk';
import moment from 'moment';
import { FilterSelect, Flex } from '../common';
import { IncidentTagSelect } from './incident-tag-select';
import { CustomQuickSelectRenderOptions } from '@elastic/eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover';

export interface IncidentFilterItems {
  preliminary?: boolean;
  startDate?: Date;
  endDate?: Date | 'now';
  dateFilterEnabled: boolean;
  tags?: IncidentTagDto[];
}

export interface IncidentTableFiltersProps {
  values: IncidentFilterItems;
  onChange: (filters: IncidentFilterItems) => void;
}

export const IncidentTableFilters = (props: IncidentTableFiltersProps) => {
  const { values, onChange } = props;

  const { preliminary, startDate, endDate, dateFilterEnabled, tags } = values;

  const parsedStartDate = startDate ? moment(startDate).toString() : undefined;
  const parsedEndDate = endDate ? moment(endDate).toString() : undefined;

  const quickSelectPanel = (_: CustomQuickSelectRenderOptions) => {
    return (
      <EuiFormRow label="Select a date">
        <EuiDatePicker
          selected={moment(startDate)}
          maxDate={moment()}
          onChange={(date) => {
            const newStart = date?.startOf('day')?.toDate();
            const newEnd = date?.endOf('day')?.toDate();
            if (newStart) {
              onChange({
                ...values,
                startDate: newStart,
                endDate: newEnd,
              });
            }
          }}
        />
      </EuiFormRow>
    );
  };

  return (
    <Flex gap={8} column>
      <EuiFlexGroup gutterSize="s">
        <EuiFlexItem style={{ alignSelf: 'center' }}>
          <EuiFlexGroup gutterSize="xs" alignItems="center">
            <EuiFlexItem style={{ minWidth: 140 }}>
              <EuiSwitch
                label="Filter by date"
                checked={dateFilterEnabled}
                onChange={(e) => {
                  onChange({
                    ...values,
                    dateFilterEnabled: e.target.checked,
                  });
                }}
              />
            </EuiFlexItem>
            <EuiSuperDatePicker
              showUpdateButton={false}
              isDisabled={
                !dateFilterEnabled ? { display: <p>Disabled</p> } : false
              }
              start={parsedStartDate}
              end={parsedEndDate}
              onTimeChange={(e) => {
                const startDate = dateMath.parse(e.start)?.toDate();
                const endDate =
                  e.end === 'now'
                    ? 'now'
                    : dateMath.parse(e.end, { roundUp: true })?.toDate();

                if (startDate) {
                  onChange({
                    ...values,
                    startDate,
                    endDate,
                  });
                }
              }}
              customQuickSelectRender={quickSelectPanel}
            />
          </EuiFlexGroup>
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiFilterGroup>
            <FilterSelect
              label="Preliminary"
              options={['Yes', 'No']}
              values={
                preliminary === true
                  ? ['Yes']
                  : preliminary === false
                  ? ['No']
                  : undefined
              }
              singleSelection
              onChange={(selected) => {
                onChange({
                  ...values,
                  preliminary: selected?.includes('Yes')
                    ? true
                    : selected?.includes('No')
                    ? false
                    : undefined,
                });
              }}
            />
          </EuiFilterGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
      <Flex gap={8}>
        <IncidentTagSelect
          tags={tags}
          onChange={(newTags) =>
            onChange({
              ...values,
              tags: newTags,
            })
          }
        />
      </Flex>
    </Flex>
  );
};
