import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiFormRow,
  EuiSpacer,
  EuiText,
  EuiTitle,
  useGeneratedHtmlId,
} from '@elastic/eui';
import { observer } from 'mobx-react';
import { Fragment, useState } from 'react';
import { CopSearch, CopDetailList } from '../cop';
import {
  CopResponseDto,
  IncidentResponseDto,
  Media,
  MediaRelationType,
  MediaUpdateDto,
  Operation,
  UpsertMediaRelationDto,
} from '@unfrl/copdb-sdk';
import { mapResponseToDetailItem } from '../../utils';
import { isEqual } from 'lodash';
import { Flex } from '../common';
import { IncidentDetailList, IncidentSearch } from '../incident';

export interface MediaEditFormProps {
  onClose: () => void;
  onSave: (mediaUpdateDto: MediaUpdateDto) => void;
  incidents: IncidentResponseDto[];
  cops: CopResponseDto[];
  media: Media;
  saving: boolean;
}

export const MediaEditForm = observer((props: MediaEditFormProps) => {
  const { onClose, onSave, incidents, cops, media, saving } = props;
  const flyoutId = useGeneratedHtmlId({ prefix: 'mediaEditFormFlyout' });

  const [editedDisplayName, setEditedDisplayName] = useState(
    media.displayName ?? '',
  );
  const [editedCops, setEditedCops] = useState<CopResponseDto[]>([...cops]);
  const [editedIncidents, setEditedIncidents] = useState<IncidentResponseDto[]>(
    [...incidents],
  );

  const canSave = () =>
    !isEqual(
      cops.map((c) => c.id),
      editedCops.map((c) => c.id),
    ) ||
    !isEqual(
      incidents.map((i) => i.id),
      editedIncidents.map((i) => i.id),
    ) ||
    !isEqual(media.displayName ?? '', editedDisplayName);

  const handleSave = () => {
    if (canSave()) {
      const upsertCopRelations: UpsertMediaRelationDto[] = editedCops
        .filter((ec) => !cops.some((c) => c.id === ec.id))
        .map((c) => ({
          type: MediaRelationType.Person,
          id: c.personId,
          operation: Operation.Upsert,
        }));
      const deleteCopRelations: UpsertMediaRelationDto[] = cops
        .filter((c) => !editedCops.some((ec) => ec.id === c.id))
        .map((c) => ({
          type: MediaRelationType.Person,
          id: c.personId,
          operation: Operation.Delete,
        }));
      const upsertIncidentRelations: UpsertMediaRelationDto[] = editedIncidents
        .filter((ei) => !incidents.some((i) => i.id === ei.id))
        .map((i) => ({
          type: MediaRelationType.Incident,
          id: i.id,
          operation: Operation.Upsert,
        }));
      const deleteIncidentRelations: UpsertMediaRelationDto[] = incidents
        .filter((i) => !editedIncidents.some((ei) => ei.id === i.id))
        .map((i) => ({
          type: MediaRelationType.Incident,
          id: i.id,
          operation: Operation.Delete,
        }));

      onSave({
        displayName: editedDisplayName,
        upsertMediaRelationDtos: [
          ...upsertCopRelations,
          ...deleteCopRelations,
          ...upsertIncidentRelations,
          ...deleteIncidentRelations,
        ],
      });
    }
  };

  return (
    <EuiFlyout maxWidth={500} onClose={onClose}>
      <EuiFlyoutHeader hasBorder aria-labelledby={flyoutId}>
        <EuiTitle>
          <h2 id={flyoutId}>{'Suggest edit'}</h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiText color="subdued">
          Media records can specify which Cops and Incidents they are related
          to. These relationships are meant to show which Cops/Incidents are
          present or directly mentioned in the Media.
        </EuiText>
        <EuiSpacer size="m" />
        <EuiFormRow fullWidth label="Display name">
          <EuiFieldText
            compressed
            fullWidth
            value={editedDisplayName ?? ''}
            onChange={(e) => {
              setEditedDisplayName(e.target.value);
            }}
            maxLength={70}
            placeholder="Media display name"
          />
        </EuiFormRow>
        <EuiFormRow fullWidth label="Cops">
          <Fragment>
            <CopSearch
              onSelected={(cop) => {
                if (!editedCops.find((c) => c.id === cop.id)) {
                  setEditedCops([...editedCops, cop]);
                }
              }}
            />
            <EuiSpacer size="s" />
            <CopDetailList
              cops={editedCops.map((cop) => mapResponseToDetailItem(cop))}
              onDelete={(index) => {
                const updatedCops = [...editedCops];
                updatedCops.splice(index, 1);
                setEditedCops(updatedCops);
              }}
            />
          </Fragment>
        </EuiFormRow>
        <EuiFormRow fullWidth label="Incidents">
          <Fragment>
            <IncidentSearch
              onSelected={(incident) => {
                if (!editedIncidents.find((i) => i.id === incident.id)) {
                  setEditedIncidents([...editedIncidents, incident]);
                }
              }}
            />
            <EuiSpacer size="s" />
            <IncidentDetailList
              incidents={editedIncidents}
              onDelete={(index) => {
                const updatedIncidents = [...editedIncidents];
                updatedIncidents.splice(index, 1);
                setEditedIncidents(updatedIncidents);
              }}
            />
          </Fragment>
        </EuiFormRow>
      </EuiFlyoutBody>
      <EuiFlyoutFooter>
        <Flex align="center" justify="flex-end" gap={16}>
          <EuiButtonEmpty
            size="s"
            iconType="cross"
            color="text"
            onClick={onClose}
            disabled={saving}
          >
            Cancel
          </EuiButtonEmpty>
          <EuiButton
            fill
            size="s"
            onClick={handleSave}
            isLoading={saving}
            disabled={!canSave()}
          >
            Submit
          </EuiButton>
        </Flex>
      </EuiFlyoutFooter>
    </EuiFlyout>
  );
});
