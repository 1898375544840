import { dateUtils } from './date.utils';

export const formatEditCitation = (editInformation: {
  createdAt: Date;
  createdByUsername?: string | null;
}): string => {
  const { createdAt, createdByUsername } = editInformation;

  return (
    `submitted ${dateUtils.fromNow(createdAt)}` +
    (createdByUsername ? ` by ${createdByUsername}` : '')
  );
};
