import { EuiImage, EuiSpacer } from '@elastic/eui';
import { Media } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { MediaLink } from './media-link';
import { Flex } from '../../common';

export interface ImagePreviewProps {
  media: Media;
  displayMediaDetailsLink?: boolean;
}
export const ImagePreview = observer((props: ImagePreviewProps) => {
  const { media, displayMediaDetailsLink } = props;
  return (
    <>
      <EuiImage
        hasShadow={false}
        color="transparent"
        allowFullScreen
        size="l"
        alt={media.name!}
        src={media.url ?? ''}
        loading="lazy"
      />
      <EuiSpacer size="s" />
      <Flex justify="center">
        <MediaLink
          media={media}
          displayMediaDetailsLink={displayMediaDetailsLink}
        />
      </Flex>
    </>
  );
});
