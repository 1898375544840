import { EuiProvider } from '@elastic/eui';
import '@elastic/eui/dist/eui_theme_dark.css';
import { observer } from 'mobx-react';
import { Fragment, lazy, Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { AuthRequired, ErrorElement, FeatureRequired } from './components';
import ModerateRequired from './components/auth/moderate-required';

const ConfirmDialog = lazy(() => import('./components/common/confirm-dialog'));
const Toasts = lazy(() => import('./components/common/toasts'));
const AppLayout = lazy(() => import('./components/layout/app-layout'));
const Auth = lazy(() => import('./routes/auth'));
const InvitedUser = lazy(() => import('./routes/invited-user'));
const ResetPassword = lazy(() => import('./routes/reset-password'));
const Home = lazy(() => import('./routes/home'));
const Media = lazy(() => import('./routes/media'));
const MediaEdits = lazy(() => import('./routes/media-edits'));
const Incident = lazy(() => import('./routes/incident'));
const Incidents = lazy(() => import('./routes/incidents'));
const Departments = lazy(() => import('./routes/departments'));
const Department = lazy(() => import('./routes/department-profile'));
const Cops = lazy(() => import('./routes/cops'));
const CopProfile = lazy(() => import('./routes/cop-profile'));
const Report = lazy(() => import('./routes/report'));
const Reports = lazy(() => import('./routes/reports'));
const NotFound = lazy(() => import('./routes/not-found'));
const ManageUsers = lazy(() => import('./routes/manage-users'));
const AdminRequired = lazy(() => import('./components/auth/admin-required'));
const VerifyEmail = lazy(() => import('./routes/verify-email'));
const UserProfile = lazy(() => import('./routes/user-profile'));

/**
 * Note: this is the app entry point, so we want to keep it as lean as possible.
 * Only critical components should be rendered here, defer everything else to lazy loads.
 */
export const App = observer(() => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Fragment>
        <Route path="/" errorElement={<ErrorElement />} element={<AppLayout />}>
          <Route index element={<Home />} />
          <Route path="cops" element={<Cops />} />
          <Route path="cops/:id" element={<CopProfile />} />
          <Route path="medias/:mediaId" element={<Media />} />
          <Route
            path="media-edits"
            element={
              <ModerateRequired isRoute>
                <MediaEdits />
              </ModerateRequired>
            }
          />
          <Route path="incidents" element={<Incidents />} />
          <Route path="incidents/:incidentId" element={<Incident />} />
          <Route path="departments" element={<Departments />} />
          <Route path="departments/:departmentId" element={<Department />} />
          <Route path="reports" element={<Reports />} />
          <Route path="reports/:reportId" element={<Report />} />
          <Route
            path="users"
            element={
              <AdminRequired isRoute>
                <ManageUsers />
              </AdminRequired>
            }
          />
          <Route
            path="user-profile"
            element={
              <AuthRequired>
                <UserProfile />
              </AuthRequired>
            }
          />
        </Route>
        <Route
          path="login"
          errorElement={<ErrorElement />}
          element={<Auth mode="login" />}
        />
        <Route
          path="signup"
          errorElement={<ErrorElement />}
          element={
            <FeatureRequired feature="signUpEnabled">
              <Auth mode="signup" />
            </FeatureRequired>
          }
        />
        <Route
          path="verify-email"
          errorElement={<ErrorElement />}
          element={
            <FeatureRequired feature="signUpEnabled">
              <VerifyEmail />
            </FeatureRequired>
          }
        />

        <Route
          path="invited-user"
          errorElement={<ErrorElement />}
          element={
            <FeatureRequired feature="invitesEnabled">
              <InvitedUser />
            </FeatureRequired>
          }
        />
        <Route
          path="forgot-password"
          errorElement={<ErrorElement />}
          element={<Auth mode="forgot-password" />}
        />
        <Route
          path="reset-password"
          errorElement={<ErrorElement />}
          element={<ResetPassword />}
        />

        <Route
          path="*"
          errorElement={<ErrorElement />}
          element={<NotFound />}
        />
      </Fragment>,
    ),
  );

  return (
    <HelmetProvider>
      <EuiProvider colorMode="dark">
        <Suspense fallback={null}>
          <RouterProvider router={router} />
          <Toasts />
          <ConfirmDialog />
        </Suspense>
      </EuiProvider>
    </HelmetProvider>
  );
});
