import { EuiPanel } from '@elastic/eui';
import { observer } from 'mobx-react';
import { EditReport } from '../../models';
import { Flex } from '../common';
import { MediaUpload } from './media-upload';

export interface MediaUploadListProps {
  report: EditReport;
}

export const MediaUploadList = observer((props: MediaUploadListProps) => {
  const { report } = props;

  if (!report.mediaAssociations?.length) {
    return null;
  }

  return (
    <Flex column gap={8}>
      {report.mediaAssociations.map((mediaAssociation) => {
        return (
          <EuiPanel
            key={mediaAssociation.mediaId}
            hasBorder
            paddingSize="s"
            color="transparent"
          >
            <MediaUpload mediaAssociation={mediaAssociation} report={report} />
          </EuiPanel>
        );
      })}
    </Flex>
  );
});
