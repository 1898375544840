import { CopEdit, CopResponseDto, EditStatus } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useExpandedMap, useStores } from '../../hooks';
import { CopEditMapping } from '../../stores';
import { logger } from '../../utils';
import { BasicTable, BasicTableColumn, expanderColumn } from '../table';
import { CopEditList } from './cop-edit-list';
import { CopNameCell } from './cop-table-columns';

const copColumn = (): BasicTableColumn<CopEditMapping> => {
  return {
    field: 'cop',
    name: 'Cop',
    render: (cop: CopResponseDto) => <CopNameCell cop={cop} />,
  };
};

const pendingEditsColumn = (): BasicTableColumn<CopEditMapping> => {
  return {
    field: 'edits',
    name: 'Pending edits',
    render: (edits: CopEdit[]) => `${edits.length}`,
  };
};

export const CopEditTable = observer(() => {
  const [ready, setReady] = useState(false);
  const { copEditStore, toastStore } = useStores();

  const renderExpanded = (id: string) => {
    const mapping = copEditStore.data.getItem(id);
    if (!mapping) {
      return null;
    }

    return <CopEditList mapping={mapping} />;
  };

  const { expandedMap, isExpanded, toggleExpanded } =
    useExpandedMap(renderExpanded);

  const columns = [
    copColumn(),
    pendingEditsColumn(),
    expanderColumn({
      isExpanded,
      onToggle: toggleExpanded,
      showText: 'Show edits',
      hideText: 'Hide edits',
    }),
  ];

  useEffect(() => {
    const load = async () => {
      try {
        setReady(false);
        await copEditStore.fetchCopEdits(EditStatus.Pending);
      } catch (error) {
        logger.error('failed to load cop edits', error);
        await toastStore.showApiError(error);
      } finally {
        setReady(true);
      }
    };

    load();

    return () => {
      copEditStore.data.clearItems();
    };
  }, []);

  return (
    <BasicTable
      columns={columns}
      loading={!ready}
      result={{
        items: copEditStore.data.orderedItems,
        count: copEditStore.data.items.size,
      }}
      subject="Cop edits"
      expandedRowsMap={expandedMap}
    />
  );
});
