import { DepartmentResponseDto } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks';
import { BasicTable, BasicTableColumn, BasicTableProps } from '../table';
import { departmentTableColumns } from './department-table-columns';

export interface DepartmentTableProps
  extends Pick<
    BasicTableProps<DepartmentResponseDto>,
    'result' | 'onTableChange' | 'loading' | 'sort'
  > {
  name?: string;
  onEdit?: (dto: DepartmentResponseDto) => void;
}

export const DepartmentTable = observer((props: DepartmentTableProps) => {
  const { name, result, onEdit, ...rest } = props;
  const { authStore } = useStores();

  const columns: BasicTableColumn<DepartmentResponseDto>[] = [
    departmentTableColumns.name(name),
    departmentTableColumns.shortName(),
    departmentTableColumns.type(),
    departmentTableColumns.location(),
    departmentTableColumns.createdAt(),
  ];

  if (onEdit && authStore.isAdmin) {
    columns.push(
      departmentTableColumns.actions((dto) => {
        onEdit(dto);
      }),
    );
  }

  return (
    <BasicTable
      {...rest}
      result={result}
      columns={columns}
      subject="Departments"
    />
  );
});
