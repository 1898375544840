// https://stackoverflow.com/a/39718708/3757297
export const camelToSentence = (camelCase: string | undefined): string => {
  if (!camelCase) {
    return '';
  }
  return camelCase
    .replaceAll(/([A-Z])/g, (match) => ` ${match.toLowerCase()}`)
    .trimStart()
    .replace(/^./, (match) => match.toUpperCase())
    .trim();
};

// https://stackoverflow.com/a/46181
export const validateEmail = (email: string) => {
  return email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const pluralize = (
  count: number | undefined | null,
  noun: string,
  suffix = 's',
) => `${count ?? 0} ${noun}${(count ?? 0) !== 1 ? suffix : ''}`;

export const truncate = (
  str: string | null | undefined,
  maxLength: number,
): string | undefined => {
  if (!str) {
    return undefined;
  }
  return str.length > maxLength ? str.slice(0, maxLength - 1) + '…' : str;
};
