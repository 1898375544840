import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiFormRow,
  EuiHorizontalRule,
  EuiTitle,
  useGeneratedHtmlId,
} from '@elastic/eui';
import { UpsertCopDto } from '@unfrl/copdb-sdk';
import { isEqual } from 'lodash';
import { observer } from 'mobx-react';
import { useState } from 'react';
import {
  formatName,
  getProfilePhotoFromUpsert,
  validateCopDepartmentDtos,
} from '../../utils';
import { Flex } from '../common';
import { CopAvatar } from './cop-avatar';
import { CopBasicInputs } from './cop-basic-inputs';
import { CopDepartmentsInput } from './cop-departments-input';
import { CopEditMediaInput } from './cop-edit-media-input';

export interface CopEditFormProps {
  copDto: UpsertCopDto;
  onClose: () => void;
  onSave: (copDto: UpsertCopDto) => void;
  saving: boolean;
  title?: string;
}

export const CopEditForm = observer((props: CopEditFormProps) => {
  const { onClose, copDto, saving, title, onSave } = props;
  const [editedDto, setEditedDto] = useState({ ...copDto });
  const flyoutId = useGeneratedHtmlId({ prefix: 'copEditFormFlyout' });

  const handleDtoChange = (dto: UpsertCopDto) => {
    setEditedDto({ ...editedDto, ...dto });
  };

  const canSave = () => {
    const valuesChanged = !isEqual(copDto, editedDto);
    const departmentErrors = validateCopDepartmentDtos(
      editedDto.copDepartmentDtos ?? [],
    );
    const hasDepartmentErrors = [...departmentErrors.values()].some(
      (val) => val.length > 0,
    );

    return valuesChanged && !hasDepartmentErrors;
  };

  const handleSave = () => {
    if (canSave()) {
      onSave(editedDto);
    }
  };

  return (
    <EuiFlyout maxWidth={500} onClose={onClose}>
      <EuiFlyoutHeader hasBorder aria-labelledby={flyoutId}>
        <EuiTitle>
          <h2 id={flyoutId}>{title || 'Suggest edit'}</h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiFormRow fullWidth label="Profile photo">
          <Flex justify="center">
            <CopAvatar
              size="s"
              name={formatName(
                editedDto.firstName,
                editedDto.middleName,
                editedDto.lastName,
              )}
              photoUrl={getProfilePhotoFromUpsert(editedDto)}
            />
          </Flex>
        </EuiFormRow>
        <CopBasicInputs copDto={editedDto} onChange={handleDtoChange} />
        <EuiHorizontalRule />
        <CopDepartmentsInput
          copDepartmentDtos={editedDto.copDepartmentDtos ?? []}
          onChange={(copDepartmentDtos) =>
            setEditedDto({ ...editedDto, copDepartmentDtos })
          }
        />
        <EuiHorizontalRule />
        <CopEditMediaInput
          personMediaDtos={editedDto.personMediaDtos ?? []}
          onChange={(personMediaDtos) =>
            setEditedDto({ ...editedDto, personMediaDtos })
          }
        />
      </EuiFlyoutBody>
      <EuiFlyoutFooter>
        <Flex align="center" justify="flex-end" gap={16}>
          <EuiButtonEmpty
            size="s"
            iconType="cross"
            color="text"
            onClick={onClose}
            disabled={saving}
          >
            Cancel
          </EuiButtonEmpty>
          <EuiButton
            fill
            size="s"
            onClick={handleSave}
            isLoading={saving}
            disabled={!canSave()}
          >
            Submit
          </EuiButton>
        </Flex>
      </EuiFlyoutFooter>
    </EuiFlyout>
  );
});
