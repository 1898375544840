import { IncidentResponseDto } from '@unfrl/copdb-sdk';
import { makeAutoObservable } from 'mobx';
import { apiClient } from '../api';
import { DataStore } from './data.store';
import { RootStore } from './root.store';

export class IncidentStore {
  private readonly _root: RootStore;

  public data: Omit<
    DataStore<IncidentResponseDto>,
    'activeId' | 'activeItem' | 'setActive' | 'clearActive'
  > = new DataStore();

  public constructor(rootStore: RootStore) {
    this._root = rootStore;
    makeAutoObservable(this);
  }

  public fetchIncidents = async (incidentIds: string[]) => {
    if (!incidentIds.length) {
      return;
    }

    const { incidentResponses } = await apiClient.incidents.listIncidents({
      incidentIds,
      page: 1,
      pageSize: incidentIds.length,
    });

    if (!incidentResponses?.length) {
      return;
    }

    this.data.setList(incidentResponses);

    // TODO: some components only reference the copStore for showing cops associated with incident, eventually this should be removed when those areas are refactored
    const cops = incidentResponses.flatMap((incident) => incident.cops ?? []);
    if (cops.length) {
      this._root.copStore.data.setList(cops);
    }
  };
}
