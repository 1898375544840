import {
  EuiButton,
  EuiButtonEmpty,
  EuiFormRow,
  EuiPanel,
  EuiSpacer,
} from '@elastic/eui';
import { Operation, UpsertPersonMediaDto } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { Fragment, useEffect, useState } from 'react';
import { useStores } from '../../hooks';
import { mediaUtils } from '../../utils';
import { Flex } from '../common';
import { MediaForm } from '../media';
import { MediaPreview } from '../media/media-preview';

export interface CopEditMediaInputProps {
  personMediaDtos: UpsertPersonMediaDto[];
  onChange: (dtos: UpsertPersonMediaDto[]) => void;
}

export const CopEditMediaInput = observer((props: CopEditMediaInputProps) => {
  const { personMediaDtos, onChange } = props;
  const { mediaStore } = useStores();
  const [newMediaIds, setNewMediaIds] = useState<string[]>([]);

  useEffect(() => {
    if (personMediaDtos.length) {
      mediaStore.fetchMedias(personMediaDtos.map((dto) => dto.mediaId));
    }
  }, [personMediaDtos]);

  const handleMediaAdded = (mediaIds: string[]) => {
    const newMediaDtos: UpsertPersonMediaDto[] = mediaIds.map((mediaId) => ({
      mediaId,
      profilePhoto: false,
      operation: Operation.Upsert,
    }));
    setNewMediaIds([...newMediaIds, ...mediaIds]);
    onChange([...newMediaDtos, ...personMediaDtos]);
  };

  const handleUpdateMediaAssociation = (
    mediaId: string,
    operation: Operation,
  ) => {
    if (newMediaIds.includes(mediaId)) {
      const index = personMediaDtos.findIndex(
        (pmdto) => pmdto.mediaId === mediaId,
      );
      if (index >= 0) {
        personMediaDtos.splice(index, 1);
        onChange(personMediaDtos);
      }
      return;
    }

    const updated = personMediaDtos.map((dto) => {
      if (dto.mediaId === mediaId) {
        return {
          ...dto,
          operation: operation,
          profilePhoto:
            operation === Operation.Delete ? false : dto.profilePhoto,
        };
      }
      return dto;
    });

    onChange(updated);
  };

  const handleToggleProfilePhoto = (mediaId: string) => {
    const updated = personMediaDtos.map((dto) => ({
      ...dto,
      profilePhoto: dto.mediaId === mediaId ? !dto.profilePhoto : false,
    }));

    onChange(updated);
  };

  const renderMediaList = () => {
    if (!personMediaDtos.length) {
      return null;
    }

    return (
      <Fragment>
        <EuiSpacer size="m" />
        <EuiFormRow fullWidth label="Manage media">
          <Flex column gap={8}>
            {personMediaDtos.map((dto) => {
              const media = mediaStore.data.getItem(dto.mediaId);
              if (!media) {
                return null;
              }

              return (
                <EuiPanel
                  key={media.id}
                  hasShadow={false}
                  hasBorder
                  color={dto.operation === 'delete' ? 'danger' : 'plain'}
                >
                  <Flex justify="center">
                    <MediaPreview media={media} />
                  </Flex>
                  <EuiSpacer size="m" />
                  <Flex justify="end" gap={16}>
                    {mediaUtils.isImage(media) && dto.operation !== 'delete' ? (
                      <EuiButton
                        color="text"
                        size="s"
                        iconType={dto.profilePhoto ? 'cross' : 'userAvatar'}
                        fill={dto.profilePhoto}
                        onClick={() => handleToggleProfilePhoto(media.id)}
                      >
                        {dto.profilePhoto
                          ? 'Clear profile photo'
                          : 'Set as profile photo'}
                      </EuiButton>
                    ) : null}
                    {dto.operation === 'delete' ? (
                      <EuiButton
                        color="danger"
                        size="s"
                        iconType="editorUndo"
                        onClick={() =>
                          handleUpdateMediaAssociation(
                            media.id,
                            Operation.Upsert,
                          )
                        }
                      >
                        Keep
                      </EuiButton>
                    ) : (
                      <EuiButtonEmpty
                        color="danger"
                        size="s"
                        iconType="trash"
                        onClick={() =>
                          handleUpdateMediaAssociation(
                            media.id,
                            Operation.Delete,
                          )
                        }
                      >
                        {newMediaIds.includes(media.id) ? 'Delete' : 'Remove'}
                      </EuiButtonEmpty>
                    )}
                  </Flex>
                </EuiPanel>
              );
            })}
          </Flex>
        </EuiFormRow>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <MediaForm onMediaAdded={handleMediaAdded} />
      {renderMediaList()}
    </Fragment>
  );
});
