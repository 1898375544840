import { EuiText } from '@elastic/eui';
import { ReportStatus } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { dateUtils } from '../../utils';
import { Flex } from '../common';
import { ReportStatusBadge } from './report-status-badge';

const Spacer = () => <div style={{ marginRight: 8 }} />;

export interface ReportMetadataProps {
  status: ReportStatus;
  createdAt: Date;
  userName?: string;
}

export const ReportMetadata = observer((props: ReportMetadataProps) => {
  const { status, createdAt, userName } = props;

  return (
    <Flex align="center">
      <ReportStatusBadge status={status} style={{ marginBottom: 4 }} />
      <Spacer />
      <EuiText color="subdued" size="s" title={dateUtils.format(createdAt)}>
        {userName} created {dateUtils.fromNow(createdAt)}
      </EuiText>
      <Spacer />
    </Flex>
  );
});
