import {
  AuthenticateResponse,
  PasswordResetDto,
  SignUpWithInviteDto,
  TfaSignInDto,
  UserProfileDto,
  VerifyEmailDto,
} from '@unfrl/copdb-sdk';
import { makeAutoObservable } from 'mobx';
import { apiClient, rtmClient } from '../api';
import { logger } from '../utils';
import { RootStore } from './root.store';

const ACCESS_TOKEN_KEY = 'authStore.accessToken';

export enum AuthRole {
  Administrators = 'Administrators',
  Moderators = 'Moderators',
}

export enum AuthStatus {
  Initializing,
  Ready,
  Authenticating,
}

export class AuthStore {
  private readonly _root: RootStore;

  public status: AuthStatus = AuthStatus.Initializing;

  public accessToken: string = '';

  public tfaSessionToken: string = '';

  public requiresEmailVerification: boolean = false;

  public user: UserProfileDto | null = null;

  public get authenticated() {
    return !!this.user;
  }

  public get isAdmin() {
    return this.hasRole(AuthRole.Administrators);
  }

  /**
   * Returns true if user has moerator role or higher (e.g. admin).
   */
  public get isModerator() {
    return this.hasRole(AuthRole.Moderators) || this.isAdmin;
  }

  public constructor(rootStore: RootStore) {
    this._root = rootStore;

    makeAutoObservable(this);

    rtmClient.user.onUpdated(this.handleUserUpdated);

    this.setAccessToken(localStorage.getItem(ACCESS_TOKEN_KEY) ?? '');
    this.initialize();

    window.addEventListener('storage', this.handleStorageEvent);
  }

  public hasRole = (role: AuthRole): boolean => {
    return !!this.user?.roles?.find((r) => r === role);
  };

  public resetPasswordWithToken = async (
    passwordResetDto: PasswordResetDto,
  ) => {
    this.setStatus(AuthStatus.Authenticating);
    try {
      await apiClient.accounts.resetPasswordWithToken({
        passwordResetDto: passwordResetDto,
      });
    } finally {
      this.setStatus(AuthStatus.Ready);
    }
  };

  public signUpWithInvite = async (
    signUpWithInviteDto: SignUpWithInviteDto,
    captchaToken?: string,
  ) => {
    this.setStatus(AuthStatus.Authenticating);
    try {
      const response = await apiClient.accounts.signUpWithInvite({
        signUpWithInviteDto,
        captchaToken,
      });

      await this.handleAuthResponse(response);
    } finally {
      this.setStatus(AuthStatus.Ready);
    }
  };

  public verifyEmail = async (verifyEmailDto: VerifyEmailDto) => {
    this.setStatus(AuthStatus.Authenticating);
    try {
      const response = await apiClient.accounts.verifySignUp({
        verifyEmailDto,
      });

      await this.handleAuthResponse(response);
    } finally {
      this.setStatus(AuthStatus.Ready);
    }
  };

  public signUp = async (
    userName: string,
    email: string,
    password: string,
    captchaToken?: string,
  ): Promise<void> => {
    this.setStatus(AuthStatus.Authenticating);

    try {
      await apiClient.accounts.signUp({
        signUpDto: { userName, email, password },
        captchaToken,
      });
    } finally {
      this.setStatus(AuthStatus.Ready);
    }
  };

  public signIn = async (email: string, password: string): Promise<void> => {
    this.setStatus(AuthStatus.Authenticating);
    this.setRequiresEmailVerification(false);

    try {
      const response = await apiClient.accounts.signIn({
        authenticateRequest: { email, password },
      });

      await this.handleAuthResponse(response);
    } finally {
      this.setStatus(AuthStatus.Ready);
    }
  };

  public signInTfa = async (dto: TfaSignInDto): Promise<void> => {
    this.setStatus(AuthStatus.Authenticating);

    try {
      const response = await apiClient.accounts.tfaSignIn({
        tfaSignInDto: dto,
      });

      await this.handleAuthResponse(response);
    } finally {
      this.setStatus(AuthStatus.Ready);
      this.setTfaSessionToken('');
    }
  };

  public requestEmailVerification = async (
    email: string,
    captchaToken?: string,
  ): Promise<void> => {
    await apiClient.accounts.requestEmailVerification({
      emailActionRequestDto: { email },
      captchaToken,
    });
  };

  private handleStorageEvent = async (event: StorageEvent): Promise<void> => {
    if (event.key !== ACCESS_TOKEN_KEY) {
      return;
    }

    const valuesMissing = !this.accessToken && !event.newValue;
    const valuesMatch = this.accessToken === event.newValue;
    if (valuesMissing || valuesMatch) {
      logger.info('state', 'no auth storage changes, ignoring event', {
        valuesMissing,
        valuesMatch,
      });
      return;
    }

    if (!event.newValue) {
      logger.info(
        'state',
        'user logged out in another tab, logging this tab out as well',
      );
      this.logout();
      return;
    }

    logger.info(
      'state',
      'user logged in from another tab, initializing user profile',
    );

    this.setAccessToken(event.newValue);

    await this.loadUser();
    await this._root.appStore.restartRtm();
  };

  public logout = (): void => {
    this.clearUser();
    this.clearAccessToken();

    window.location.replace('/');
  };

  private initialize = async (): Promise<void> => {
    if (this.accessToken) {
      // TODO: refreshing on init to account for claims changes b/c our tokens live too long
      await this.refreshAccessToken();
      await this.loadUser();
    }

    this.setStatus(AuthStatus.Ready);

    await this._root.appStore.startRtm();
  };

  private refreshAccessToken = async (): Promise<void> => {
    try {
      const { token } = await apiClient.accounts.refreshToken();
      if (!token) {
        throw new Error('No access token from refreshToken call');
      }

      this.setAccessToken(token);
    } catch (error) {
      logger.warn('failed to refresh token, accessToken might be expired', {
        error,
      });
      this.logout();
    }
  };

  private handleAuthResponse = async (
    response: AuthenticateResponse,
  ): Promise<void> => {
    if (response.requiresEmailVerification) {
      this.setRequiresEmailVerification(true);
      return;
    }

    if (response.tfaSessionToken) {
      this.setTfaSessionToken(response.tfaSessionToken);
      return;
    }

    if (!response.result || !response.token) {
      throw new Error('Unknown sign in error occurred.');
    }

    this.setAccessToken(response.token);

    await this.loadUser();
    await this._root.appStore.restartRtm();
  };

  private loadUser = async (): Promise<void> => {
    try {
      this.setUser(await apiClient.users.getCurrentUserProfile());
    } catch (error) {
      logger.warn('failed to get profile, accessToken likely expired', {
        error,
      });
      this.logout();
    }
  };

  //#region RTM Events

  private handleUserUpdated = async (user: UserProfileDto): Promise<void> => {
    if (user.lockoutEnd) {
      this._root.toastStore.showError(
        'Banned',
        'You have been banned and will be logged out.',
      );

      setTimeout(() => this.logout(), 3000);
      return;
    }

    this.setUser(user);

    // refresh the access token to account for any updated claims
    await this.refreshAccessToken();
    await this._root.appStore.restartRtm();
  };

  //#endregion

  //#region Actions

  private setStatus = (status: AuthStatus): void => {
    this.status = status;
  };

  private setUser = (user: UserProfileDto): void => {
    this.user = user;
  };

  private clearUser = (): void => {
    this.user = null;
  };

  private setTfaSessionToken = (token: string): void => {
    this.tfaSessionToken = token;
  };

  private setRequiresEmailVerification = (required: boolean): void => {
    this.requiresEmailVerification = required;
  };

  private setAccessToken = (token: string): void => {
    this.accessToken = token;

    apiClient.setAccessTokenHeader(token);
    rtmClient.setAccessToken(token);

    if (token) {
      localStorage.setItem(ACCESS_TOKEN_KEY, token);
    } else {
      localStorage.removeItem(ACCESS_TOKEN_KEY);
    }
  };

  private clearAccessToken = (): void => {
    this.setAccessToken('');
  };

  //#endregion
}
