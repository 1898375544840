import { IncidentEdit } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { useExpandedMap, useStores } from '../../hooks';
import { IncidentEditMapping } from '../../stores';
import { incidentPath } from '../../utils';
import {
  BasicTable,
  BasicTableColumn,
  TitleCell,
  expanderColumn,
} from '../table';
import { IncidentEditList } from './incident-edit-list';

const summaryColumn = (): BasicTableColumn<IncidentEditMapping> => {
  return {
    field: 'incident.summary',
    name: 'Summary',
    render: (summary: string, item: IncidentEditMapping) => {
      return (
        <TitleCell
          title={summary || 'Untitled summary'}
          linkTo={incidentPath(item.incident.id)}
        />
      );
    },
  };
};

const editsColumn = (): BasicTableColumn<IncidentEditMapping> => {
  return {
    field: 'edits',
    name: 'Pending edits',
    render: (edits: IncidentEdit[]) => `${edits.length}`,
  };
};

export const IncidentEditTable = observer(() => {
  const { incidentEditStore } = useStores();
  const { expandedMap, isExpanded, toggleExpanded } = useExpandedMap((id) => (
    <IncidentEditList incidentId={id} />
  ));

  const columns = [
    summaryColumn(),
    editsColumn(),
    expanderColumn({
      isExpanded,
      onToggle: toggleExpanded,
      showText: 'Show edits',
      hideText: 'Hide edits',
    }),
  ];

  return (
    <BasicTable
      columns={columns}
      loading={incidentEditStore.loading}
      result={{
        items: incidentEditStore.incidentEditMap,
        count: incidentEditStore.incidentEditMap.length,
      }}
      subject="Incident edits"
      expandedRowsMap={expandedMap}
    />
  );
});
