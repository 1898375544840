import { EuiText } from '@elastic/eui';
import { Fragment } from 'react';

export interface ResultsCountProps {
  pageIndex: number;
  pageSize: number;
  totalCount: number;
  subject: string;
}

export const ResultsCount = (props: ResultsCountProps) => {
  const { pageIndex, pageSize, totalCount, subject } = props;
  const startIndex = pageSize * pageIndex;
  const endIndex = startIndex + pageSize;

  const renderCount = () => {
    if (totalCount === 0) {
      return <strong>{0}</strong>;
    }

    if (endIndex > totalCount) {
      return <strong>All</strong>;
    }

    return (
      <Fragment>
        <strong>
          {startIndex + 1}-{endIndex}
        </strong>{' '}
        of {totalCount}
      </Fragment>
    );
  };

  return (
    <EuiText size="xs">
      Showing {renderCount()} <strong>{subject}</strong>
    </EuiText>
  );
};
