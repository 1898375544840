import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFilePicker,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiFormRow,
  EuiLink,
  EuiMarkdownEditor,
  EuiSelect,
  EuiSpacer,
  EuiText,
  EuiTitle,
  getDefaultEuiMarkdownPlugins,
  useGeneratedHtmlId,
} from '@elastic/eui';
import {
  DepartmentDto,
  DepartmentResponseDto,
  DepartmentType,
} from '@unfrl/copdb-sdk';
import { isEqual } from 'lodash';
import { Fragment, lazy, Suspense, useRef, useState } from 'react';
import { useStores } from '../../hooks';
import { camelToSentence } from '../../utils';
import { Flex, Spinner } from '../common';
import { LocationForm } from '../location';
import { DepartmentCapabilitiesSelect } from './department-capabilities-select';

const DepartmentJurisdictionMap = lazy(
  () => import('../mapping/department-jurisdiction-map'),
);

const DESCRIPTION_LIMIT = 2500;

export interface DepartmentFormProps {
  onClose: () => void;
  onSave: (departmentDto: DepartmentDto) => void;
  saving?: boolean;
  editingDepartment?: DepartmentResponseDto;
}

export const DepartmentForm = (props: DepartmentFormProps) => {
  const { onClose, onSave, saving, editingDepartment } = props;

  const { toastStore } = useStores();
  const [departmentDto, setDepartmentDto] = useState<DepartmentDto>(
    editingDepartment
      ? (editingDepartment as DepartmentDto)
      : ({} as DepartmentDto),
  );

  const { parsingPlugins, processingPlugins, uiPlugins } =
    getDefaultEuiMarkdownPlugins({ exclude: ['tooltip'] });

  const filePickerRef: any = useRef();

  const description = departmentDto?.description ?? '';

  const canSave = (): boolean => {
    const requiredData = Boolean(
      departmentDto.name &&
        departmentDto.location &&
        departmentDto.shortName &&
        departmentDto.type,
    );

    if (editingDepartment) {
      return requiredData && !isEqual(editingDepartment, departmentDto);
    }
    return requiredData;
  };

  const formFlyoutId = useGeneratedHtmlId({
    prefix: 'departmentFormFlyoutTitle',
  });

  const handleJurisdictionFile = (files: FileList | null) => {
    if (!files || !(files.length > 0)) {
      setDepartmentDto({ ...departmentDto, jurisdiction: undefined });
      return;
    }
    try {
      const reader = new FileReader();
      reader.readAsText(files[0]);
      reader.onload = (e) => {
        try {
          const jsonResults = JSON.parse(reader.result as string);
          const multiPolygon = jsonResults.features[0].geometry;

          if (
            jsonResults.features.length !== 1 ||
            multiPolygon.type !== 'MultiPolygon'
          ) {
            toastStore.showError(
              'Invalid Upload',
              'Jurisdiction uploads must be a GeoJSON file with 1 FeatureCollection, with 1 Feature, whose geometry is of type "MultiPolygon"',
            );
            (filePickerRef.current as any)?.removeFiles();
            return;
          }

          setDepartmentDto({ ...departmentDto, jurisdiction: multiPolygon });
        } catch (e) {
          toastStore.showError('Unable to parse provided jurisdiction GeoJSON');
          (filePickerRef.current as any)?.removeFiles();
        }
      };
    } catch (e) {
      toastStore.showError('Unable to parse provided jurisdiction GeoJSON');
      setDepartmentDto({ ...departmentDto, jurisdiction: undefined });
    }
  };

  const handleSave = () => {
    if (canSave()) {
      onSave(departmentDto);
    }
  };

  const departmentTypeOptions = Object.values(DepartmentType).map((type) => {
    return { value: type, text: camelToSentence(type) };
  });

  return (
    <EuiFlyout maxWidth={500} onClose={onClose}>
      <EuiFlyoutHeader hasBorder aria-labelledby={formFlyoutId}>
        <EuiTitle>
          <h2 id={formFlyoutId}>Department form</h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiFormRow
          fullWidth
          label="Full name"
          helpText="The full name of the police department, e.g. Salt Lake City Police Department."
        >
          <EuiFieldText
            required
            fullWidth
            name="name"
            value={departmentDto.name ?? ''}
            onChange={(e) =>
              setDepartmentDto({ ...departmentDto, name: e.target.value })
            }
          />
        </EuiFormRow>
        <EuiFormRow
          fullWidth
          label="Short name"
          helpText="The acronym of the police department, e.g. SLCPD."
        >
          <EuiFieldText
            required
            fullWidth
            name="shortName"
            value={departmentDto.shortName ?? ''}
            onChange={(e) =>
              setDepartmentDto({
                ...departmentDto,
                shortName: e.target.value.toUpperCase(),
              })
            }
          />
        </EuiFormRow>
        <EuiFormRow
          fullWidth
          label="Description"
          helpText={`${description.length} / ${DESCRIPTION_LIMIT} characters`}
        >
          <EuiMarkdownEditor
            aria-label="Department description editor"
            placeholder="Description of the department..."
            value={description}
            onChange={(value) => {
              if (value.length < DESCRIPTION_LIMIT) {
                setDepartmentDto({
                  ...departmentDto,
                  description: value,
                });
              }
            }}
            height={250}
            initialViewMode="editing"
            parsingPluginList={parsingPlugins}
            processingPluginList={processingPlugins}
            uiPlugins={uiPlugins}
          />
        </EuiFormRow>
        <EuiFormRow fullWidth label="Capabilities">
          <Fragment>
            <EuiText color="subdued" size="s">
              {`For more information on capabilities check out the EFF's`}{' '}
              <EuiLink
                href="https://atlasofsurveillance.org/glossary"
                target="_blank"
                rel="noopener noreferrer"
                external
              >
                Atlas of Surveillance
              </EuiLink>{' '}
              project
            </EuiText>
            <EuiSpacer size="s" />
            <DepartmentCapabilitiesSelect
              selectedCapabilities={
                departmentDto.capabilities
                  ? [...departmentDto.capabilities]
                  : []
              }
              onChange={(capabilities) => {
                setDepartmentDto({ ...departmentDto, capabilities });
              }}
            />
          </Fragment>
        </EuiFormRow>
        <EuiFormRow fullWidth label="Type">
          <EuiSelect
            fullWidth
            hasNoInitialSelection
            required
            value={departmentDto?.type?.toString()}
            options={departmentTypeOptions}
            onChange={(e) => {
              setDepartmentDto({
                ...departmentDto,
                type: e.target.value as DepartmentType,
              });
            }}
          />
        </EuiFormRow>
        <LocationForm
          required
          label="Location"
          location={departmentDto.location}
          onLocationChange={(location) => {
            setDepartmentDto({
              ...departmentDto,
              location: location as any, // DepartmentDto requires location, but in the editing state is needs to be able to be empty
            });
          }}
        />
        <EuiFormRow fullWidth label="Jurisdiction">
          <Fragment>
            <EuiFilePicker
              fullWidth
              compressed
              accept="application/geo+json"
              multiple={false}
              ref={filePickerRef}
              onChange={handleJurisdictionFile}
              isLoading={false}
              initialPromptText="Select or drag and drop GeoJSON file"
            />
            <EuiSpacer size="s" />
            {departmentDto.jurisdiction ? (
              <Suspense fallback={<Spinner />}>
                <DepartmentJurisdictionMap
                  jurisdiction={departmentDto.jurisdiction}
                />
              </Suspense>
            ) : null}
          </Fragment>
        </EuiFormRow>
      </EuiFlyoutBody>
      <EuiFlyoutFooter>
        <Flex align="center" justify="flex-end" gap={16}>
          <EuiButtonEmpty
            size="s"
            iconType="cross"
            color="text"
            onClick={onClose}
            disabled={saving}
          >
            Cancel
          </EuiButtonEmpty>
          <EuiButton
            fill
            size="s"
            onClick={handleSave}
            isLoading={saving}
            disabled={!canSave()}
          >
            Save
          </EuiButton>
        </Flex>
      </EuiFlyoutFooter>
    </EuiFlyout>
  );
};
