import {
  EuiCheckbox,
  EuiIcon,
  EuiPanel,
  EuiText,
  EuiTitle,
} from '@elastic/eui';
import { observer } from 'mobx-react';
import { EditIncidentViewModel } from '../../models';
import { formatNameFromResponseDto } from '../../utils';
import { DeleteConfirm, Flex } from '../common';
import { MediaPreview } from '../media/media-preview';

export interface IncidentMediaAssociationListProps {
  editIncident: EditIncidentViewModel;
}

export const IncidentMediaAssociationList = observer(
  (props: IncidentMediaAssociationListProps) => {
    const { editIncident } = props;

    return (
      <Flex column gap={16}>
        {editIncident.medias.map((media) => {
          const mediaAssociation = editIncident.mediaAssociations.find(
            (ma) => ma.mediaId === media.id,
          );
          const personIds = mediaAssociation?.personIds ?? [];

          const hasAnyAssociations =
            !!mediaAssociation?.includesIncident ||
            editIncident.associatedCops.some(
              (cop) => personIds.indexOf(cop.personId) > -1,
            );

          return (
            <EuiPanel key={media.id} hasBorder style={{ paddingBottom: 8 }}>
              <Flex column gap={8}>
                <MediaPreview media={media} />
                <EuiCheckbox
                  id={`includes-incident-${media.id}`}
                  label="Contains information about the incident"
                  checked={!!mediaAssociation?.includesIncident}
                  onChange={(e) =>
                    editIncident.setIncludesIncident(media.id, e.target.checked)
                  }
                />
                {editIncident.associatedCops.length ? (
                  <Flex column gap={4}>
                    <EuiTitle size="xxxs">
                      <span>Cops associated with this media</span>
                    </EuiTitle>
                    {editIncident.associatedCops.map((cop) => {
                      const id = `${cop.id}-${media.id}`;
                      const isChecked = personIds.indexOf(cop.personId) > -1;

                      return (
                        <EuiCheckbox
                          key={id}
                          id={id}
                          label={formatNameFromResponseDto(cop)}
                          checked={isChecked}
                          onChange={(e) => {
                            if (e.target.checked) {
                              editIncident.associatePersonWithMedia(
                                cop.personId,
                                media.id,
                              );
                            } else {
                              editIncident.unassociatePersonWithMedia(
                                cop.personId,
                                media.id,
                              );
                            }
                          }}
                        />
                      );
                    })}
                  </Flex>
                ) : null}
                {!hasAnyAssociations && (
                  <Flex gap={8}>
                    <EuiIcon type="iInCircle" color="danger" />
                    <EuiText size="s">
                      Media that is not associated with either the incident or
                      any of the cops should be deleted
                    </EuiText>
                  </Flex>
                )}
              </Flex>
              <Flex justify="flex-end">
                <DeleteConfirm
                  onDelete={() =>
                    Promise.resolve(editIncident.unassociateMedia(media.id))
                  }
                  text="This will remove the media association from the incident and all cops. Delete media?"
                />
              </Flex>
            </EuiPanel>
          );
        })}
      </Flex>
    );
  },
);
