import { EuiDatePicker, EuiFormRow } from '@elastic/eui';
import { observer } from 'mobx-react';
import moment from 'moment';
import { dateUtils } from '../../utils';

export interface DateOccurredFormProps {
  date?: Date | null;
  onDateChange: (date?: Date) => void;
}

export const DateOccurredForm = observer((props: DateOccurredFormProps) => {
  const { date, onDateChange } = props;

  return (
    <EuiFormRow fullWidth label="Date and time">
      <EuiDatePicker
        onClear={() => onDateChange()}
        fullWidth
        showTimeSelect
        selected={dateUtils.toMoment(date)}
        onChange={(date) => onDateChange(date?.toDate())}
        maxDate={moment()}
      />
    </EuiFormRow>
  );
});
