import { Media } from '@unfrl/copdb-sdk';
import { makeAutoObservable, when } from 'mobx';
import { apiClient, rtmClient } from '../api';
import {
  MediaProcessedStatuses,
  fileUtils,
  logger,
  mediaUtils,
} from '../utils';
import { RtmStatus } from './app.store';
import { DataStore } from './data.store';
import { RootStore } from './root.store';

export interface UploadingMedia {
  id: string;
  file: File;
  uploadUrl: string;
  error?: string;
  progress?: number;
  cancel?: () => void;
}

export interface ProcessingMedia {
  id: string;
  progress: number;
  status?: string;
}

export class MediaStore {
  private readonly _root: RootStore;

  public data: DataStore<Media> = new DataStore();

  private _uploading: DataStore<UploadingMedia> = new DataStore();

  private _processing: DataStore<ProcessingMedia> = new DataStore();

  public constructor(rootStore: RootStore) {
    this._root = rootStore;

    rtmClient.media.onUpdated(this.fetchUpdatedMedia);
    rtmClient.media.onProgress(this.handleProgressUpdate);
    makeAutoObservable(this);
  }

  private handleProgressUpdate = async (update: {
    id: string;
    progress: number;
    status: string;
  }) => {
    const { id, progress, status } = update;

    this._processing.updateItem(id, { progress, status });
  };

  private fetchUpdatedMedia = async (mediaId: string): Promise<void> => {
    const updatedMedias = await apiClient.media.listMedias({
      listMediasDto: { mediaIds: [mediaId] },
    });
    const updatedMedia = updatedMedias[0];

    if (this._processing.deleteItem(updatedMedia.id)) {
      try {
        await rtmClient.media.disconnect(updatedMedia.id);
      } catch (error) {
        logger.error('Error when disconnecting from media', error);
      }
    }

    this.data.setItem(updatedMedia);
    // Check if the updated media failed
    if (updatedMedia.mediaProcessInfo[0].failureReason) {
      const failedMediaUploading = this.getUploadingMedia(mediaId);
      // If the failed media is still uploading then cancel it
      if (failedMediaUploading) {
        this.cancelUploadMedia(mediaId);
      }
    }
  };

  /**
   * Returns the uploading status of the media record. If undefined, the media
   * was likely already uploaded.
   */
  public getUploadingMedia = (mediaId: string): UploadingMedia | undefined => {
    return this._uploading.getItem(mediaId);
  };

  /**
   * Returns the uploading status of the media record. If undefined, the media
   * was likely already processed.
   */
  public getProcessingMedia = (
    mediaId: string,
  ): ProcessingMedia | undefined => {
    return this._processing.getItem(mediaId);
  };

  public fetchMedias = async (ids: string[]): Promise<void> => {
    const idsToFetch = ids.filter((id) => !this.data.itemExists(id));
    if (!idsToFetch.length) {
      return;
    }

    const medias = await apiClient.media.listMedias({
      listMediasDto: { mediaIds: idsToFetch },
    });

    const mediaProgressConnections = medias
      .filter(
        (media) =>
          mediaUtils.getProcessingStatus(media) ===
          MediaProcessedStatuses.Processing,
      )
      .map((media) => {
        this._processing.setItem({ id: media.id, progress: 0 });
        return this.connectWhenReady(media.id);
      });

    await Promise.all(mediaProgressConnections);

    medias.forEach(this.data.setItem);
  };

  /**
   * Creates a new media record, uploads the file, and then returns the created
   * media.
   * @param file - File to create media based on.
   * @param awaitUpload - Set to true to await the file upload (default false).
   */
  public createMedia = async (
    file: File,
    awaitUpload = false,
  ): Promise<Media> => {
    const { media, uploadUrl } = await apiClient.media.createMedia({
      createMediaDto: {
        name: file.name,
        contentType: file.type,
      },
    });

    this._processing.setItem({ id: media.id, progress: 0 });

    try {
      await rtmClient.media.connect(media.id);
    } catch (error) {
      logger.error('Error when connecting to media', error);
    }

    this.data.setItem(media);

    if (awaitUpload) {
      await this.handleUploadMedia(media.id, uploadUrl, file);
    } else {
      this.handleUploadMedia(media.id, uploadUrl, file);
    }

    return media;
  };

  public updateDisplayName = async (
    mediaId: string,
    displayName: string,
  ): Promise<void> => {
    const updated = await apiClient.media.setMediaDisplayName({
      mediaId,
      displayName,
    });

    this.data.setItem(updated);
  };

  public deleteMedia = async (mediaId: string): Promise<void> => {
    await apiClient.media.deleteMedia({ mediaId });

    this.data.deleteItem(mediaId);
  };

  public cancelUploadMedia = (mediaId: string): void => {
    const existing = this._uploading.getItem(mediaId);
    if (!existing) {
      logger.warn('no uploading media found for id', { mediaId });
      return;
    }

    if (!existing.cancel) {
      logger.warn('no cancel function attached to uploading media');
      return;
    }

    existing.cancel();
  };

  /**
   * Attempts to connect to the RTM room by the media ID, first ensuring that the
   * RTM client is in a connected state.
   */
  private connectWhenReady = async (mediaId: string): Promise<void> => {
    await when(() => this._root.appStore.rtmStatus === RtmStatus.Connected);

    try {
      await rtmClient.media.connect(mediaId);
    } catch (error) {
      logger.error('Error when connecting to media', error);
    }
  };

  private handleUploadMedia = async (
    id: string,
    uploadUrl: string,
    file: File,
  ): Promise<void> => {
    try {
      this._uploading.setItem({ id, file, uploadUrl });

      await fileUtils.uploadFile(file, {
        uploadUrl,
        onProgress: (progress) => {
          this._uploading.updateItem(id, { progress });
        },
        onStart: (abort) => {
          this._uploading.updateItem(id, { cancel: abort });
        },
      });

      // remove on completion
      this._uploading.deleteItem(id);
    } catch (error: any) {
      logger.warn('File upload failed or cancelled', error);
      this._uploading.updateItem(id, { error: error.message });
    }
  };
}
