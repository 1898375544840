import {
  CopResponseDto,
  Media,
  PublicUserProfileDto,
  Report,
  ReportResponseDto,
  ReportStatus,
  ReportVersion,
  UpsertReportDto,
} from '@unfrl/copdb-sdk';
import { makeAutoObservable, reaction, toJS, when } from 'mobx';
import { apiClient, rtmClient } from '../api';
import { EditReport } from '../models';
import { logger } from '../utils';
import { RtmStatus } from './app.store';
import { AuthStatus } from './auth.store';
import { DataStore } from './data.store';
import { RootStore } from './root.store';

export interface ReportDetails {
  /**
   * Id of the report.
   */
  id: string;
  /**
   * Actual report record.
   */
  report: Report;
  /**
   * Latest version containing the report data.
   */
  version: ReportVersion;
}

export class ReportStore {
  private readonly _root: RootStore;

  public data: DataStore<ReportDetails> = new DataStore();

  private get activeReportCopIds(): string[] {
    return this.data.activeItem?.version?.reportDto?.existingCopIds ?? [];
  }

  public get activeReportExistingCops(): CopResponseDto[] {
    return this._root.copStore.data.orderedItems.filter((cop) =>
      this.activeReportCopIds.includes(cop.id),
    );
  }

  private get activeReportMediaIds(): string[] {
    return (
      this.data.activeItem?.version.reportDto?.mediaAssociations?.map(
        (m) => m.mediaId,
      ) ?? []
    );
  }

  private get activeReportNewCopPersonMediaIds(): string[] {
    const mediaIds: string[] = [];
    if (!this.data.activeItem?.version.reportDto.newCops?.length) {
      return mediaIds;
    }

    for (const newCop of this.data.activeItem.version.reportDto.newCops) {
      if (newCop.personMediaDtos?.length) {
        mediaIds.push(...newCop.personMediaDtos.map((dto) => dto.mediaId));
      }
    }

    return mediaIds;
  }

  public get activeReportMedias(): Media[] {
    return this.activeReportMediaIds
      .map((mediaId) => this._root.mediaStore.data.getItem(mediaId))
      .filter(Boolean) as Media[];
  }

  public get activeTitle(): string {
    if (!this.data.activeItem) {
      return '';
    }

    return this.data.activeItem.version.reportDto.title || 'Untitled report';
  }

  public get activeAuthorProfile(): PublicUserProfileDto | null {
    if (!this.data.activeItem) {
      return null;
    }

    return (
      this._root.userStore.data.getItem(
        this.data.activeItem.report.createdBy,
      ) ?? null
    );
  }

  /**
   * True if the current user can edit the active report.
   */
  public get userCanEditActive(): boolean {
    if (!this.data.activeItem) {
      return false;
    }

    if (this.data.activeItem.report.status === ReportStatus.Approved) {
      return false;
    }

    return (
      this.userIsActiveAuthor ||
      // admins and mods can edit reports that are not their own
      this._root.authStore.isAdmin ||
      this._root.authStore.isModerator
    );
  }

  /**
   * True if the current user can approve the active report.
   */
  public get userCanApproveActive(): boolean {
    return (
      this.data.activeItem?.report.status !== ReportStatus.Approved &&
      (this._root.authStore.isAdmin ||
        (this._root.authStore.isModerator && !this.userIsActiveAuthor))
    );
  }

  /**
   * True if the current user can submit the active report for approval.
   */
  public get userCanSubmitActiveForApproval(): boolean {
    if (this.data.activeItem?.report.status !== ReportStatus.Draft) {
      return false;
    }

    if (this.userCanApproveActive) {
      return false; // user is able to modify the report status, no need for approval option
    }

    return this.userIsActiveAuthor;
  }

  /**
   * True if the current user is the author of the active report.
   */
  public get userIsActiveAuthor(): boolean {
    if (!this._root.authStore.user?.id) {
      return false;
    }

    if (!this.data.activeItem) {
      return false;
    }

    return (
      this._root.authStore.user.id === this.data.activeItem.report.createdBy
    );
  }

  /**
   * True if the current user can view active report comments.
   */
  public get userCanViewComments(): boolean {
    return (
      this.userIsActiveAuthor ||
      this._root.authStore.isAdmin ||
      this._root.authStore.isModerator
    );
  }

  /**
   * True if the current user can add comments to the active report.
   */
  public get userCanAddComments(): boolean {
    if (this.data.activeItem?.report.status === ReportStatus.Approved) {
      return false;
    }

    return this.userCanViewComments;
  }

  public constructor(rootStore: RootStore) {
    this._root = rootStore;

    rtmClient.reports.onUpdated(this.setReport);

    reaction(
      () => this.activeReportCopIds,
      async (copIds) => {
        if (copIds.length) {
          await this._root.copStore.fetchCops(copIds);
        }
      },
    );

    reaction(
      () => this.activeReportMediaIds,
      async (mediaIds) => {
        if (mediaIds.length) {
          await this._root.mediaStore.fetchMedias(mediaIds);
        }
      },
    );

    reaction(
      () => this.activeReportNewCopPersonMediaIds,
      async (mediaIds) => {
        if (mediaIds.length) {
          await this._root.mediaStore.fetchMedias(mediaIds);
        }
      },
    );

    reaction(
      () => ({
        status: this._root.appStore?.rtmStatus,
        reportId: this.data.activeId,
      }),
      async ({ status, reportId }) => {
        if (status === RtmStatus.Connected && reportId) {
          await rtmClient.reports.connect(reportId);
        }
      },
    );

    makeAutoObservable(this);
  }

  public loadActiveReport = async (reportId: string): Promise<void> => {
    try {
      this.setReport(await apiClient.reports.getReport({ reportId }));
      this.data.setActive(reportId);

      await Promise.all([this.fetchCommentsIfAllowed(), this.fetchAuthor()]);
    } catch (error) {
      logger.error('loadActiveReport', error);
    }
  };

  public clearActiveReport = async (): Promise<void> => {
    if (!this.data.activeId) {
      throw new Error('No active report to clear');
    }

    try {
      await rtmClient.reports.disconnect(this.data.activeId);
    } catch (error) {
      logger.error('Error when disconnecting from report', error);
    }

    this.data.clearActive();
    this._root.reportCommentStore.data.clearItems();
  };

  /**
   * Creates and returns a new observable for editing the active report's latest
   * version data.
   */
  public getActiveReportEditDto = (): EditReport => {
    if (!this.data.activeItem) {
      throw new Error('No active report to get edit DTO for');
    }

    return new EditReport(
      this._root,
      toJS(this.data.activeItem.version.reportDto),
    );
  };

  public updateReportStatus = async (
    reportId: string,
    status: ReportStatus,
  ): Promise<void> => {
    const response = await apiClient.reports.updateReportStatus({
      reportId,
      status,
    });

    this.setReport(response);
  };

  public updateReport = async (
    reportId: string,
    upsertDto: UpsertReportDto,
  ): Promise<void> => {
    const response = await apiClient.reports.updateReport({
      reportId,
      upsertReportDto: upsertDto,
    });

    this.setReport(response);
  };

  public submitReport = async (reportId: string): Promise<void> => {
    const response = await apiClient.reports.submitReport({ reportId });

    this.setReport(response);
  };

  private fetchAuthor = async (): Promise<void> => {
    if (this.data.activeItem?.report.createdBy) {
      await this._root.userStore.fetchUserProfiles([
        this.data.activeItem.report.createdBy,
      ]);
    }
  };

  private fetchCommentsIfAllowed = async (): Promise<void> => {
    await when(() => this._root.authStore.status === AuthStatus.Ready);

    if (this.data.activeId && this.userCanViewComments) {
      await this._root.reportCommentStore.fetchComments(this.data.activeId);
    }
  };

  //#region Actions

  private setReport = (dto: ReportResponseDto): void => {
    this.data.setItem(this.mapResponseToDetails(dto));
  };

  private mapResponseToDetails = (dto: ReportResponseDto): ReportDetails => {
    return {
      id: dto.report.id,
      report: dto.report,
      version: dto.latestVersion,
    };
  };

  //#endregion
}
