import { EuiIcon, EuiText, EuiToolTip } from '@elastic/eui';
import { CopDepartmentResponseDto, CopResponseDto } from '@unfrl/copdb-sdk';
import { uniqBy } from 'lodash';
import {
  copProfilePath,
  dateUtils,
  formatNameFromResponseDto,
  getBadgeNumberText,
  getDepartmentStatusText,
  getPositionText,
  getProfilePhotoFromResponse,
} from '../../utils';
import { Flex, MissingDataText } from '../common';
import { BasicTableColumn, TitleCell } from '../table';
import { CopAvatar } from './cop-avatar';

export const CopNameCell = ({
  cop,
  searchValue,
}: {
  cop: CopResponseDto;
  searchValue?: string;
}) => {
  const name = formatNameFromResponseDto(cop);

  return (
    <Flex align="center">
      <CopAvatar
        name={name}
        photoUrl={getProfilePhotoFromResponse(cop)}
        size={64}
      />
      <div style={{ marginRight: 12 }} />
      <TitleCell
        title={name}
        searchValue={searchValue}
        linkTo={copProfilePath(cop.id!)}
      />
    </Flex>
  );
};

const name = (searchValue?: string) => {
  return {
    field: 'person.lastName',
    name: 'Name',
    sortable: true,
    width: '250px',
    render: (_value: string, cop: CopResponseDto) => (
      <CopNameCell cop={cop} searchValue={searchValue} />
    ),
  };
};

const department = (departmentId?: string) => {
  return {
    field: 'copDepartments',
    name: 'Department',
    render: (departments: CopDepartmentResponseDto[]) => {
      const status = getDepartmentStatusText(departments, departmentId);

      const uniqueDepartments = uniqBy(departments, (dep) => dep.departmentId);

      if (uniqueDepartments.length > 1) {
        return (
          <Flex align="center" gap={8}>
            <EuiText size="relative">{status}</EuiText>
            <EuiToolTip
              content={
                <>
                  <EuiText color="subdued">Multiple departments:</EuiText>
                  {uniqueDepartments.map((dep) => (
                    <EuiText key={dep.departmentId}>{dep.name}</EuiText>
                  ))}
                </>
              }
            >
              <EuiIcon type="inspect" color="primary" />
            </EuiToolTip>
          </Flex>
        );
      }
      return status || <MissingDataText text="N/A" />;
    },
  };
};

const position = (departmentId?: string) => {
  return {
    field: 'copDepartments',
    name: 'Position',
    truncateText: true,
    textOnly: true,
    render: (departments: CopDepartmentResponseDto[]) => {
      return (
        getPositionText(departments, departmentId) || (
          <MissingDataText text="N/A" />
        )
      );
    },
  };
};

const badgeNumber = (departmentId?: string) => {
  return {
    field: 'copDepartments',
    name: 'Badge number',
    truncateText: true,
    render: (departments: CopDepartmentResponseDto[]) => {
      return (
        getBadgeNumberText(departments, departmentId) || (
          <MissingDataText text="N/A" />
        )
      );
    },
  };
};

const updatedAt = (): BasicTableColumn<CopResponseDto> => {
  return {
    field: 'updatedAt',
    name: 'Last updated',
    sortable: true,
    dataType: 'date',
    width: '100px',
    render: (updated: Date) => {
      return (
        <EuiText size="relative" title={dateUtils.format(updated)}>
          {dateUtils.fromNow(updated)}
        </EuiText>
      );
    },
  };
};

export const copTableColumns = {
  name,
  department,
  position,
  badgeNumber,
  updatedAt,
};
