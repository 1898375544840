import {
  EuiButtonIcon,
  EuiDatePicker,
  EuiFieldText,
  EuiFlexGrid,
  EuiFlexItem,
  EuiText,
} from '@elastic/eui';
import { CopDepartmentDto } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { ChangeEvent } from 'react';
import { dateUtils } from '../../utils';
import { Flex } from '../common';
import { CopNotabilityList } from './cop-notability-list';

export interface CopDepartmentItemProps {
  departmentName: string;
  copDepartmentDto: CopDepartmentDto;
  onRemove: (id: string) => void;
  onUpdate: (dto: CopDepartmentDto) => void;
  isInvalid: boolean;
}

export const CopDepartmentItem = observer((props: CopDepartmentItemProps) => {
  const { departmentName, copDepartmentDto, onRemove, onUpdate, isInvalid } =
    props;

  const handleTextChange =
    (key: keyof CopDepartmentDto) => (e: ChangeEvent<HTMLInputElement>) => {
      onUpdate({ ...copDepartmentDto, [key]: e.target.value });
    };

  return (
    <Flex column gap={8}>
      <Flex>
        <EuiText size="relative">{departmentName}</EuiText>
        <EuiFlexItem style={{ flexFlow: 'row-reverse' }}>
          <EuiButtonIcon
            iconType="trash"
            aria-label="Delete"
            color="danger"
            onClick={() => onRemove(copDepartmentDto.departmentId)}
          />
        </EuiFlexItem>
      </Flex>
      <EuiFlexGrid columns={2}>
        <EuiDatePicker
          maxDate={moment()}
          selected={dateUtils.toMoment(copDepartmentDto.startDate)}
          onChange={(date) =>
            onUpdate({ ...copDepartmentDto, startDate: date?.toDate() })
          }
          onClear={() =>
            onUpdate({ ...copDepartmentDto, startDate: undefined })
          }
          isInvalid={isInvalid}
          placeholder="Start date"
        />
        <EuiDatePicker
          maxDate={moment()}
          selected={dateUtils.toMoment(copDepartmentDto.endDate)}
          onChange={(date) =>
            onUpdate({ ...copDepartmentDto, endDate: date?.toDate() })
          }
          onClear={() => onUpdate({ ...copDepartmentDto, endDate: undefined })}
          isInvalid={isInvalid}
          placeholder="End date"
        />
      </EuiFlexGrid>
      <EuiFlexGrid columns={2}>
        <Flex column style={{ flexGrow: 1 }}>
          <EuiText color="subdued" size="xs">
            Badge number
          </EuiText>
          <EuiFieldText
            placeholder="J117"
            fullWidth
            name="badgeNumber"
            value={copDepartmentDto.badgeNumber ?? ''}
            onChange={handleTextChange('badgeNumber')}
          />
        </Flex>
        <Flex column style={{ flexGrow: 1 }}>
          <EuiText color="subdued" size="xs">
            Position
          </EuiText>
          <EuiFieldText
            placeholder="Sergeant"
            fullWidth
            name="position"
            value={copDepartmentDto.position ?? ''}
            onChange={handleTextChange('position')}
          />
        </Flex>
      </EuiFlexGrid>
      <Flex column>
        <EuiText color="subdued" size="xs">
          Notability
        </EuiText>
        <CopNotabilityList
          notabilities={copDepartmentDto.notabilities}
          onChange={(values) =>
            onUpdate({
              ...copDepartmentDto,
              notabilities: values,
            })
          }
        />
      </Flex>
    </Flex>
  );
});
