import { EuiButtonIconPropsForButton, EuiListGroupItem } from '@elastic/eui';
import { ReactNode } from 'react';
import { useMatch, useResolvedPath } from 'react-router-dom';
import { useNavProps } from '../../hooks';

export interface NavLinkProps {
  name: string | ReactNode;
  to: string;
  icon?: string;
  extraAction?: EuiButtonIconPropsForButton & {
    alwaysShow?: boolean;
  };
  secondary?: boolean;
  onClickBeforeNav?: () => void;
}

export const NavLink = (props: NavLinkProps) => {
  const { name, to, icon, extraAction, secondary, onClickBeforeNav } = props;
  const { pathname } = useResolvedPath(to);
  // by setting end = false, when navigating to reports/{reportId}, a navItem
  // that is just "/reports" will count as selected -- this is useful for
  // app-sidebar
  const end = pathname === '/';
  const match = useMatch({ path: pathname, end });
  const getNavProps = useNavProps();

  const { href, onClick } = getNavProps(to);

  const handleClick = (e: any) => {
    if (onClickBeforeNav) {
      onClickBeforeNav();
    }

    onClick(e);
  };

  return (
    <EuiListGroupItem
      color={secondary ? 'subdued' : 'text'}
      label={name}
      iconType={icon}
      isActive={!!match}
      extraAction={extraAction}
      href={href}
      onClick={handleClick}
    />
  );
};
