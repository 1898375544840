import {
  EuiAvatar,
  EuiButton,
  EuiFormRow,
  EuiHorizontalRule,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui';
import { UpdateUserDto, UserProfileDto } from '@unfrl/copdb-sdk';
import { Fragment } from 'react';
import { AuthRole } from '../../stores';
import { Flex } from '../common';
import { RoleSelect } from './role-select';

export interface UserFormProps {
  user: UserProfileDto;
  updateDto: UpdateUserDto;
  onUpdateUserDto: (updateDto: UpdateUserDto) => void;
}

export const UserForm = (props: UserFormProps) => {
  const { user, updateDto, onUpdateUserDto } = props;
  const { userName, email } = user;

  return (
    <Fragment>
      <Flex align="center">
        <EuiAvatar name={userName} size="l" />
        <div style={{ marginRight: 12 }} />
        <div>
          <EuiText size="relative">{userName}</EuiText>
          <EuiText size="relative" color="subdued">
            {email}
          </EuiText>
        </div>
      </Flex>
      <EuiHorizontalRule margin="m" />
      <EuiTitle size="xs">
        <h3>Role</h3>
      </EuiTitle>
      <EuiSpacer size="s" />
      <EuiFormRow>
        <RoleSelect
          selectedRole={updateDto.role ? (updateDto.role as AuthRole) : null}
          onChange={(role) => onUpdateUserDto({ ...updateDto, role })}
        />
      </EuiFormRow>
      <EuiSpacer size="m" />
      <EuiTitle size="xs">
        <h3>Actions</h3>
      </EuiTitle>
      <EuiSpacer size="s" />
      <EuiButton
        fullWidth
        isSelected={updateDto.banned}
        fill={updateDto.banned}
        size="s"
        color="danger"
        iconType={updateDto.banned ? 'cross' : 'minusInCircle'}
        onClick={() =>
          onUpdateUserDto({ ...updateDto, banned: !updateDto.banned })
        }
      >
        {updateDto.banned ? 'Unban user' : 'Ban user'}
      </EuiButton>
    </Fragment>
  );
};
